import { AgentSimulationOptions, VideoAgentSessionTopic, VideoAgentSessionTopicKind } from '@spinach-shared/types';
import { isLocalStage } from '@spinach-shared/utils';

import { defaultLocalScribeServer, mockBotId, mockSeriesId, mockToken } from '../constants';
import { isWebPlatform } from './platform';

/**
 * Iff working locally on web client, this returns a simulation options record for agent development.
 * Set the appropriate env vars if you wish to change from the defaults.
 */
export function getAgentSimulationOptions(): AgentSimulationOptions | null {
    if (!isLocalStage()) {
        return null;
    }

    if (!isWebPlatform()) {
        return null;
    }

    function getSimulationEnvVarsWithDefault<T>(envVarName: string, fallback: T): T {
        const setValue = process.env[envVarName];

        if (setValue === undefined) {
            return fallback;
        }

        if (typeof fallback === 'boolean') {
            return (setValue === 'true') as T;
        }

        if (typeof fallback === 'number') {
            return Number(setValue) as T;
        }

        if (typeof fallback === 'string') {
            return setValue as T;
        }

        return fallback;
    }

    return {
        mockTranscriptionEnabled: getSimulationEnvVarsWithDefault('REACT_APP_AGENT_TRANSCRIPTION_ENABLED', true),
        skipLiveNoteGeneration: getSimulationEnvVarsWithDefault('REACT_APP_AGENT_SKIP_LIVE_NOTES_GENERATION', true),
        agentModeEnabled: getSimulationEnvVarsWithDefault('REACT_APP_AGENT_MODE_ENABLED', true),
        showAgentVideo: getSimulationEnvVarsWithDefault('REACT_APP_AGENT_VIDEO_ON', true),

        mockTranscriptionInterval: getSimulationEnvVarsWithDefault('REACT_APP_AGENT_TRANSCRIPTION_INTERVAL', 30),

        mockBotId: getSimulationEnvVarsWithDefault('REACT_APP_AGENT_MOCK_BOT_ID', ''),
    };
}

export function getRandomMockTranscriptLine(): string {
    return sampleLines[Math.floor(Math.random() * sampleLines.length)];
}

// ty gpt
export const sampleLines = [
    'Yesterday, I completed the user interface for the login page.',
    'I managed to merge the latest features into the main branch without any conflicts.',
    'I finished the code review for the payment integration module.',
    "Today, I'll start working on the backend for the new search functionality.",
    "I'll be focusing on fixing the bugs reported from the latest QA session.",
    'I plan to write the API documentation for the services we deployed last week.',
    'I’m blocked by the lack of access to the new cloud environment; I need someone to help with that.',
    "I'm waiting for the frontend team to finalize the design specs so I can proceed with the integration.",
    'I encountered a problem with the third-party payment gateway; it’s not accepting test transactions.',
    'Can someone from the DevOps team help me set up the new staging environment?',
    'I need a quick pair programming session to solve a complex issue related to database performance.',
    'Is there anyone who has experience with React hooks? I could use some help troubleshooting a bug.',
    "Let's ensure everyone updates their tasks on Jira by the end of the day.",
    'Remember, the deployment window is Thursday night, so let’s prioritize any commits that need to go out this week.',
    'We need to schedule a meeting with the stakeholders to get feedback on the latest prototype.',
    'Great job on the redesign, it really enhances the user experience!',
    'I appreciated the quick turnaround on those critical bugs last week.',
    'Thanks for staying late yesterday to help with the server issues; it really helped keep us on track.',
];

export function getAgentSearchParams(statefulSearch?: string) {
    const searchParams = new URLSearchParams(statefulSearch || location.search);

    let seriesId = searchParams.get('seriesId');
    let botId = searchParams.get('botId');
    let token = searchParams.get('token');
    let scribeServer = searchParams.get('scribeServer');

    if (isLocalStage()) {
        seriesId = seriesId || mockSeriesId;
        botId = botId || mockBotId;
        token = token || mockToken;
        scribeServer = scribeServer || defaultLocalScribeServer;
    }

    if (!seriesId || !botId || !token || !scribeServer) {
        throw new Error('Missing required parameters for agent');
    }

    return { seriesId, botId, token, scribeServer };
}

const AFFIRMATIVE = 'AFFIRMATIVE';

export const AGENT_SAYINGS = {
    AgendaAlreadyStarted: 'The agenda has already started',
    StartAgenda: 'great, lets go.',
    AlreadyOnLastTopic: 'This is the last topic',
    GoToLastTopicNoFeedback: `This is the last topic. I'll share the notes after the meeting.`,
    GoToLastTopicAsFeedback: `That was the last topic. We'd love to hear your feedback.`,
    JumpingToTopic: 'Moving on.',
    [`${AFFIRMATIVE}_1`]: 'sure',
    [`${AFFIRMATIVE}_2`]: 'great',
    [`${AFFIRMATIVE}_3`]: 'okay',
    [`${AFFIRMATIVE}_4`]: 'Got it!',
    QuestionAreNotSupportedYet: 'Sorry, I cannot answer questions just yet',
};

export function getAffirmativeSayings(): string[] {
    return Object.entries(AGENT_SAYINGS)
        .filter(([key]) => key.startsWith(AFFIRMATIVE))
        .map(([, value]) => value);
}

export const shuffledAffirmativeSayings = getAffirmativeSayings().sort(() => Math.random() - 0.5);

export const getTemplateForTopic = (kind: VideoAgentSessionTopicKind, topic: VideoAgentSessionTopic) => {
    const templates = typeTemplates[kind];
    if (!templates || templates.length === 0) {
        throw new Error(`No templates found for kind: ${kind}`);
    }
    return templates[0](topic); // Assuming you want to use the first template
};

// TODO getRandomAffirmativeSaying()

const typeTemplates: Record<VideoAgentSessionTopicKind, ((topic: VideoAgentSessionTopic) => string)[]> = {
    [VideoAgentSessionTopicKind.Person]: [
        (topic: VideoAgentSessionTopic) => `${topic.title} is up`,
        (topic: VideoAgentSessionTopic) => `You're up, ${topic.title}!`,
    ],
    [VideoAgentSessionTopicKind.Discussion]: [
        (topic: VideoAgentSessionTopic) => `Moving on to ${topic.title}`,
        (topic: VideoAgentSessionTopic) => `Next up is ${topic.title}`,
    ],
    [VideoAgentSessionTopicKind.PreviousBlocker]: [
        (topic: VideoAgentSessionTopic) => `${topic.title} is a previous blocker`,
        (topic: VideoAgentSessionTopic) => `Next up is ${topic.title}`,
    ],
};

export const getAnnouncementForTopicKind = (
    kind: VideoAgentSessionTopicKind,
    topic: VideoAgentSessionTopic,
    psudoRandomIndex: number
) => {
    const templates = typeTemplates[kind];
    if (!templates || templates.length === 0) {
        throw new Error(`No templates found for kind: ${kind}`);
    }
    return templates[psudoRandomIndex % templates.length](topic);
};
