import { CircularProgress } from '@material-ui/core';
import { CallMade, ErrorOutline } from '@material-ui/icons';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import {
    ClientEventType,
    MeetingActionItem,
    SpinachIntegration,
    TicketIntegration,
    WebUrlQuery,
} from '@spinach-shared/types';
import { isTicketSource, isTicketSourceWithSubProject } from '@spinach-shared/utils';

import { getAiSummaryJson } from '../../../../apis';
import { postCreateTicket } from '../../../../apis/postCreateTicket';
import { postEditSummaryActionItem } from '../../../../apis/postEditSummaryActionItem';
import { GlobalModal, GlobalModalFrom, GlobalModalMetadataType } from '../../../../atoms';
import { useExperienceTracking, useGlobalAiDashboard, useGlobalAuthedUser, useGlobalModal } from '../../../../hooks';
import { BodyRegular, BodyRegularOnboard, ButtonSize, lightTheme } from '../../../../styles';
import { URLUtil, withContentMasking } from '../../../../utils';
import { Anchor, Column, Row, Spacing } from '../../../common';
import { OutlinedButton } from '../../../stand-up/OutlinedButton';
import SecondaryButton from '../../../stand-up/SecondaryButton';
import { getCreateTicketIntegrationRedirectCause } from '../utils';

export function ActionItemSummaryComponent({
    actionItem,
    botId,
    setActionItem,
}: {
    actionItem: MeetingActionItem;
    setActionItem: (actionItem: MeetingActionItem) => void;
    botId: string;
}): JSX.Element {
    const [user] = useGlobalAuthedUser();
    const [isLoading, setIsLoading] = useState(false);
    const [params] = useSearchParams();
    const [, setGlobalModal] = useGlobalModal();
    const [failed, setFailed] = useState(false);
    const track = useExperienceTracking();
    const { openIntegrationsSection } = useGlobalAiDashboard();

    const createdTicket = actionItem.ticketData;

    const isFullyIntegratedWithTicketSource =
        !!user.authedValidTicketCreationSources.length &&
        user.authedValidTicketCreationSources.some(
            (source) =>
                !!user.ticketSourceProject(source) &&
                (!isTicketSourceWithSubProject(source) || !!user.ticketSourceSubProject(source))
        );
    const onClick = async () => {
        if (isLoading) {
            return;
        }
        track(ClientEventType.AIDashboardClick, {
            ClickedOn: 'Action Item Create Ticket',
            IsTicketSourceKnown: !!params.get(WebUrlQuery.TicketSource),
            KnownTicketSource: params.get(WebUrlQuery.TicketSource),
        });

        if (!isFullyIntegratedWithTicketSource) {
            const {
                isAuthedWithJiraWithNoCreateScopes,
                isNotIntegratedWithAnyTicketSource,
                isIntegratedWithTicketSourceButNoProjectSelected,
                cause,
            } = getCreateTicketIntegrationRedirectCause(user);

            track(ClientEventType.AIDashboardActivity, {
                Action: 'Authorize User',
                Where: 'Actions Section',
                IsTicketSourceKnown: !!params.get(WebUrlQuery.TicketSource),
                KnownTicketSource: params.get(WebUrlQuery.TicketSource),
                IsNotIntegratedWithAnyTicketSource: isNotIntegratedWithAnyTicketSource,
                IsIntegratedWithTicketSourceButNoProjectSelected: isIntegratedWithTicketSourceButNoProjectSelected,
                IsAuthedWithJiraWithNoCreateScopes: isAuthedWithJiraWithNoCreateScopes,
            });

            setGlobalModal(GlobalModal.ConnectTicketSource, {
                from: GlobalModalFrom.ActionItemSummaryComponent,
                metadata: {
                    metadataType: GlobalModalMetadataType.TicketIntegration,
                    integrationRedirectCause: cause ?? undefined,
                },
            });
            // If no ticket source is available we should open the integraton section for the user
            const ticketSourceFromParams = params.get(WebUrlQuery.TicketSource);
            if (
                !ticketSourceFromParams ||
                !isTicketSource(ticketSourceFromParams) ||
                !user.actionItemCreationEnabledTicketSources.includes(ticketSourceFromParams)
            ) {
                openIntegrationsSection();
            }
            return;
        }

        setIsLoading(true);

        const latestSummary = await getAiSummaryJson(botId, false);
        if ('summaryJson' in latestSummary) {
            const latestActionItem = latestSummary.summaryJson.actionItems?.find((item) => item.id === actionItem.id);
            if (latestActionItem?.ticketData) {
                track(ClientEventType.AIDashboardActivity, {
                    Action: 'Sync Ticket Data',
                    Where: 'Actions Section',
                    IsTicketSourceKnown: !!params.get(WebUrlQuery.TicketSource),
                    KnownTicketSource: params.get(WebUrlQuery.TicketSource),
                    LatestActionItemTicketSource: latestActionItem.ticketData.ticketSource,
                });
                setActionItem(latestActionItem);
                setIsLoading(false);
                return;
            }
        }

        track(ClientEventType.AIDashboardActivity, {
            Action: 'Create Ticket',
            Where: 'Actions Section',
            UserAuthedTicketSources: user.authedTicketSources.join(', '),
        });

        const createdTicket = await postCreateTicket({
            title: actionItem.title,
            description: actionItem.context ?? '',
        });

        if (createdTicket?.id) {
            track(ClientEventType.AIDashboardActivity, {
                Action: 'Created Ticket',
                Where: 'Actions Section',
                ActionItemId: actionItem.id,
                TicketSource: createdTicket.ticketSource,
                TicketId: createdTicket.id,
                UserAuthedTicketSources: user.authedTicketSources.join(', '),
            });
            setActionItem({ ...actionItem, ticketData: createdTicket });
            const response = await postEditSummaryActionItem({
                actionItem: {
                    ...actionItem,
                    ticketData: {
                        title: createdTicket.title,
                        id: createdTicket.id,
                        displayName: createdTicket.displayName,
                        ticketSource: createdTicket.ticketSource,
                        ticketUrl: createdTicket.ticketUrl,
                    },
                },
                botId,
            });
            if (!response.success) {
                track(ClientEventType.AIDashboardActivity, {
                    Activity: 'Failed To Update Action Item',
                    Where: 'Actions Section',
                    BotId: botId,
                    ActionItemId: actionItem.id,
                    TicketSource: createdTicket.ticketSource,
                    TicketId: createdTicket.id,
                });
                setFailed(true);
            } else {
                track(ClientEventType.AIDashboardActivity, {
                    Action: 'Action Item Ticket Associated',
                    Where: 'Actions Section',
                    ActionItemId: actionItem.id,
                    TicketSource: createdTicket.ticketSource,
                    TicketId: createdTicket.id,
                    BotId: botId,
                    UserAuthedTicketSources: user.authedTicketSources.join(', '),
                });
            }
        } else {
            track(ClientEventType.AIDashboardActivity, {
                Activity: 'Failed To Create Ticket',
                Where: 'Actions Section',
                BotId: botId,
                ActionItemId: actionItem.id,
            });
            setFailed(true);
        }

        setIsLoading(false);
    };

    return (
        <ActionItemSubComponent
            combinedUsernames={actionItem.users.map((user) => user.username).join(', ')}
            ticketTitle={actionItem.title}
            context={actionItem.context}
            createdTicket={createdTicket}
            failed={failed}
            isLoading={isLoading}
            onClick={onClick}
            track={track}
        />
    );
}

export function ActionItemSubComponent({
    combinedUsernames,
    ticketTitle,
    context,
    createdTicket,
    failed,
    isLoading,
    onClick,
    track,
}: {
    combinedUsernames: string;
    ticketTitle: string;
    context: string;
    createdTicket?: {
        title: string;
        id: string;
        ticketSource: string;
        ticketUrl: string;
        displayName: string;
    };
    failed: boolean;
    isLoading: boolean;
    onClick: () => void;
    track: (eventType: ClientEventType, data: any) => void;
}) {
    const [user] = useGlobalAuthedUser();
    return (
        <Column>
            <Row style={{ paddingBottom: '5px' }}>
                <BodyRegularOnboard {...withContentMasking()}>
                    <span style={{ fontWeight: 'bold', paddingRight: '5px' }}>{combinedUsernames}:</span>
                    {ticketTitle}
                </BodyRegularOnboard>
            </Row>
            <BodyRegular {...withContentMasking()}>{context}</BodyRegular>
            <Spacing factor={1 / 3} />
            {createdTicket ? (
                <SecondaryButton
                    style={{ alignItems: 'center', display: 'flex' }}
                    size={ButtonSize.Small}
                    title={createdTicket.displayName ?? `${createdTicket.id} ${createdTicket.title}`}
                    endIcon={
                        <CallMade style={{ fontSize: '14px', color: lightTheme.primary.green, marginLeft: '5px' }} />
                    }
                    onClick={() => {
                        track(ClientEventType.AIDashboardClick, {
                            ClickedOn: 'Action Item Ticket Link',
                            TicketSource: createdTicket.ticketSource,
                            TicketId: createdTicket.id,
                        });
                        URLUtil.openURL(createdTicket.ticketUrl);
                    }}
                />
            ) : failed ? (
                <Row
                    style={{
                        backgroundColor: '#FFA87729',
                        borderRadius: '4px',
                        alignItems: 'center',
                        padding: '10px 8px 10px 2px',
                    }}
                >
                    <ErrorOutline style={{ color: '#F26E24', padding: '0px 8px 3px 3px' }} />
                    <TicketCreationHelp authedTicketSources={user.authedTicketSources} />
                </Row>
            ) : (
                <OutlinedButton
                    title={isLoading ? 'Creating Ticket' : '+ Create Ticket'}
                    disabled={isLoading}
                    size={ButtonSize.Mini}
                    preIcon={isLoading ? <CircularProgress size={'14px'} style={{ marginRight: '4px' }} /> : <></>}
                    style={{
                        cursor: isLoading ? 'not-allowed' : 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        width: 'fit-content',
                    }}
                    labelStyles={{
                        fontStyle: isLoading ? 'italic' : 'normal',
                    }}
                    isLoading={isLoading}
                    onClick={onClick}
                />
            )}
            <Spacing />
        </Column>
    );
}

function TicketCreationHelp({ authedTicketSources }: { authedTicketSources: TicketIntegration[] }) {
    const track = useExperienceTracking();
    const generalTicketCreationSupportUrl =
        'https://intercom.help/spinach_io/en/articles/8593494-create-tickets-from-action-items';

    const supportUrlMap = {
        [SpinachIntegration.Jira]: 'https://intercom.help/spinach_io/en/articles/6143457-jira-integration',
        [SpinachIntegration.Trello]: 'https://intercom.help/spinach_io/en/articles/8070359-trello-integration',
        [SpinachIntegration.Clickup]: 'https://intercom.help/spinach_io/en/articles/8070362-clickup-integration',
        [SpinachIntegration.Asana]: 'https://intercom.help/spinach_io/en/articles/8070352-asana-integration',
        [SpinachIntegration.Linear]: 'https://intercom.help/spinach_io/en/articles/8070355-linear-integration',
        [SpinachIntegration.MondayDotCom]: 'https://intercom.help/spinach_io/en/articles/9533397-monday-com-integration',
    };

    const supportUrl =
        authedTicketSources.length === 1 ? supportUrlMap[authedTicketSources[0]] : generalTicketCreationSupportUrl;

    if (authedTicketSources.length > 1) {
        return (
            <BodyRegularOnboard style={{ fontWeight: 400 }}>
                Spinach currently supports 1 ticketing tool at a time. Please disconnect one and try to create a ticket
                again. If you're still seeing an error contact support{' '}
                <Anchor
                    onClick={() => {
                        track(ClientEventType.AIDashboardClick, {
                            ClickedOn: 'Ticket Creation Support URL',
                        });
                        URLUtil.openURL(supportUrl);
                    }}
                >
                    here
                </Anchor>
                .
            </BodyRegularOnboard>
        );
    }

    return (
        <BodyRegularOnboard style={{ fontWeight: 400 }}>
            Ticket Creation failed. Please view these{' '}
            <Anchor
                onClick={() => {
                    track(ClientEventType.AIDashboardClick, {
                        ClickedOn: 'Ticket Creation Support URL',
                    });
                    URLUtil.openURL(supportUrl);
                }}
            >
                common troubleshooting
            </Anchor>{' '}
            tips and try again.
        </BodyRegularOnboard>
    );
}
