import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { ClientEventType, MeetingHeySpinachTicketCreationResult, WebUrlQuery } from '@spinach-shared/types';
import { isTicketSource, isTicketSourceWithSubProject } from '@spinach-shared/utils';

import { getAiSummaryJson } from '../../../../apis';
import { postCreateTicket } from '../../../../apis/postCreateTicket';
import { GlobalModal, GlobalModalFrom, GlobalModalMetadataType } from '../../../../atoms';
import { useExperienceTracking, useGlobalAiDashboard, useGlobalAuthedUser, useGlobalModal } from '../../../../hooks';
import { getCreateTicketIntegrationRedirectCause } from '../utils';
import { ActionItemSubComponent } from './ActionItemSummaryComponent';

export function HeySpinachActionItemComponent({
    actionItem,
    botId,
    onItemUpdated,
}: {
    actionItem: MeetingHeySpinachTicketCreationResult;
    onItemUpdated: (item: MeetingHeySpinachTicketCreationResult) => void;
    botId: string;
}): JSX.Element {
    const [user] = useGlobalAuthedUser();
    const [isLoading, setIsLoading] = useState(false);
    const [params] = useSearchParams();
    const [_, setGlobalModal] = useGlobalModal();
    const [failed, setFailed] = useState(false);
    const track = useExperienceTracking();
    const { openIntegrationsSection } = useGlobalAiDashboard();

    const createdTicket = actionItem.ticketData;

    const isFullyIntegratedWithTicketSource =
        !!user.authedValidTicketCreationSources.length &&
        user.authedValidTicketCreationSources.some(
            (source) =>
                !!user.ticketSourceProject(source) &&
                (!isTicketSourceWithSubProject(source) || !!user.ticketSourceSubProject(source))
        );
    const onClick = async () => {
        if (isLoading) {
            return;
        }
        track(ClientEventType.AIDashboardClick, {
            ClickedOn: 'Hey Spinach Create Ticket',
            IsTicketSourceKnown: !!params.get(WebUrlQuery.TicketSource),
            KnownTicketSource: params.get(WebUrlQuery.TicketSource),
        });

        if (!isFullyIntegratedWithTicketSource) {
            const {
                isAuthedWithJiraWithNoCreateScopes,
                isNotIntegratedWithAnyTicketSource,
                isIntegratedWithTicketSourceButNoProjectSelected,
                cause,
            } = getCreateTicketIntegrationRedirectCause(user);

            track(ClientEventType.AIDashboardActivity, {
                Action: 'Authorize User',
                Where: 'Actions Section',
                IsTicketSourceKnown: !!params.get(WebUrlQuery.TicketSource),
                KnownTicketSource: params.get(WebUrlQuery.TicketSource),
                IsNotIntegratedWithAnyTicketSource: isNotIntegratedWithAnyTicketSource,
                IsIntegratedWithTicketSourceButNoProjectSelected: isIntegratedWithTicketSourceButNoProjectSelected,
                IsAuthedWithJiraWithNoCreateScopes: isAuthedWithJiraWithNoCreateScopes,
            });

            setGlobalModal(GlobalModal.ConnectTicketSource, {
                from: GlobalModalFrom.ActionItemSummaryComponent,
                metadata: {
                    metadataType: GlobalModalMetadataType.TicketIntegration,
                    integrationRedirectCause: cause ?? undefined,
                },
            });
            // If no ticket source is available we should open the integraton section for the user
            const ticketSourceFromParams = params.get(WebUrlQuery.TicketSource);
            if (
                !ticketSourceFromParams ||
                !isTicketSource(ticketSourceFromParams) ||
                !user.actionItemCreationEnabledTicketSources.includes(ticketSourceFromParams)
            ) {
                openIntegrationsSection();
            }
            return;
        }

        setIsLoading(true);

        const latestSummary = await getAiSummaryJson(botId, false);
        if ('summaryJson' in latestSummary) {
            const latestActionItem = latestSummary.summaryJson.heySpinach?.find((item) => item.id === actionItem.id);
            if (latestActionItem && 'ticketData' in latestActionItem) {
                track(ClientEventType.AIDashboardActivity, {
                    Action: 'Sync Ticket Data',
                    Where: 'Actions Section',
                    IsTicketSourceKnown: !!params.get(WebUrlQuery.TicketSource),
                    KnownTicketSource: params.get(WebUrlQuery.TicketSource),
                    LatestActionItemTicketSource: latestActionItem.ticketData!.ticketSource,
                });
                onItemUpdated(latestActionItem);
                setIsLoading(false);
                return;
            }
        }

        track(ClientEventType.AIDashboardActivity, {
            Action: 'Create Hey Spinach Ticket',
            Where: 'Actions Section',
            UserAuthedTicketSources: user.authedTicketSources.join(', '),
        });

        const createdTicket = await postCreateTicket({
            title: actionItem.ticketTitle,
            description: actionItem.ticketDescription,
            metadata: {
                botId,
                heySpinachId: actionItem.id,
            },
        });

        if (createdTicket?.id) {
            onItemUpdated({ ...actionItem, ticketData: createdTicket });
        } else {
            track(ClientEventType.AIDashboardActivity, {
                Activity: 'Failed To Create Hey Spinach Ticket',
                Where: 'Actions Section',
                BotId: botId,
                ActionItemId: actionItem.id,
            });
            setFailed(true);
        }

        setIsLoading(false);
    };

    return (
        <ActionItemSubComponent
            combinedUsernames={actionItem.username}
            ticketTitle={actionItem.ticketTitle}
            context={actionItem.context}
            createdTicket={createdTicket}
            failed={failed}
            isLoading={isLoading}
            onClick={onClick}
            track={track}
        />
    );
}
