import { ReactNode } from 'react';
import { atom } from 'recoil';

import { DashboardSection } from '@spinach-shared/constants';
import { ISOString } from '@spinach-shared/types';

export type GlobalAiDashboardState = {
    activeSection: DashboardSection;
    hasEditedSummary: boolean;
    toastText: ReactNode;
    isLoadingMeetingNotesDeletion: boolean;
    lastRefreshedHistory: ISOString;
    reportedEventIds: string[];
};

export const atomAiDashboardState = atom<GlobalAiDashboardState>({
    key: 'aiDashboard',
    default: {
        activeSection: DashboardSection.Meetings,
        hasEditedSummary: false,
        toastText: '',
        isLoadingMeetingNotesDeletion: false,
        lastRefreshedHistory: new Date().toISOString(),
        reportedEventIds: [],
    },
});
