import { CircularProgress } from '@material-ui/core';

import { isMeetingHeySpinachTicketCreationResult } from '@spinach-shared/schemas';
import {
    ClientEventType,
    MeetingActionItem,
    MeetingHeySpinachTicketCreationResult,
    SummaryJson,
} from '@spinach-shared/types';

import { useGlobalAuthedUser, useLandingAnalytic } from '../../../../hooks';
import { BodyRegular, HeaderThree, lightTheme } from '../../../../styles';
import { SetValue } from '../../../../types';
import { Column, Spacing } from '../../../common';
import { ActionItemSummaryComponent } from './ActionItemSummaryComponent';
import { HeySpinachActionItemComponent } from './HeySpinachActionItemComponent';

export function ActionsSectionContainer({
    summaryJson,
    botId,
    setSummaryJson,
}: {
    summaryJson: SummaryJson | null;
    setSummaryJson: SetValue<SummaryJson | null>;
    botId: string;
}): JSX.Element {
    if (!summaryJson) {
        return (
            <CircularProgress
                size={'30px'}
                style={{ marginLeft: '10px', marginBottom: '15px', color: lightTheme.primary.orangeDark }}
            />
        );
    }

    const actionItems = summaryJson.actionItems;

    return (
        <Column>
            {!actionItems?.length ? (
                <BodyRegular>No action items for this meeting</BodyRegular>
            ) : (
                <ActionsSection
                    botId={botId}
                    summaryJson={{ ...summaryJson, actionItems }}
                    setSummaryJson={setSummaryJson}
                />
            )}
        </Column>
    );
}

function ActionsSection({
    botId,
    summaryJson,
    setSummaryJson,
}: {
    botId: string;
    summaryJson: Omit<SummaryJson, 'actionItems'> & { actionItems: MeetingActionItem[] };
    setSummaryJson: SetValue<SummaryJson | null>;
}): JSX.Element {
    const [user] = useGlobalAuthedUser();
    const actionItems = summaryJson.actionItems;
    const heySpinachTickets: MeetingHeySpinachTicketCreationResult[] =
        summaryJson.heySpinach?.filter(isMeetingHeySpinachTicketCreationResult) ?? [];
    // Only run landing analytics after load, the user has not really "landed" on the page until then
    useLandingAnalytic(ClientEventType.AIDashboardActivity, {
        Action: 'Opened Actions Section',
        BotId: botId,
        NumberOfActionItems: actionItems?.length,
        NumberOfActionItemsWithTicket: actionItems?.filter((ai) => !!ai.ticketData).length,
    });

    return (
        <>
            <HeaderThree>Action Items</HeaderThree>
            <Spacing factor={1 / 3} />
            {user.isHeySpinachCreateTicketEnabled &&
                heySpinachTickets.map((item) => {
                    const onItemUpdated = (updatedHeySpinach: MeetingHeySpinachTicketCreationResult) => {
                        setSummaryJson({
                            ...summaryJson,
                            heySpinach: heySpinachTickets.map((ticket) =>
                                ticket.id === item.id ? updatedHeySpinach : ticket
                            ),
                        });
                    };

                    return (
                        <HeySpinachActionItemComponent
                            key={item.id}
                            actionItem={item}
                            onItemUpdated={onItemUpdated}
                            botId={botId}
                        />
                    );
                })}
            {actionItems.map((actionItem, index) => {
                const setActionItem = (updatedActionItem: MeetingActionItem) => {
                    setSummaryJson({
                        ...summaryJson,
                        actionItems: [
                            ...actionItems.slice(0, index),
                            updatedActionItem,
                            ...actionItems.slice(index + 1),
                        ],
                    });
                };

                return (
                    <ActionItemSummaryComponent
                        key={actionItem.id}
                        actionItem={actionItem}
                        setActionItem={setActionItem}
                        botId={botId}
                    />
                );
            })}
        </>
    );
}
