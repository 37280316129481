import { getAgentSearchParams } from '../../utils/agent';

export type PostVideoAgentSpeechOptions = {
    speech: string;
    cacheRemotely?: boolean;
};

export async function postVideoAgentSpeechSrc(options: PostVideoAgentSpeechOptions) {
    const { speech, cacheRemotely } = options;
    const { scribeServer, token, botId } = getAgentSearchParams();

    if (!token) {
        return '';
    }

    const data = fetch(`${scribeServer}/video-agent/${botId}/generate-audio`, {
        method: 'POST',
        headers: {
            Authorization: token,
        },
        body: JSON.stringify({
            text: speech,
            cacheRemotely,
        }),
    });
    const audio = await data.then((res) => res.json());
    const audioSrc = `data:audio/wav;base64,${audio.audio}`;
    return audioSrc;
}
