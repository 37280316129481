import { Titles } from '@spinach-clients/constants';
import { useEffect } from 'react';

import { MILLIS_IN_SECONDS, SECONDS_IN_MINUTES } from '@spinach-shared/constants';
import { ClientUser } from '@spinach-shared/models';
import { ClientEventType } from '@spinach-shared/types';

import { getUser, postExperienceEvent } from '../../../apis';
import { getStoredSeries } from '../../../apis';
import { ElementId } from '../../../constants';
import { useGlobalAuthedUser, useGlobalRouting, useLandingAnalytic } from '../../../hooks';
import { BodyRegular, HeaderThree } from '../../../styles';
import { SlidingColumn, Spacing } from '../../common';
import SecondaryButton from '../../stand-up/SecondaryButton';
import { ScrollView } from '../common';
import { SLogo, Stationary } from './common';
import { RequestAndRedirectProps } from './types';

export function RequestAndRedirect({ onBack, direction, user }: RequestAndRedirectProps): JSX.Element {
    const [_, setUser] = useGlobalAuthedUser();
    const { routeToSeriesExperience } = useGlobalRouting();
    useLandingAnalytic(ClientEventType.UserLandedOnJoinStandupView, user.toUserIdentityPayload());

    useEffect(() => {
        const interval = setInterval(async () => {
            const userResponse = await getUser();

            if (userResponse.user) {
                setUser(userResponse.user);
                const latestUser = new ClientUser(userResponse.user);

                const idOfOnlySeries = latestUser.getSeriesIdOfOnlySeries();

                if (idOfOnlySeries) {
                    postExperienceEvent({
                        eventType: ClientEventType.UserRedirectingFromJoinFlowToStandup,
                        payload: user.toUserIdentityPayload(),
                    });

                    const seriesJustAddedToUsersAccount = await getStoredSeries(idOfOnlySeries);

                    if (seriesJustAddedToUsersAccount) {
                        routeToSeriesExperience(seriesJustAddedToUsersAccount.slug);
                    }
                }
            }
        }, 10 * MILLIS_IN_SECONDS);

        setTimeout(() => {
            clearInterval(interval);
        }, MILLIS_IN_SECONDS * SECONDS_IN_MINUTES * 20);

        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, []);

    return (
        <Stationary id={ElementId.SeriesDashboardContainer}>
            <SLogo style={{ alignSelf: 'flex-start' }} />
            <ScrollView sidePadding={0}>
                <SlidingColumn direction={direction} centered>
                    <HeaderThree style={{ textAlign: 'center' }}>Request an invite</HeaderThree>

                    <Spacing />

                    <BodyRegular style={{ textAlign: 'center' }}>
                        Have someone from your team invite you. If they invite you now, we’ll take you straight to your
                        standup from here.
                    </BodyRegular>

                    <Spacing factor={1 / 2} />

                    <BodyRegular style={{ textAlign: 'center' }}>
                        You’ll also get an email so you can join whenever you’re ready.
                    </BodyRegular>

                    <Spacing />

                    <SecondaryButton title={Titles.Back} onClick={onBack} />
                </SlidingColumn>
            </ScrollView>
        </Stationary>
    );
}
