import { useEffect, useState } from 'react';

import { ClientEventType, ScribeMetadata } from '@spinach-shared/types';
import { StoredSpinachSeries } from '@spinach-shared/utils';

import { patchSeries, useExperienceTracking } from '../..';
import { useGlobalStoredSeriesList } from './useGlobalStoredSeriesList';

export const useSelectSummaryAccess = ({ storedSeries, from }: { storedSeries: StoredSpinachSeries; from: string }) => {
    const { updateSeriesInList } = useGlobalStoredSeriesList();
    const track = useExperienceTracking();
    const [isEmailingIcpOnly, setIsEmailingIcpOnly] = useState(storedSeries.isEmailingIcpOnly);

    useEffect(() => {
        setIsEmailingIcpOnly(storedSeries.isEmailingIcpOnly);
    }, [storedSeries.isEmailingIcpOnly]);

    return {
        isEmailingIcpOnly,
        onSelect: async (isEmailingIcpOnly: boolean) => {
            try {
                setIsEmailingIcpOnly(isEmailingIcpOnly);
                const seriesJSON = storedSeries.toJSON();
                /** @NOTE optimistic update */
                updateSeriesInList({
                    ...seriesJSON,
                    metadata: {
                        ...seriesJSON.metadata,
                        scribeMetadata: {
                            ...(seriesJSON.metadata?.scribeMetadata as ScribeMetadata),
                            isEmailingIcpOnly,
                        },
                    },
                });
                track(ClientEventType.AIDashboardClick, {
                    ClickedOn: `Selected Email Recipients Option (${from})`,
                });

                const updatedSeries = await patchSeries(storedSeries.id, {
                    metadata: {
                        scribeMetadata: {
                            isEmailingIcpOnly,
                        },
                    },
                });
                if (updatedSeries) {
                    updateSeriesInList(updatedSeries);
                }
            } catch (e) {
                updateSeriesInList(storedSeries.toJSON());
                setIsEmailingIcpOnly(!isEmailingIcpOnly);
            }
        },
    };
};
