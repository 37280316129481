import { ClientEventType } from '@spinach-shared/types';

import { useGlobalAuthedUser, useLandingAnalytic } from '../../../hooks';
import { BodyBigOnboard, HeaderThreeOnboard } from '../../../styles';
import { Row, SlidingColumn, Spacing } from '../../common';
import { AsanaIntegrationRow } from '../../common/settings/AsanaIntegrationRow';
import { ClickUpIntegrationRow } from '../../common/settings/ClickUpIntegrationRow';
import { JiraIntegrationRow } from '../../common/settings/JiraIntegrationRow';
import { LinearIntegrationRow } from '../../common/settings/LinearIntegrationRow';
import { TrelloIntegrationRow } from '../../common/settings/TrelloIntegrationRow';
import { ScrollView } from '../../series';
import { PrimaryButton } from '../../stand-up';
import SecondaryButton from '../../stand-up/SecondaryButton';
import { BackButtonRow, Stationary, StepProps } from './common';

export type TicketSetupProps = StepProps & {
    onBack: () => void;
    onSkip: () => void;
};

export function TicketSetup({ onBack, direction, onSubmit, onSkip }: TicketSetupProps): JSX.Element {
    useLandingAnalytic(ClientEventType.UserViewedConnectTicketsStep);
    const [user] = useGlobalAuthedUser();
    const hasAnyIntegration =
        user.isAuthedForAsana ||
        user.isAuthedForClickUp ||
        user.isAuthedForJira ||
        user.isAuthedForLinear ||
        user.isAuthedForTrello;
    return (
        <Stationary>
            <BackButtonRow onBack={onBack} />
            <HeaderThreeOnboard>Connect your ticketing tools</HeaderThreeOnboard>
            <Spacing factor={1 / 2} />
            <BodyBigOnboard>Spinach will link to existing tickets you discuss.</BodyBigOnboard>
            <Spacing />
            <ScrollView
                sidePadding={0}
                stretch
                style={{ backgroundColor: 'rgba(0,0,0,0)', background: 'unset', maxWidth: '515px' }}
            >
                <SlidingColumn centered direction={direction}>
                    <JiraIntegrationRow
                        message={'Add Jira tickets to your summary'}
                        isAi={true}
                        isConnectViewOnly={true}
                    />
                    <AsanaIntegrationRow isConnectViewOnly={true} />
                    <LinearIntegrationRow isConnectViewOnly={true} />
                    <ClickUpIntegrationRow isConnectViewOnly={true} />
                    <TrelloIntegrationRow isConnectViewOnly={true} />
                    <Spacing />
                </SlidingColumn>
            </ScrollView>
            <Spacing />
            <Row centered>
                <PrimaryButton title={'Next'} onClick={onSubmit} disabled={!hasAnyIntegration} />
            </Row>
            <Spacing factor={1 / 2} />
            <Row centered>
                <SecondaryButton title={'Skip'} onClick={onSkip} />
            </Row>
        </Stationary>
    );
}
