import { Box } from '@material-ui/core';

import { safeWidth } from '../../../constants';

export function AgentNotificationBanner({ text, visible }: { text: string; visible: boolean }): JSX.Element {
    return (
        <Box
            style={{
                left: 0,
                right: 0,
                textAlign: 'center',
                width: '100%',
                fontSize: '45px',
                fontWeight: 'bold',
                color: 'white',
                backgroundImage: 'linear-gradient(to bottom, #2a8381, #008e7f, #009974, #00a262, #20aa48)',
                position: 'fixed',
                zIndex: 55,
                textTransform: 'capitalize',
                top: visible ? '0px' : '-200px',
                transition: 'top 0.5s ease-in-out',
                lineHeight: '1.2',
                whiteSpace: 'nowrap',
            }}
        >
            <Box maxWidth={safeWidth} py="25px" margin="auto">
                {text}
            </Box>
        </Box>
    );
}
