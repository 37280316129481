import axios from 'axios';

import { ClientVideoAgentSessionJSON, PostVideoAgentChatMessageRequest } from '@spinach-shared/types';
import { getDefaultRequestHeaders, isLocalStage } from '@spinach-shared/utils';

import { getAgentSearchParams } from '../../..';
import { mockBotId } from '../../constants';
import { getHeaderOpts } from '../../utils/api';

function chunkString(str: string, maxChunkSize: number): string[] {
    const chunks: string[] = [];
    let currentChunk = '';
    let currentWord = '';

    for (let i = 0; i < str.length; i++) {
        const char = str[i];

        if (isWhitespace(char)) {
            if (currentWord) {
                if (currentChunk.length + (currentChunk ? 1 : 0) + currentWord.length > maxChunkSize) {
                    if (currentChunk) {
                        chunks.push(currentChunk.trim());
                        currentChunk = '';
                    }
                    while (currentWord.length > maxChunkSize) {
                        chunks.push(currentWord.slice(0, maxChunkSize));
                        currentWord = currentWord.slice(maxChunkSize);
                    }
                }
                currentChunk += (currentChunk ? ' ' : '') + currentWord;
                currentWord = '';
            }
            if (currentChunk.length < maxChunkSize) {
                currentChunk += char;
            } else {
                chunks.push(currentChunk.trim());
                currentChunk = char;
            }
        } else {
            if (currentWord.length + 1 > maxChunkSize) {
                if (currentChunk) {
                    chunks.push(currentChunk.trim());
                    currentChunk = '';
                }
                chunks.push(currentWord);
                currentWord = char;
            } else {
                currentWord += char;
            }
        }
    }

    // Handle the last word
    if (currentWord) {
        if (currentChunk.length + (currentChunk ? 1 : 0) + currentWord.length > maxChunkSize) {
            if (currentChunk) {
                chunks.push(currentChunk.trim());
            }
            while (currentWord.length > maxChunkSize) {
                chunks.push(currentWord.slice(0, maxChunkSize));
                currentWord = currentWord.slice(maxChunkSize);
            }
            chunks.push(currentWord);
        } else {
            currentChunk += (currentChunk ? ' ' : '') + currentWord;
        }
    }

    // Add the last chunk if it's not empty
    if (currentChunk) {
        chunks.push(currentChunk.trim());
    }

    return chunks;
}

function isWhitespace(char: string): boolean {
    return char === ' ' || char === '\n' || char === '\t';
}

const maxGoogleMeetMessageSize = 500;

export async function postVideoAgentChatMessage(
    payload: PostVideoAgentChatMessageRequest,
    autoChunk = false
): Promise<ClientVideoAgentSessionJSON | undefined> {
    const { scribeServer, token } = getAgentSearchParams();

    if (payload.botId === mockBotId && isLocalStage()) {
        console.log(`Agent Chat Output: ${payload.message}`);
        return;
    }

    if (autoChunk) {
        const chunks = chunkString(payload.message, maxGoogleMeetMessageSize).slice(-10);
        for (const chunk of chunks) {
            await postVideoAgentChatMessage({ ...payload, message: chunk }, false);
        }
        return;
    }

    const response = await axios.post(`${scribeServer}/video-agent/chat`, payload, {
        headers: {
            ...getDefaultRequestHeaders(getHeaderOpts()),
            'Content-Type': 'application/json',
            'Cache-Control': 'no-store',
            authorization: token,
        },
    });

    return response.data;
}
