import { FeatureIntent, ScribeMeetingType, Stage, SummaryFeatureIntents } from '@spinach-shared/types';

export const SLACK_HUDDLE_BOT_EMAIL_PROD = 'bot@slack.spinach.io' as const;
export const SLACK_HUDDLE_BOT_EMAIL_TEST = 'bot2@slackbot.test.spinach.io' as const;

/**
 * @NOTE If a key in MongoDB contains a "." in it, it will create a nested object in the document.
 * To avoid this, we replace the "." with this string.
 * */
export const MONGODB_DOT_REPLACEMENT_FOR_KEY_NAME = '-- dot --';

const ATLASSAIN_ENTERPRISE_SCRIBE_EMAILS = [
    'atlassian@spinach.io' as const,
    'atlassian2@spinach.io' as const,
    'atlassian3@spinach.io' as const,
    'atlassian4@spinach.io' as const,
    'atlassian5@spinach.io' as const,
];
const ENTERPRISE_SCRIBE_EMAILS_PROD = [...ATLASSAIN_ENTERPRISE_SCRIBE_EMAILS];

export const ENTERPRISE_SCRIBE_EMAIL_MAP_PROD: Record<string, typeof ENTERPRISE_SCRIBE_EMAILS_PROD>[] = [
    {
        '@atlassian.com': ATLASSAIN_ENTERPRISE_SCRIBE_EMAILS,
    },
];

export const SCRIBE_EMAILS_PROD = [
    'spinach@spinach.io',
    'ai@spinach.io',
    'scrum@spinach.io',
    'join@spinach.io',
    'meet@spinach.io',
    'go@spinach.io',
    'run@spinach.io',
    'pro@spinach.io',
    'add@spinach.io',
    'get@spinach.io',
    'invite@spinach.io',
    ...ATLASSAIN_ENTERPRISE_SCRIBE_EMAILS,
    'book@spinach.io',
    'sub@spinach.io',
    SLACK_HUDDLE_BOT_EMAIL_PROD,
    'sano@spinach.io',
    'agent-1@spinach.io',
] as const;
export const SCRIBE_EMAILS_TEST = [
    'scribe-test@spinach.io',
    'scribe-test-2@spinach.io',
    SLACK_HUDDLE_BOT_EMAIL_TEST,
] as const;

export const ALL_KNOWN_SCRIBE_EMAILS: string[] = [...SCRIBE_EMAILS_PROD, ...SCRIBE_EMAILS_TEST];

export function getScribeEmails(stage: Stage): readonly string[] {
    return stage === Stage.Production ? SCRIBE_EMAILS_PROD : SCRIBE_EMAILS_TEST;
}

export function getDefaultScribeEmail(stage: Stage, rootDomain?: string): string {
    // default to ai@spinach.io
    if (rootDomain && Stage.Production) {
        const enterpriseDomainMap = ENTERPRISE_SCRIBE_EMAIL_MAP_PROD.find((enterpriseMap) => enterpriseMap[rootDomain]);
        if (enterpriseDomainMap) {
            const validScribeEmailsForEnterprise = enterpriseDomainMap[rootDomain];
            return validScribeEmailsForEnterprise[Math.floor(Math.random() * validScribeEmailsForEnterprise.length)];
        }
    }
    return stage === Stage.Production ? getScribeEmails(stage)[1] : getScribeEmails(stage)[0];
}

export function getSlackHuddleBotEmail(stage: Stage): string {
    return stage === Stage.Production ? SLACK_HUDDLE_BOT_EMAIL_PROD : SLACK_HUDDLE_BOT_EMAIL_TEST;
}

export const DefaultStandupSummaryFeatureIntents: SummaryFeatureIntents[] = [
    FeatureIntent.MeetingHeySpinachSection,
    FeatureIntent.MeetingBlockers,
    FeatureIntent.MeetingKeyDecisions,
    FeatureIntent.MeetingCelebrations,
    FeatureIntent.MeetingActionItems,
    FeatureIntent.PerPersonUpdates,
    FeatureIntent.PreviousMeetingInsights,
    FeatureIntent.MeetingWorkstreams,
];
export const DeafultRetroSummaryFeatureIntents: SummaryFeatureIntents[] = [
    FeatureIntent.MeetingHeySpinachSection,
    FeatureIntent.MeetingActionItems,
    FeatureIntent.MeetingWhatWentWell,
    FeatureIntent.MeetingWhatDidNotGoWell,
    FeatureIntent.MeetingWhatCanBeImproved,
];

export const DefaultGenericSummaryFeatureIntents: SummaryFeatureIntents[] = [
    FeatureIntent.MeetingHeySpinachSection,
    FeatureIntent.MeetingBlockers,
    FeatureIntent.MeetingKeyDecisions,
    FeatureIntent.MeetingCelebrations,
    FeatureIntent.MeetingActionItems,
    FeatureIntent.MeetingAgendaItems,
    FeatureIntent.PreviousMeetingInsights,
    FeatureIntent.MeetingWorkstreams,
];

export const PartiallyCappedSummaryFeatureIntents: SummaryFeatureIntents[] = [FeatureIntent.MeetingAgendaItems];

export const MEETING_TYPE_FEATURE_INTENT_MAP: Record<ScribeMeetingType, SummaryFeatureIntents[]> = {
    [ScribeMeetingType.BacklogGrooming]: [],
    [ScribeMeetingType.SprintPlanning]: [],
    [ScribeMeetingType.Generic]: DefaultGenericSummaryFeatureIntents,

    [ScribeMeetingType.Retro]: DeafultRetroSummaryFeatureIntents,

    [ScribeMeetingType.Standup]: DefaultStandupSummaryFeatureIntents,

    [ScribeMeetingType.UserResearchInterview]: [
        FeatureIntent.KeyResearchTakeaways,
        FeatureIntent.ResearchInterviewQuotesBank,
    ],
    [ScribeMeetingType.CustomerSuccessCheckIn]: [
        FeatureIntent.MeetingActionItems,
        FeatureIntent.MeetingCustomerSuccessCheckIn,
    ],
    [ScribeMeetingType.GeneralSalesMeeting]: [FeatureIntent.MeetingActionItems, FeatureIntent.MeetingGeneralSalesCall],
};

export const DEFAULT_HEY_SPINACH_THRESHOLD = 0.85;

// taken from https://github.com/openai/whisper/blob/main/whisper/tokenizer.py
export const ENGLISH_LANGUAGE = 'English';
const SPANISH_LANGUAGE = 'Spanish';
const FRENCH_LANGUAGE = 'French';
const GERMAN_LANGUAGE = 'German';
const ITALIAN_LANGUAGE = 'Italian';
const PORTUGUESE_LANGUAGE = 'Portuguese';
const DUTCH_LANGUAGE = 'Dutch';

export const MOST_COMMON_LANGUAGES = [
    ENGLISH_LANGUAGE,
    SPANISH_LANGUAGE,
    GERMAN_LANGUAGE,
    FRENCH_LANGUAGE,
    ITALIAN_LANGUAGE,
    PORTUGUESE_LANGUAGE,
    DUTCH_LANGUAGE,
];
export const MEETING_LANGUAGE_SEPARATOR = '-----------';
export const MEETING_LANGAUGES = [
    ...MOST_COMMON_LANGUAGES,
    MEETING_LANGUAGE_SEPARATOR,
    'Afrikaans',
    'Albanian',
    'Amharic',
    'Arabic',
    'Armenian',
    'Assamese',
    'Azerbaijani',
    'Bashkir',
    'Basque',
    'Belarusian',
    'Bengali',
    'Bosnian',
    'Breton',
    'Bulgarian',
    'Cantonese',
    'Catalan',
    'Chinese',
    'Croatian',
    'Czech',
    'Danish',
    DUTCH_LANGUAGE,
    ENGLISH_LANGUAGE,
    'Estonian',
    'Faroese',
    'Finnish',
    FRENCH_LANGUAGE,
    'Galician',
    'Georgian',
    GERMAN_LANGUAGE,
    'Greek',
    'Gujarati',
    'Haitian Creole',
    'Hausa',
    'Hawaiian',
    'Hebrew',
    'Hindi',
    'Hungarian',
    'Icelandic',
    'Indonesian',
    ITALIAN_LANGUAGE,
    'Japanese',
    'Javanese',
    'Kannada',
    'Kazakh',
    'Khmer',
    'Korean',
    'Lao',
    'Latin',
    'Latvian',
    'Lingala',
    'Lithuanian',
    'Luxembourgish',
    'Macedonian',
    'Malagasy',
    'Malay',
    'Malayalam',
    'Maltese',
    'Maori',
    'Marathi',
    'Mongolian',
    'Myanmar',
    'Nepali',
    'Norwegian',
    'Nynorsk',
    'Occitan',
    'Pashto',
    'Persian',
    'Polish',
    PORTUGUESE_LANGUAGE,
    'Punjabi',
    'Romanian',
    'Russian',
    'Sanskrit',
    'Serbian',
    'Shona',
    'Sindhi',
    'Sinhala',
    'Slovak',
    'Slovenian',
    'Somali',
    SPANISH_LANGUAGE,
    'Sundanese',
    'Swahili',
    'Swedish',
    'Tagalog',
    'Tajik',
    'Tamil',
    'Tatar',
    'Telugu',
    'Thai',
    'Tibetan',
    'Turkish',
    'Turkmen',
    'Ukrainian',
    'Urdu',
    'Uzbek',
    'Vietnamese',
    'Welsh',
    'Yiddish',
    'Yoruba',
];

export const OUTPUT_LANGUAGES = [
    ...MOST_COMMON_LANGUAGES,
    MEETING_LANGUAGE_SEPARATOR,
    'Afrikaans',
    'Albanian',
    'Amharic',
    'Arabic',
    'Armenian',
    'Azerbaijani',
    'Basque',
    'Belarusian',
    'Bengali',
    'Bosnian',
    'British English',
    'Bulgarian',
    'Burmese',
    'Catalan',
    'Chinese',
    'Croatian',
    'Czech',
    'Danish',
    DUTCH_LANGUAGE,
    ENGLISH_LANGUAGE,
    'Esperanto',
    'Estonian',
    'Filipino',
    'Finnish',
    FRENCH_LANGUAGE,
    'Galician',
    'Georgian',
    GERMAN_LANGUAGE,
    'Greek',
    'Gujarati',
    'Hausa',
    'Hebrew',
    'Hindi',
    'Hungarian',
    'Icelandic',
    'Igbo',
    'Indonesian',
    'Irish',
    ITALIAN_LANGUAGE,
    'Japanese',
    'Kannada',
    'Kazakh',
    'Khmer',
    'Kinyarwanda',
    'Korean',
    'Kurdish',
    'Kyrgyz',
    'Lao',
    'Latin',
    'Latvian',
    'Lithuanian',
    'Macedonian',
    'Malay',
    'Malayalam',
    'Maltese',
    'Marathi',
    'Mongolian',
    'Nepali',
    'Norwegian',
    'Pashto',
    'Persian',
    'Polish',
    PORTUGUESE_LANGUAGE,
    'Punjabi',
    'Romanian',
    'Russian',
    'Scottish Gaelic',
    'Serbian',
    'Sindhi',
    'Sinhala',
    'Slovak',
    'Slovenian',
    'Somali',
    SPANISH_LANGUAGE,
    'Swahili',
    'Swedish',
    'Tajik',
    'Tamil',
    'Telugu',
    'Thai',
    'Tibetan',
    'Turkish',
    'Turkmen',
    'Ukrainian',
    'Urdu',
    'Uzbek',
    'Vietnamese',
    'Welsh',
    'Yoruba',
    'Zulu',
];

export const pauseCommands = ['pause', 'stop'];
export const resumeCommands = ['resume', 'unpause', 'play'];
