import { getDefaultScribeEmail, getScribeEmails } from '@spinach-shared/constants';
import { FeatureToggle } from '@spinach-shared/types';
import { getStage } from '@spinach-shared/utils';

import { isFeatureEnabled } from '../utils';
import { useGlobalAuthedUser } from './useGlobalUser';

export function useScribeEmail(): string {
    const [user] = useGlobalAuthedUser();
    const scribeEmails = getScribeEmails(getStage());
    const defaultScribeEmail = getDefaultScribeEmail(getStage(), user.metadata.rootDomain);
    const scribeEmail = isFeatureEnabled(user.featureToggles, FeatureToggle.ScribeEmail, defaultScribeEmail);

    // if the value is not in the list of scribe emails for some reason, return the default scribe email
    if (!scribeEmails.includes(scribeEmail.toLocaleLowerCase())) {
        return defaultScribeEmail;
    }
    return scribeEmail;
}

export function getAllScribeEmails(): readonly string[] {
    return getScribeEmails(getStage());
}

export function isScribeEmail(email?: string | null): boolean {
    return !!email && getAllScribeEmails().includes(email.toLowerCase());
}
