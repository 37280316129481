import { ClientVideoAgentSessionJSON } from './Scribe';
import { Series } from './Series';

export enum ServerSocketEvent {
    SeriesUpdated = 'SERIES_UPDATED',
    SlackMessageSent = 'SLACK_MESSAGE_SENT',
    Notification = 'NOTIFICATION',
    ClientRefresh = 'CLIENT_REFRESH',
    YTBHistoryUpdated = 'YTB_HISTORY_UPDATED',
    AvatarStateUpdated = 'AVATAR_STATE_UPDATED',
    AvatarTaskClassified = 'AVATAR_TASK_CLASSIFIED',
    AvatarLiveNotesGenerated = 'AVATAR_LIVE_NOTES_GENERATED',
    HeySpinachDetected = 'HEY_SPINACH_DETECTED',
    VideoAgentUpdated = 'VIDEO_AGENT_UPDATED',
}

export enum ClientSocketEvent {
    ParticipantJoining = 'PARTICIPANT_JOINING',
    ParticipantMoodUpdating = 'PARTICIPANT_MOOD_UPDATING',

    WholeTeamReaction = 'WHOLE_TEAM_REACTION',

    AgendaRestarting = 'AGENDA_RESTARTING',
    AgendaStarting = 'AGENDA_STARTING',
    AgendaProgressing = 'AGENDA_PROGRESSING',
    AgendaNavigating = 'AGENDA_NAVIGATING',
    AgendaReversing = 'AGENDA_REVERSING',
    AgendaItemAdding = 'AGENDA_ITEM_ADDING',
    AgendaItemDeleting = 'AGENDA_ITEM_DELETING',
    AgendaItemUpdating = 'AGENDA_ITEM_UPDATING',
    SheduleAgenda = 'SUBMIT_ASYNC_AGENDA',
    ModulesUpdating = 'MODULES_UPDATING',
    SeriesNameUpdating = 'SERIES_NAME_UPDATING',
    AgendaReorder = 'AGENDA_REORDERING',

    YTBParticipantReadying = 'YTB_PARTICIPANT_READYING',
    YTBUpdateUpdating = 'YTB_UPDATE_UPDATING',
    YTBUpdateConfirming = 'YTB_UPDATE_CONFIRMING',
    YTBHistoryConfirming = 'YTB_HISTORY_CONFIRMING',
    YTBAddTopic = 'YTB_ADD_TOPIC',

    IcebreakerShuffle = 'ICEBREAKER_SHUFFLE',
    IcebreakerLockIn = 'ICEBREAKER_LOCK_IN',
    AgendaShuffle = 'AGENDA_SHUFFLE',

    MeditationStarting = 'MEDITATION_STARTING',
    MeditationAudioStarting = 'MEDITATION_AUDIO_STARTING',
    MeditationAdded = 'MEDITATION_ADDED',
    MeditationRemoved = 'MEDITATION_REMOVED',
    MeditationEnding = 'MEDITATION_ENDING',

    Disconnecting = 'disconnecting',

    MeetingInactive = 'MEETING_INACTIVE',
    TriggerMeetingInactiveModal = 'TRIGGER_MEETING_INACTIVE_MODAL',
    TriggerRemoveMeetingInactiveModal = 'TRIGGER_REMOVE_INACTIVITY_MODAL',

    HubConnection = 'HUB_CONNECTION',
    HubDraftUpdated = 'HUB_DRAFT_UPDATED',
    HubUpdateConfirming = 'HUB_UPDATE_CONFIRMING',
    HubClientRefreshed = 'HUB_CLIENT_REFRESHED',

    SendScheduleSummary = 'SEND_SCHEDULE_SUMMARY',
    SendChannelSelection = 'SEND_CHANNEL_SELECTION',

    UpdatingSeriesComponents = 'UPDATING_SERIES_COMPONENTS',

    NewEmailSummarySubscribing = 'NEW_EMAIL_SUMMARY_SUBSCRIBING',

    AvatarPrepareTicket = 'AVATAR_PREPARE_TICKET',
    AvatarCreateTicket = 'AVATAR_CREATE_TICKET',
    ClassifyAvatarTask = 'CLASSIFY_AVATAR_TASK',
    AvatarTranscriptChunkCollected = 'AVATAR_TRANSCRIPT_CHUNK_COLLECTED',
    AvatarUpsertLiveNote = 'AVATAR_UPSERT_LIVE_NOTE',
    AvatarUpsertTopicObjective = 'AVATAR_UPSERT_TOPIC_OBJECTIVE',
    HeySpinachDetected = 'HEY_SPINACH_DETECTED',

    VideoAgentConnecting = 'VIDEO_AGENT_CONNECTING',
}

export enum NotificationCode {
    SlackOutputSuccessful = 'SLACK_OUTPUT_SUCCESSFUL',
    SlackOutputFailure = 'SLACK_OUTPUT_FAILURE',
    SlackOutputLimited = 'SLACK_OUTPUT_LIMITED',
    ShowInactivityModal = 'SHOW_INACTIVITY_MODAL',
    RemoveInactivityModal = 'REMOVE_INACTIVITY_MODAL',
    WholeTeamReaction = 'WHOLE_TEAM_REACTION',
    WaitForAiSummary = 'SLACK_WAIT_FOR_AI_SUMMARY',
    BotPaused = 'BOT_PAUSED',
    BotResumed = 'BOT_RESUMED',
    HeySpinachDetected = 'HEY_SPINACH_DETECTED',
}

export type NotificationPayload = {
    message: string;
    code: NotificationCode;
    triggeringSpinachUserId?: string;
};

export type ServerResponseTimeProps = {
    serverResponseTime: number;
};

export type SeriesUpdatedResponse = ServerResponseTimeProps & {
    series: Series;
};

export type VideoAgentUpdatedResponse = {
    session: ClientVideoAgentSessionJSON;
};
