import { isProductionStage } from '@spinach-shared/utils';

import { useGlobalNullableVideoAgent } from '../../../..';

export function AgentBackgrounds({
    proccessingAskSpinach,
    answeringQuestion,
}: {
    proccessingAskSpinach?: boolean;
    answeringQuestion?: boolean;
}): JSX.Element {
    const {
        state: { assetMap },
        session,
    } = useGlobalNullableVideoAgent();

    const shouldShowJoshTypography =
        session?.topics.some((topic) => topic.title.toLocaleLowerCase().includes('typograhy')) && !isProductionStage();

    const listeningStyles: React.CSSProperties = {
        display: 'block',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        zIndex: shouldShowJoshTypography ? 1001 : 3,
    };

    const processingAskSpinachStyles: React.CSSProperties = {
        display: proccessingAskSpinach ? 'block' : 'none',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        zIndex: 1002,
    };

    const answeringQuestionStyles: React.CSSProperties = {
        display: answeringQuestion ? 'block' : 'none',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        zIndex: 1003,
    };

    return (
        <>
            <video
                id="videoPlayer"
                src={assetMap?.listeningSrc}
                autoPlay
                muted
                loop
                playsInline
                style={listeningStyles}
            />
            <video
                id="videoPlayer2"
                src={assetMap?.askSpinachThinking}
                autoPlay
                muted
                loop
                playsInline
                style={processingAskSpinachStyles}
            />
            <video
                id="videoPlayer3"
                src={assetMap?.askSpinachAnswering}
                autoPlay
                muted
                loop
                playsInline
                style={answeringQuestionStyles}
            />
        </>
    );
}
