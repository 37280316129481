import { useEffect } from 'react';

import { ServerSocketEvent, VideoAgentUpdatedResponse } from '@spinach-shared/types';

import { useGlobalNullableVideoAgentSocket } from '../useGlobalSocket';
import { useGlobalNullableVideoAgent } from './useGlobalVideoAgent';

export function useVideoAgentSocketSyncing() {
    const { setSession } = useGlobalNullableVideoAgent();
    const [socket] = useGlobalNullableVideoAgentSocket();

    useEffect(() => {
        const callback = (response: VideoAgentUpdatedResponse) => {
            setSession(response.session);
        };

        socket?.on(ServerSocketEvent.VideoAgentUpdated, callback);

        return () => {
            socket?.off(ServerSocketEvent.VideoAgentUpdated, callback);
        };
    }, [socket, setSession]);
}
