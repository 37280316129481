import { useEffect } from 'react';

import { ClientEventType } from '@spinach-shared/types';

import { useExperienceTracking, useGlobalAuthedUser } from '../../../..';
import { useFullStory } from '../../../hooks/useStartupProcesses';
import { OnboardingFlowContainer } from './OnboardingFlow';
import { AgentOnboardingFlowContainer } from './agent-onboarding/AgentOnboardingFlowContainer';
import { OnboardingFlowWithCalendarV2Container } from './calendar-step-experimentation';
import { ExperimentOneOnboardingFlowContainer } from './experiment-one';

export function AiOnboardingWithExperimentation(): JSX.Element {
    const [user] = useGlobalAuthedUser();
    const track = useExperienceTracking();
    useFullStory();
    useEffect(() => {
        track(ClientEventType.UserStartedAiOnboardingFlow, {
            IsEnabledForCalendarOnboardingStepV2: user.isEnabledForCalendarOnboardingStepV2,
            IsEnabledForOnboardingExperimentOne: user.isEnabledForOnboardingExperimentOne,
            IsEnabledForAgentOnboarding: user.isEnabledForAgentOnboarding,
            IsAuthedForGoogle: user.isAuthedForGoogle,
            isAuthedForMicrosoftCalendar: user.isAuthedForMicrosoftCalendar,
            IsPersonal: user.isPersonal,
            IsOnLiveReverseTrial: user.isOnLiveReverseTrial,
        });
    }, [
        user.isEnabledForCalendarOnboardingStepV2,
        user.isEnabledForOnboardingExperimentOne,
        user.isAuthedForGoogle,
        user.isPersonal,
        user.isOnLiveReverseTrial,
        user.isEnabledForAgentOnboarding,
        user.isAuthedForMicrosoftCalendar,
    ]);

    if (user.isEnabledForAgentOnboarding) {
        return <AgentOnboardingFlowContainer />;
    }

    if (user.isEnabledForCalendarOnboardingStepV2) {
        return <OnboardingFlowWithCalendarV2Container />;
    }

    if (user.isEnabledForOnboardingExperimentOne) {
        return <ExperimentOneOnboardingFlowContainer />;
    }

    return <OnboardingFlowContainer />;
}
