import { ClientAiHistory, SpinachAPIPath, ViewableTranscriptLineData } from '@spinach-shared/types';

import { TranscriptData } from '../components/spinach-ai/dashboard/summaries/MediaComponent';
import { getSpinachAPI } from './getSpinachAPI';

export async function getAiHistories(): Promise<ClientAiHistory[] | undefined> {
    const response = await getSpinachAPI<{ histories: ClientAiHistory[] }>(SpinachAPIPath.AIHistory);

    return response?.histories;
}

export async function getAiTranscript({
    seriesId,
    botId,
}: {
    seriesId: string;
    botId: string;
}): Promise<TranscriptData | undefined | null> {
    const response = await getSpinachAPI<
        { results: ViewableTranscriptLineData[] | null; isTranscriptDisabled: false } | { isTranscriptDisabled: true }
    >(SpinachAPIPath.AITranscript, {
        params: {
            seriesId,
            botId,
        },
    });

    if (response?.isTranscriptDisabled) {
        return response;
    }

    if (!response?.results) {
        return null;
    }

    return { isTranscriptDisabled: response.isTranscriptDisabled, transcriptLineData: response.results };
}

export async function getAiSummaryHtml({
    seriesId,
    botId,
}: {
    seriesId: string;
    botId: string;
}): Promise<string | undefined | null> {
    const response = await getSpinachAPI<{ results: string | null }>(SpinachAPIPath.AiSummaryHtml, {
        params: {
            seriesId,
            botId,
        },
    });

    return response?.results;
}
