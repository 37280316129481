import { CircularProgress, Popover, makeStyles } from '@material-ui/core';
import { AccountCircle } from '@material-ui/icons';
import LogoutIcon from '@material-ui/icons/ExitToApp';
import React, { useState } from 'react';
import styled from 'styled-components';

import { ClientEventType } from '@spinach-shared/types';
import { getWebAppHost } from '@spinach-shared/utils';

import { postLogOut } from '../../apis';
import { GlobalModal } from '../../atoms';
import {
    useEditNameEnablement,
    useExperienceTracking,
    useGlobalAuthedUser,
    useGlobalModal,
    useGlobalRouting,
} from '../../hooks';
import { lightTheme } from '../../styles';
import { isZoomPlatform } from '../../utils';
import { Circle, Column } from './framing';

const usePopoverStyles = makeStyles(() => ({
    paper: {
        borderRadius: '10px',
        backgroundColor: lightTheme.neutrals.grayLight,
        boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.25), 5px 5px 0px 1px rgba(0, 0, 0, 0.15)',
    },
}));

const MenuButton = styled.button`
    padding: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
    width: 90%;
    border-radius: 8px;
    align-items: center;
    cursor: pointer;
    display: flex;
    border: none;
    background-color: transparent;

    &:hover {
        background-color: ${(props) => props.theme.neutrals.gray};
    }
`;

export function UserAccountButton(): JSX.Element {
    const [user] = useGlobalAuthedUser();
    const [isLoadingLogout, setIsLoadingLogout] = useState(false);
    const [_, setGlobalModal] = useGlobalModal();
    const [isUserOptionsOpen, setIsUserOptionsOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const isEditNameEnabled = useEditNameEnablement();
    const isVisible = isEditNameEnabled || !isZoomPlatform();
    const popoverStyles = usePopoverStyles();
    const { routeToAIDashboard } = useGlobalRouting();
    const onOptionsClick = async (e: React.MouseEvent | React.KeyboardEvent) => {
        e.stopPropagation();
        setIsUserOptionsOpen(!isUserOptionsOpen);
        setAnchorEl(document.getElementById(`account-settings`));
    };
    const track = useExperienceTracking();

    return isVisible ? (
        <div style={{ position: 'absolute', top: '5px', right: '5px', zIndex: 1000 }}>
            <Circle id="account-settings" onClick={onOptionsClick}>
                {user.initials ? user.initials : <AccountCircle style={{ marginTop: '4px' }} />}
            </Circle>
            {isUserOptionsOpen ? (
                <Popover
                    style={{
                        padding: '10px',
                    }}
                    classes={popoverStyles}
                    id={'popover'}
                    open={isUserOptionsOpen}
                    anchorEl={anchorEl}
                    onClose={() => {
                        setIsUserOptionsOpen(false);
                    }}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    <Column style={{ width: '150px', alignItems: 'center' }}>
                        {isEditNameEnabled && (
                            <MenuButton
                                disabled={isLoadingLogout}
                                onClick={async () => {
                                    track(ClientEventType.UserClickedNameChange);
                                    setGlobalModal(GlobalModal.UserAccount);
                                }}
                            >
                                <AccountCircle style={{ marginRight: '5px' }} />
                                <b>Edit Profile</b>
                            </MenuButton>
                        )}
                        {!isZoomPlatform() && (
                            <MenuButton
                                disabled={isLoadingLogout}
                                onClick={async () => {
                                    track(ClientEventType.UserClickedLogOut);

                                    setIsLoadingLogout(true);
                                    const response = await postLogOut();
                                    if (response.success) {
                                        window.location.href = getWebAppHost();
                                    }
                                    setIsLoadingLogout(false);
                                }}
                            >
                                <LogoutIcon style={{ marginRight: '5px' }} />
                                <b>Logout</b>
                                {isLoadingLogout ? (
                                    <CircularProgress
                                        size={'20px'}
                                        style={{
                                            marginLeft: '5px',
                                            color: lightTheme.primary.orangeDark,
                                        }}
                                    />
                                ) : null}
                            </MenuButton>
                        )}
                    </Column>
                </Popover>
            ) : null}
        </div>
    ) : (
        <></>
    );
}
