import { Box, Modal, TextField } from '@material-ui/core';
import { useState } from 'react';

import { SpinachAPIPath, SpinachIntegration } from '@spinach-shared/types';

import { postSpinachAPI } from '../../../../apis/postSpinachAPI';
import { useIntegrationDetection } from '../../../../hooks/useIntegrationDetection';
import { CloseButton, SpinachModalContent } from '../../../common';
import { PrimaryButton } from '../../../stand-up';
import { getUser } from '../../../../apis/getUser';
import { setUser } from '@sentry/react';
import { useGlobalAuthedUser } from '../../../../hooks/useGlobalUser';
import { on } from 'events';

export const MondayApiKeyModel = ({
    isOpen,
    onSuccess,
    onClose,
}: {
    isOpen: boolean;
    onSuccess: () => void;
    onClose: () => void;
}) => {
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [apiKey, setApiKey] = useState('');
    const [, setUser] = useGlobalAuthedUser();
    return (
        <Modal open={isOpen} onClose={onClose}>
            <SpinachModalContent
                onClose={onClose}
                style={{
                    minWidth: '700px',
                    maxHeight: '800px',
                    padding: '30px',
                    minHeight: '270px',
                    overflow: 'initial',
                }}
            >
                <CloseButton
                    style={{
                        left: '3px',
                        top: '9px',
                    }}
                    onClick={onClose}
                />
                <Box display="flex" flexDirection="column" alignItems="stretch">
                    <Box style={{ textAlign: 'center', fontWeight: 300, fontSize: '30px' }}>Get Started</Box>
                    <Box display="flex" flexDirection="column" alignItems="stretch" flex={1}>
                        <video
                            src={'https://media.spinach.io/monday-apikey.webm'}
                            style={{
                                width: '600px',
                                marginBottom: '10px',
                                marginTop: '20px',
                                borderRadius: '10px',
                                border: '20px solid #efefef',
                            }}
                            muted
                            controls={false}
                            autoPlay
                            loop
                        />
                        <ol
                            style={{
                                fontSize: '15px',
                                marginTop: '10px',
                                marginBottom: '20px',
                                paddingLeft: '30px',
                                lineHeight: '1.5em',
                            }}
                        >
                            <li>
                                Log into your{' '}
                                <a href="https://monday.com/" target="_blank" rel="noopener noreferrer">
                                    Monday.com
                                </a>{' '}
                                account.
                            </li>
                            <li>
                                Click on <b>your avatar</b> in the top right corner.
                            </li>
                            <li>
                                Click <b>Developers</b> in the dropdown.
                            </li>
                            <li>
                                Click <b>My access tokens</b>.
                            </li>
                            <li>
                                Click <b>Show</b>
                            </li>
                            <li>
                                <b>Copy</b> the API key and <b>paste</b> it below.
                            </li>
                        </ol>
                        <Box flex={1} flexDirection="row" display="flex">
                            <TextField
                                style={{ flex: 1 }}
                                label={isError ? 'Invalid API key' : 'Paste your API key'}
                                variant="outlined"
                                error={isError}
                                type="password"
                                disabled={isLoading}
                                value={apiKey}
                                onChange={(e) => {
                                    setApiKey(e.target.value);
                                }}
                                autoComplete="off"
                            />
                            <PrimaryButton
                                loadingText={'Verifying...'}
                                isLoading={isLoading}
                                style={{
                                    flexShrink: 1,
                                    alignSelf: 'flex-start',
                                    marginTop: '10px',
                                    marginLeft: '20px',
                                }}
                                title={`Continue`}
                                disabled={isLoading || !apiKey.trim()}
                                onClick={async () => {
                                    setIsLoading(true);
                                    try {
                                        await postSpinachAPI(
                                            SpinachAPIPath.IntegrationSetApiKey.replace(
                                                ':oauthProvider',
                                                SpinachIntegration.MondayDotCom
                                            ),
                                            {
                                                apiKey,
                                            },
                                            { throwOnError: true }
                                        );
                                        const userResponse = await getUser();
                                        if (!userResponse.user) {
                                            throw new Error('User not found');
                                        }
                                        setUser(userResponse.user);
                                        onSuccess();
                                        onClose();
                                    } catch (error) {
                                        setIsError(true);
                                        setIsLoading(false);
                                    }
                                }}
                            />
                        </Box>
                    </Box>
                </Box>
            </SpinachModalContent>
        </Modal>
    );
};
