import { ArrowBack } from '@material-ui/icons';
import { Titles } from '@spinach-clients/constants';
import lottie from 'lottie-web';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { ClientEventType, SpinachIntegration } from '@spinach-shared/types';
import { SeriesSchedule, TimeUtils } from '@spinach-shared/utils';

import { postExperienceEvent } from '../../apis';
import celebrateLottieJSON from '../../assets/celebrate.json';
import { ReactComponent as Cross } from '../../assets/cross.svg';
import { ReactComponent as GreenCheckmark } from '../../assets/green-checkmark.svg';
import { ReactComponent as SLogo } from '../../assets/s-logo.svg';
import { ReactComponent as SlackLogo } from '../../assets/slack-logo.svg';
import { ElementId } from '../../constants';
import { useGlobalAuthedUser, useGlobalStoredSeries, usePrevious } from '../../hooks';
import { useGlobalMeetingSettings } from '../../hooks/useGlobalMeetingSettings';
import { useGlobalSlack } from '../../hooks/useSlack';
import { BodyRegular, HeaderThree, lightTheme } from '../../styles';
import { PrimaryButton } from '../stand-up';
import { Column, Row, Spacing } from './framing';

const ConfettiContainer = styled.div`
    position: relative;
    height: 0px;
`;

export function ScheduleConfirmationContent({
    onSubmit,
    dateTimes,
    isLoading,
    onBackClick,
}: {
    onSubmit: (dateTimes: SeriesSchedule, shouldSendSlackMessage: boolean) => Promise<void>;
    onBackClick: () => void;
    dateTimes?: SeriesSchedule;
    isLoading: boolean;
}) {
    const [user] = useGlobalAuthedUser();
    const { openIntegrationsSettings } = useGlobalMeetingSettings();
    const { setShouldSendSummaryOnChannelSelect } = useGlobalSlack();
    const [series] = useGlobalStoredSeries();
    const [arrowBackgroundColor, setArrowBackgroundColor] = useState<string | null>(null);
    const [isReloading] = useState(false);
    const [shouldDisplaySlackInstalled, setShouldDisplaySlackInstalled] = useState(false);

    const slackIntegration = series.getIntegrationSettings(SpinachIntegration.Slack);

    const slackChannel = series.slackChannel;

    const primaryButtonText = slackChannel ? Titles.Done : Titles.ConnectToSlack;

    const primaryButtonId = slackChannel
        ? `schedule-setup-modal-is-installing-slack`
        : `schedule-setup-modal-slack-the-team`;

    const prevSlackIntegration = usePrevious(slackIntegration);
    useEffect(() => {
        if (prevSlackIntegration === undefined && !!slackIntegration) {
            setShouldDisplaySlackInstalled(true);
            const container = document.querySelector('#lets-go-header');

            if (container) {
                const animation = lottie.loadAnimation({
                    container,
                    autoplay: false,
                    loop: false,
                    animationData: celebrateLottieJSON,
                    rendererSettings: {
                        className: 'celebrate-first-series-animation',
                    },
                });
                animation.setSpeed(0.33);
                animation.play();
            }
        }
    }, [slackIntegration]);

    const filteredDateTimes = dateTimes?.days;

    return filteredDateTimes ? (
        <>
            <ConfettiContainer id="lets-go-header"></ConfettiContainer>
            <Row style={{ alignItems: 'center' }}>
                <ArrowBack
                    style={{
                        position: 'absolute',
                        cursor: 'pointer',
                        border: 'none',
                        left: '8px',
                        top: '20px',
                        fontSize: '17px',
                        backgroundColor: arrowBackgroundColor ?? 'unset',
                        marginRight: '5px',
                    }}
                    onMouseEnter={() => setArrowBackgroundColor(lightTheme.neutrals.grayLight)}
                    onMouseLeave={() => setArrowBackgroundColor(null)}
                    onClick={onBackClick}
                />
            </Row>
            <Column>
                <HeaderThree>
                    {shouldDisplaySlackInstalled ? '✅ Slack is now setup!' : 'Your schedule is set!'}
                </HeaderThree>
                <BodyRegular>Here's a summary of your weekly schedule:</BodyRegular>
                <Spacing factor={1 / 2} />
                <Row
                    style={{
                        backgroundColor: '#FDDEB030',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '80%',
                    }}
                >
                    <Column>
                        <BodyRegular style={{ fontWeight: 'bold' }}>Day</BodyRegular>
                        {filteredDateTimes.map((dateTime) => (
                            <BodyRegular style={{ paddingBottom: '5px' }} key={dateTime.day}>
                                {dateTime.day}
                            </BodyRegular>
                        ))}
                    </Column>
                    <Column>
                        <BodyRegular style={{ fontWeight: 'bold' }}>Format</BodyRegular>
                        {filteredDateTimes.map(
                            (dateTime) =>
                                dateTime.meetingFormat && (
                                    <BodyRegular
                                        style={{ paddingBottom: '5px' }}
                                        key={`${dateTime.day}-${dateTime.meetingFormat}`}
                                    >
                                        {dateTime.meetingFormat.charAt(0).toUpperCase() +
                                            dateTime.meetingFormat.slice(1)}
                                    </BodyRegular>
                                )
                        )}
                    </Column>
                    <Column>
                        <BodyRegular style={{ fontWeight: 'bold' }}>Time</BodyRegular>
                        {filteredDateTimes.map((dateTime) => {
                            const time = TimeUtils.getDateFromHHMM(dateTime.startTime);
                            const fullTimeText = time
                                .toLocaleTimeString(undefined, { timeZoneName: 'short' })
                                .split(' ')[0];
                            const amPmText = time
                                .toLocaleTimeString(undefined, { timeZoneName: 'short' })
                                .split(' ')[1]
                                .toLowerCase();
                            const timeZoneValueText = time
                                ?.toLocaleTimeString(undefined, { timeZoneName: 'short' })
                                .split(' ')[2];
                            const timeValueText = fullTimeText?.split(':').slice(0, -1).join(':');

                            return (
                                <BodyRegular
                                    style={{ paddingBottom: '5px' }}
                                    key={`${timeValueText}-${amPmText}-${timeZoneValueText}`}
                                >
                                    {timeValueText}
                                    {amPmText} {timeZoneValueText}
                                </BodyRegular>
                            );
                        })}
                    </Column>
                </Row>
                <Spacing factor={1 / 2} />
                <Column style={{ alignItems: 'start', width: '80%' }}>
                    <Row>
                        <Column style={{ width: 'unset' }}>
                            {slackIntegration ? (
                                <>
                                    <BodyRegular>
                                        We’ll send out a schedule summary now and a reminder 15 minutes ahead of each
                                        meeting.
                                    </BodyRegular>
                                    <Spacing factor={1 / 3} />
                                    <Row
                                        style={{
                                            alignSelf: 'center',
                                            width: '80%',
                                            justifyContent: 'space-evenly',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <SLogo style={{ width: '50px', height: '47px' }} />
                                        <GreenCheckmark style={{ height: '24px', width: '24px' }} />
                                        <SlackLogo style={{ width: '50px', height: '39px' }} />
                                    </Row>
                                </>
                            ) : (
                                <>
                                    <Spacing factor={1 / 3} />
                                    <Row
                                        style={{
                                            alignSelf: 'center',
                                            width: '80%',
                                            justifyContent: 'space-evenly',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <SLogo style={{ width: '50px', height: '47px' }} />
                                        <Cross style={{ height: '12px', width: '12px' }} />
                                        <SlackLogo style={{ width: '50px', height: '39px' }} />
                                    </Row>
                                    <Spacing factor={1 / 3} />
                                    <BodyRegular>
                                        In order to remind the team and see summaries, you need to enable Slack. It will
                                        only take a second.
                                    </BodyRegular>
                                </>
                            )}
                        </Column>
                    </Row>
                </Column>
            </Column>
            <Spacing />
            <div style={{ flexGrow: 1 }} />
            <PrimaryButton
                id={primaryButtonId}
                title={primaryButtonText}
                onClick={async () => {
                    if (!slackChannel) {
                        await onSubmit(dateTimes, false);
                        setShouldSendSummaryOnChannelSelect(true);
                        openIntegrationsSettings({ autoClickId: ElementId.SlackConnectButton });
                        postExperienceEvent({
                            eventType: ClientEventType.UserAuthorizeSlackClick,
                            payload: {
                                ...user.toUserIdentityPayload(),
                                SeriesId: series?.id,
                                timezone: TimeUtils.getTimezoneRegion(),
                            },
                        });
                    } else {
                        onSubmit(dateTimes, true);
                    }
                }}
                isLoading={isLoading || isReloading}
            />
        </>
    ) : (
        <></>
    );
}
