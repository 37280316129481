import { groupBy } from 'lodash';

import { ViewableTranscriptLineData } from '@spinach-shared/types';

import {
    GlobalSearchDataItem,
    GlobalSearchDetailedMatch,
    GlobalSearchMatchType,
    SummaryWrapper,
} from './globalSearchTypes';

interface CharData {
    char: string;
    speaker: string;
    timestamp: number;
    index: number;
}

export function findTermInTranscripts(
    transcripts: ViewableTranscriptLineData[],
    searchTerm: string,
    contextSize = 120,
    maxGroups = 25 // truncate to 25 groups, the ui is struggling with more elements
): ViewableTranscriptLineData[][] {
    const chars: CharData[] = [];

    // Step 1: Convert the transcript data into a list of characters with metadata
    transcripts.forEach(({ speaker, statement, timestamp }) => {
        Array.from(statement).forEach((char, index) => {
            chars.push({ char, speaker, timestamp, index });
        });
    });

    const relevantIndices = new Set<number>();

    // Step 2: Search for the term and mark relevant characters and their context
    chars.forEach((_, index) => {
        if (
            chars
                .slice(index, index + searchTerm.length)
                .map((c) => c.char)
                .join('')
                .toLowerCase() === searchTerm.toLowerCase()
        ) {
            for (
                let i = Math.max(0, index - contextSize);
                i <= Math.min(chars.length - 1, index + searchTerm.length + contextSize - 1);
                i++
            ) {
                relevantIndices.add(i);
            }
        }
    });

    // Step 3: Reconstruct the transcript data from the marked characters
    const result: ViewableTranscriptLineData[][] = [];
    let currentGroup: ViewableTranscriptLineData[] = [];
    let currentStatement: string[] = [];
    let currentSpeaker = '';
    let currentTimestamp = 0;

    chars.forEach((charData, index) => {
        if (relevantIndices.has(index)) {
            if (currentSpeaker && (currentSpeaker !== charData.speaker || currentTimestamp !== charData.timestamp)) {
                currentGroup.push({
                    speaker: currentSpeaker,
                    statement: currentStatement.join(''),
                    timestamp: currentTimestamp,
                });
                currentStatement = [];
            }
            currentSpeaker = charData.speaker;
            currentTimestamp = charData.timestamp;
            currentStatement.push(charData.char);
        } else if (currentStatement.length > 0) {
            currentGroup.push({
                speaker: currentSpeaker,
                statement: currentStatement.join(''),
                timestamp: currentTimestamp,
            });
            currentStatement = [];
            result.push(currentGroup);
            currentGroup = [];
        }
    });

    if (currentStatement.length > 0) {
        currentGroup.push({
            speaker: currentSpeaker,
            statement: currentStatement.join(''),
            timestamp: currentTimestamp,
        });
    }

    if (currentGroup.length > 0) {
        result.push(currentGroup);
    }

    result.map((group) => {
        group.forEach((line, index) => {
            if (index === 0) {
                line.statement = `...${line.statement}`;
            } else if (index === group.length - 1) {
                line.statement = `${line.statement}...`;
            }
        });
    });

    return result.slice(0, maxGroups);
}

export function getMatchCount<T>(
    list: T[] | undefined,
    mapper: (o: T) => (string | undefined)[],
    term: string
): number {
    return list
        ? list.map(mapper).filter((strs) => strs.find((str) => str?.toLowerCase().includes(term.toLowerCase()))).length
        : 0;
}

export const getMatchesForMeeting = ({
    transcript,
    summary,
    term,
}: {
    transcript: ViewableTranscriptLineData[];
    summary: SummaryWrapper;
    term: string;
}) => {
    if (!transcript) {
        return 0;
    }

    const transcriptMatches = getMatchCount(transcript, (line) => [line.statement], term);
    const blockersMatches = getMatchCount(
        summary?.summaryJson?.blockers || [],
        (blocker) => [blocker?.context, blocker?.content],
        term
    );
    const actionItemsMatches = getMatchCount(
        summary?.summaryJson?.actionItems || [],
        (actionItem) => [actionItem?.context, actionItem?.title],
        term
    );
    const keyDecisionsMatches = getMatchCount(
        summary?.summaryJson?.keyDecisions || [],
        (keyDecision) => [keyDecision?.context, keyDecision?.content],
        term
    );
    return transcriptMatches + blockersMatches + actionItemsMatches + keyDecisionsMatches;
};

export const getDetailedMatchesForMeeting = (
    selectedData: GlobalSearchDataItem,
    searchTerm: string
): GlobalSearchDetailedMatch[] => {
    const lowercaseSearchTerm = searchTerm.toLowerCase();
    if (selectedData.transcript) {
        const transcriptMatches: { type: GlobalSearchMatchType.Transcript; group: ViewableTranscriptLineData[] }[] =
            findTermInTranscripts(selectedData.transcript, lowercaseSearchTerm).map((x) => ({
                type: GlobalSearchMatchType.Transcript as const,
                group: x,
            }));

        const blockerMatches =
            selectedData.summary?.summaryJson.blockers
                ?.filter(
                    (blocker) =>
                        blocker.context?.toLowerCase().includes(lowercaseSearchTerm) ||
                        blocker.content?.toLowerCase().includes(lowercaseSearchTerm)
                )
                .map((blocker) => ({
                    type: GlobalSearchMatchType.Blocker as const,
                    content: blocker.content,
                    context: blocker.context,
                })) || [];

        const actionItemMatches =
            selectedData.summary?.summaryJson.actionItems
                ?.filter(
                    (actionItem) =>
                        actionItem.context?.toLowerCase().includes(lowercaseSearchTerm) ||
                        actionItem.title?.toLowerCase().includes(lowercaseSearchTerm)
                )
                .map((actionItem) => ({
                    type: GlobalSearchMatchType.ActionItem as const,
                    title: actionItem.title,
                    context: actionItem.context,
                })) || [];

        const keyDecisionsMatches =
            selectedData.summary?.summaryJson.keyDecisions
                ?.filter(
                    (keyDecision) =>
                        keyDecision.context?.toLowerCase().includes(lowercaseSearchTerm) ||
                        keyDecision.content?.toLowerCase().includes(lowercaseSearchTerm)
                )
                .map((keyDecision) => ({
                    type: GlobalSearchMatchType.KeyDecision as const,
                    content: keyDecision.content,
                    context: keyDecision.context,
                })) || [];

        const detailedMatches = [...transcriptMatches, ...blockerMatches, ...actionItemMatches, ...keyDecisionsMatches];

        const groupedMatches = groupBy(detailedMatches, 'type');

        return [
            ...(groupedMatches.actionItem || []),
            ...(groupedMatches.blocker || []),
            ...(groupedMatches.keyDecisions || []),
            ...(groupedMatches.transcript || []),
        ];
    } else {
        return [];
    }
};

export const consolidateTranscript = (
    transcript: ViewableTranscriptLineData[] | null | undefined
): ViewableTranscriptLineData[] => {
    return Array.isArray(transcript)
        ? transcript.reduce((consolidated, current) => {
              if (consolidated.length === 0) {
                  return [current];
              }
              const lastStatement = consolidated[consolidated.length - 1];
              if (lastStatement.speaker === current.speaker) {
                  lastStatement.statement += ` ${current.statement}`;
              } else {
                  consolidated.push(current);
              }
              return consolidated;
          }, [] as ViewableTranscriptLineData[])
        : [];
};
