import { MutationOptions, useMutation, useQuery, useQueryClient } from 'react-query';

import { CalendarEvent, CalendarEventListOpts } from '@spinach-shared/types';
import { getMasterEventId, getUniques } from '@spinach-shared/utils';

import { getScribeAttendeesFromEvent } from '../components/spinach-ai/ScribeCalendarPage';
import { SetValue } from '../types';
import { ClientLogger } from '../utils';
import {
    MeetingsAndSuggestions,
    fetchCalendarEventSuggestions,
    fetchCalendarEvents,
} from '../utils/fetchCalendarEvents';
import { updateScribeOnCalendarEvent } from '../utils/updateScribeOnCalendarEvent';
import { useGlobalUser } from './useGlobalUser';
import { getAllScribeEmails, useScribeEmail } from './useScribe';

async function fetchCalendarEventsWithStartEndTime(
    timeMinISOString: string,
    timeMaxISOString: string,
    options: CalendarEventListOpts = {}
) {
    const fetchedEvents = await fetchCalendarEvents({
        timeMin: timeMinISOString,
        timeMax: timeMaxISOString,
        ...options,
    });

    return fetchedEvents;
}

async function fetchCalendarEventSuggestionsWithStartEndTime(
    timeMinISOString: string,
    timeMaxISOString: string
): Promise<MeetingsAndSuggestions> {
    const results = await fetchCalendarEventSuggestions({
        timeMin: timeMinISOString,
        timeMax: timeMaxISOString,
    });

    return results;
}

export async function updateScribeOnEvent(iCalUid: string, addToEvent: boolean): Promise<CalendarEvent> {
    const updated = await updateScribeOnCalendarEvent(iCalUid, addToEvent);
    if (!updated) {
        throw new Error('Failed to update scribe on event');
    }
    return updated;
}

export const useCalendarEvents = (
    options: Required<Pick<CalendarEventListOpts, 'timeMax' | 'timeMin'>> & Partial<CalendarEventListOpts>
) => {
    const [user] = useGlobalUser();
    const queryKey = `calendar-events-${options.timeMin}-${options.timeMax}`;
    const query = useQuery({
        queryKey,
        queryFn: () => fetchCalendarEventsWithStartEndTime(options.timeMin, options.timeMax, options),
        enabled: user?.isAuthedForAnyCalendar,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        refetchOnMount: false,
        retry: 2,
    });
    return { ...query, queryKey };
};

export const useCalendarEventSuggestions = (
    dateRange: { startISOString: string; endISOString: string },
    shouldFetch: boolean
) => {
    const [user] = useGlobalUser();
    const queryKey = `calendar-event-suggestions-${dateRange.startISOString}-${dateRange.endISOString}`;
    const query = useQuery({
        queryKey,
        queryFn: () => fetchCalendarEventSuggestionsWithStartEndTime(dateRange.startISOString, dateRange.endISOString),
        enabled: user?.isAuthedForAnyCalendar && shouldFetch,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        refetchOnMount: false,
        retry: 2,
    });
    return { ...query, queryKey };
};

export function useBulkUpdateScribeOnEvents(queryKey: string) {
    const queryClient = useQueryClient();
    const currentScribeEmail = useScribeEmail();
    const mutation = useMutation({
        mutationFn: async (events: { iCalUid: string; addToEvent: boolean }[]) => {
            const savedEvents = await Promise.all(
                events.map((event) => {
                    return updateScribeOnEvent(event.iCalUid, event.addToEvent);
                })
            );

            queryClient.setQueryData(queryKey, (oldData: MeetingsAndSuggestions | undefined) => {
                // set the new data in the cache
                const optimisticEvents =
                    oldData?.events.map((event) => {
                        if (!!events.find((e) => e.iCalUid === event.iCalUID)) {
                            // add or remove the scribe from the event that was clicked on
                            const newAttendees = [...(event.attendees || []), { email: currentScribeEmail }];

                            return { ...event, isScribeOnEvent: true, attendees: newAttendees };
                        }
                        return event;
                    }) || [];

                return new MeetingsAndSuggestions({
                    events: optimisticEvents,
                    suggestions: oldData?.toJSON().suggestions ?? {
                        oneOffMeetingSuggestions: [],
                        recurringEventSuggestions: [],
                    },
                });
            });

            return savedEvents;
        },

        onMutate: async () => {
            //called before mutationFn, used for optimistic updates
            await queryClient.cancelQueries(queryKey); //cancel any outgoing queries with the same queryKey to avoid them overwriting our optimistic update
            const previousEvents = queryClient.getQueryData(queryKey); //get the previous events from the cache

            return { previousEvents };
        },
        onError: (err, _data, context) => {
            ClientLogger.error('Failed to bulk add bot to events', err);
            // revert to the old data in case the mutation fails
            queryClient.setQueryData(queryKey, context?.previousEvents); //rollback to the previous events if the mutation fails
        },
        onSuccess: () => {
            queryClient.invalidateQueries(queryKey); //invalidate the query to refetch the data
        },
    });
    return mutation;
}

export function useUpdateScribeOnEvent(
    queryKey: string,
    setUpdatingEvents: SetValue<string[]>,
    setFailedEvents?: SetValue<string[]>
) {
    const queryClient = useQueryClient();
    const currentScribeEmail = useScribeEmail();
    const mutation = useMutation({
        mutationFn: ({
            addToEvent,
            event,
        }: {
            addToEvent: boolean;
            event: Omit<CalendarEvent, 'iCalUID'> & { iCalUID: string };
        }) => updateScribeOnEvent(event.iCalUID, addToEvent),

        onMutate: async ({ event, addToEvent }) => {
            //called before mutationFn, used for optimistic updates
            await queryClient.cancelQueries(queryKey); //cancel any outgoing queries with the same queryKey to avoid them overwriting our optimistic update
            const previousEvents = queryClient.getQueryData(queryKey); //get the previous events from the cache
            const scribeEmails = getAllScribeEmails();
            queryClient.setQueryData(queryKey, (oldData: CalendarEvent[] | undefined) => {
                // set the new data in the cache
                const eventId = getMasterEventId(event);
                return (
                    oldData?.map((oldEvent) => {
                        const oldMasterId = getMasterEventId(oldEvent);
                        if (oldMasterId === eventId) {
                            // add or remove the scribe from the event that was clicked on
                            const newIsScribeOnEvent = addToEvent ? true : false;
                            const newAttendees = addToEvent
                                ? [
                                      ...(oldEvent.attendees || []),
                                      /**
                                       * @NOTE If scribe emails  already exist on the event we accept the meeting for those
                                       * instead of adding a new email to the event
                                       * */
                                      ...(getScribeAttendeesFromEvent(oldEvent)?.length
                                          ? getScribeAttendeesFromEvent(oldEvent)!.map((attendee) => ({
                                                ...attendee,
                                                responseStatus: 'accepted',
                                            }))
                                          : [{ email: currentScribeEmail }]),
                                  ]
                                : oldEvent.attendees?.map((attendee) =>
                                      scribeEmails.includes(attendee.email ?? '')
                                          ? { ...attendee, responseStatus: 'declined' }
                                          : attendee
                                  );
                            return { ...oldEvent, isScribeOnEvent: newIsScribeOnEvent, attendees: newAttendees };
                        }
                        return oldEvent;
                    }) || []
                );
            });

            return { previousEvents };
        },

        onSettled: (settledEvent: CalendarEvent | undefined, _, { event }) => {
            if (!settledEvent) {
                setFailedEvents?.((failedEvents) => getUniques([...failedEvents, getMasterEventId(event)]));
                return;
            }

            setUpdatingEvents((updatingEvents) =>
                updatingEvents.filter((id) => {
                    if (id === getMasterEventId(settledEvent)) {
                        return false;
                    }
                    return true;
                })
            );
        },

        onError: (err, _data, context) => {
            // revert to the old data in case the mutation fails
            setFailedEvents?.((failedEvents) => getUniques([...failedEvents, getMasterEventId(_data.event)]));
            queryClient.setQueryData(queryKey, context?.previousEvents); //rollback to the previous events if the mutation fails
        },
    });
    return mutation;
}

export function useUpdateScribeOnEventWithOverrides(
    queryKey: string,
    mutationOptions?: Pick<
        MutationOptions<
            CalendarEvent,
            unknown,
            {
                addToEvent: boolean;
                event: Omit<CalendarEvent, 'iCalUID'> & { iCalUID: string };
            }
        >,
        'onSettled' | 'onSuccess' | 'onError'
    >
) {
    const queryClient = useQueryClient();
    const currentScribeEmail = useScribeEmail();
    const mutation = useMutation({
        mutationFn: ({
            addToEvent,
            event,
        }: {
            addToEvent: boolean;
            event: Omit<CalendarEvent, 'iCalUID'> & { iCalUID: string };
        }) => updateScribeOnEvent(event.iCalUID, addToEvent),

        onMutate: async ({ event, addToEvent }) => {
            //called before mutationFn, used for optimistic updates
            await queryClient.cancelQueries(queryKey); //cancel any outgoing queries with the same queryKey to avoid them overwriting our optimistic update
            const previousEvents = queryClient.getQueryData(queryKey); //get the previous events from the cache
            const scribeEmails = getAllScribeEmails();
            queryClient.setQueryData(queryKey, (oldData: CalendarEvent[] | undefined) => {
                // set the new data in the cache
                const eventId = getMasterEventId(event);
                return (
                    oldData?.map((oldEvent) => {
                        const oldMasterId = getMasterEventId(oldEvent);
                        if (oldMasterId === eventId) {
                            // add or remove the scribe from the event that was clicked on
                            const newIsScribeOnEvent = addToEvent ? true : false;
                            const newAttendees = addToEvent
                                ? [
                                      ...(oldEvent.attendees || []),
                                      /**
                                       * @NOTE If scribe emails  already exist on the event we accept the meeting for those
                                       * instead of adding a new email to the event
                                       * */
                                      ...(getScribeAttendeesFromEvent(oldEvent)?.length
                                          ? getScribeAttendeesFromEvent(oldEvent)!.map((attendee) => ({
                                                ...attendee,
                                                responseStatus: 'accepted',
                                            }))
                                          : [{ email: currentScribeEmail }]),
                                  ]
                                : oldEvent.attendees?.map((attendee) =>
                                      scribeEmails.includes(attendee.email ?? '')
                                          ? { ...attendee, responseStatus: 'declined' }
                                          : attendee
                                  );
                            return { ...oldEvent, isScribeOnEvent: newIsScribeOnEvent, attendees: newAttendees };
                        }
                        return oldEvent;
                    }) || []
                );
            });

            return { previousEvents };
        },
        onError: (err, _data, context) => {
            // revert to the old data in case the mutation fails
            queryClient.setQueryData(queryKey, context?.previousEvents); //rollback to the previous events if the mutation fails
        },
        ...mutationOptions,
    });
    return mutation;
}
