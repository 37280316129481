import moment from 'moment';
import { useEffect, useState } from 'react';

import { ENGLISH_LANGUAGE } from '@spinach-shared/constants';
import { CalendarEvent, ClientEventType, FeatureToggle, UserCreationSource } from '@spinach-shared/types';
import { TimeUtils } from '@spinach-shared/utils';

import { Direction, patchUser, useExperienceTracking, useGlobalAuthedUser, useGlobalRouting } from '../../../../..';
import { useBulkUpdateScribeOnEvents, useCalendarEventSuggestions } from '../../../../hooks/useCalendarEvents';
import { useGlobalMeetingSettings } from '../../../../hooks/useGlobalMeetingSettings';
import { useScribeEmail } from '../../../../hooks/useScribe';
import { TagManager } from '../../../../utils/TagManager';
import { ViewContainer } from '../../../common';
import { isScribeOnEvent } from '../../ScribeCalendarPage';
import { PrepHostForFirstMeeting } from '../../dashboard/meetings/PrepHostForFirstMeeting';
import { ExperimentOneOnboardingStep } from '../common';
import { ExperimentOneAboutFinishStep } from './ExperimentOneAboutFinishStep';
import { ExperimentOneAddToMeetingsStep } from './ExperimentOneAddToMeetingsStep';

export function ExperimentOneOnboardingFlowContainer(): JSX.Element {
    return (
        <ViewContainer>
            <ExperimentOneOnboardingFlow />
        </ViewContainer>
    );
}

function ExperimentOneOnboardingFlow(): JSX.Element {
    const { routeToAIDashboard } = useGlobalRouting();
    const [user, setUser] = useGlobalAuthedUser();

    const [onboardingStep, setOnboardingStep] = useState<ExperimentOneOnboardingStep>(
        ExperimentOneOnboardingStep.AddSpinachToMeetings
    );
    const [direction, setSlidingDirection] = useState<Direction>(Direction.Forward);
    const [loadingMessage, setLoadingMessage] = useState('');
    const { setSubview, closeSettingsModal } = useGlobalMeetingSettings();
    const track = useExperienceTracking();
    const [previousSteps, setPreviousSteps] = useState<ExperimentOneOnboardingStep[]>([]);

    const [firstName, setFirstName] = useState(user.metadata.firstName ?? '');
    const [lastName, setLastName] = useState(user.metadata.lastName ?? '');
    const [howDidYouHear, setHowDidYouHear] = useState('');
    const [howDidYouHearOther, setHowDidYouHearOther] = useState('');
    const [defaultMeetingLanguage, setDefaultMeetingLanguage] = useState(ENGLISH_LANGUAGE);

    const [onboardingEventsToAddScribeTo, setOnboardingEventsToAddScribeTo] = useState<CalendarEvent[]>([]);
    const [hasFetched, setHasFetched] = useState(false);
    const { data, error, queryKey, isFetching, refetch } = useCalendarEventSuggestions(
        {
            startISOString: moment.tz(TimeUtils.getTimezoneRegion()).startOf('day').toISOString(),
            endISOString: moment.tz(TimeUtils.getTimezoneRegion()).add(28, 'days').endOf('day').toISOString(),
        },
        !hasFetched
    );
    const scribeEmail = useScribeEmail();
    useEffect(() => {
        if (data && !hasFetched && user.isAuthedForAnyCalendar) {
            setHasFetched(true);
            const meta = {
                TimedOut: data.timedOut,
                SuggestionCount: data.suggestionIds.length,
                EventCount: data.events.length,
            };
            track(ClientEventType.OnboardingActivity, {
                Activity: 'Meeting Suggestions Received',
                ...meta,
            });
            track(ClientEventType.MeetingSuggestionsReceived, meta);

            if (!data.events.length) {
                setOnboardingStep(ExperimentOneOnboardingStep.AboutAndFinish);
            }
        }
    }, [data, hasFetched, setHasFetched, user.isAuthedForAnyCalendar, setOnboardingStep, track]);

    const { mutateAsync: bulkUpdateAddScribeToEvents, error: bulkError } = useBulkUpdateScribeOnEvents(queryKey);

    // if an error occurs from retrieving or updating events, kill the loading message and move to next step
    useEffect(() => {
        const someError = !!error || !!bulkError;
        if (someError) {
            setLoadingMessage('');
        }

        if (someError && onboardingStep === ExperimentOneOnboardingStep.AddSpinachToMeetings) {
            setOnboardingStep(ExperimentOneOnboardingStep.AboutAndFinish);
        }
    }, [error, bulkError, onboardingStep]);

    useEffect(() => {
        TagManager.trackStartOnboarding(user);
    }, []);

    function progressTo(step: ExperimentOneOnboardingStep, preventGoingBackTo = false) {
        setSlidingDirection(Direction.Forward);

        if (!preventGoingBackTo) {
            setPreviousSteps([onboardingStep, ...previousSteps]);
        }

        setOnboardingStep(step);
    }

    let activeStep: JSX.Element | undefined;

    switch (onboardingStep) {
        case ExperimentOneOnboardingStep.AddSpinachToMeetings:
            activeStep = (
                <ExperimentOneAddToMeetingsStep
                    data={data}
                    // note - this isn't needed since its not click-to-load
                    updatingEvents={[]}
                    refetch={refetch as unknown as () => Promise<void>}
                    isFetchingEvents={isFetching}
                    onboardingEventsToAddScribeTo={onboardingEventsToAddScribeTo}
                    onEventClick={(event: CalendarEvent) => {
                        const isOrganizer = user.isUserTheOrganizer(event);
                        const isScribeOn = isScribeOnEvent(event);
                        if (isScribeOn) {
                            return;
                        }
                        const meta = {
                            ICalUid: event.iCalUID,
                            Action: !!onboardingEventsToAddScribeTo.find((e) => e.iCalUID === event.iCalUID)
                                ? 'remove'
                                : 'add',
                            MeetingTitle: event.summary,
                            IsCurrentUserTheOrganizer: isOrganizer,
                            IsOnboardingFlow: true,
                            AttendeeCount: event.attendees?.length,
                            IsSuggestion: event.iCalUID && data ? data.isICalUidSuggested(event.iCalUID) : false,
                            ChosenScribeEmail: scribeEmail,
                            CalendarProvider: user.calendarProvider,
                        };
                        track(ClientEventType.OnboardingClick, {
                            ClickedOn: 'Select Meeting for Spinach',
                            ...meta,
                        });
                        track(ClientEventType.CalendarMeetingItemClick, meta);
                        if (!event.iCalUID) {
                            return;
                        }
                        if (onboardingEventsToAddScribeTo.includes(event)) {
                            setOnboardingEventsToAddScribeTo(
                                onboardingEventsToAddScribeTo.filter(
                                    (eventToAdd) => eventToAdd.iCalUID !== event.iCalUID
                                )
                            );
                        } else {
                            setOnboardingEventsToAddScribeTo([...onboardingEventsToAddScribeTo, event]);
                        }
                    }}
                    hasError={!!error || !!bulkError}
                    direction={direction}
                    onSubmit={async () => {
                        const meta = {
                            NumberOfEventsScribeWasAddedTo: onboardingEventsToAddScribeTo.length,
                            TotalEventsAvailable: data?.events.length,
                            DidSuggestionsTimeOut: data?.timedOut,
                            TotalSuggestedMeetingsAdded: onboardingEventsToAddScribeTo.filter(
                                (event) => !!event.iCalUID && data?.suggestionIds.includes(event.iCalUID)
                            ).length,
                            ...(data?.getAcceptedSuggestionsByMeetingType(onboardingEventsToAddScribeTo) ?? {}),
                            ChosenScribeEmail: scribeEmail,
                        };
                        track(ClientEventType.OnboardingClick, {
                            ClickedOn: 'Submit Step: Add Spinach to Meetings',
                            ...meta,
                        });
                        track(ClientEventType.UserSubmittedAddSpinachToMeetingStep, meta);

                        setLoadingMessage('Saving...');
                        await bulkUpdateAddScribeToEvents(
                            onboardingEventsToAddScribeTo.map((event) => ({
                                iCalUid: event.iCalUID!,
                                addToEvent: true,
                            }))
                        );

                        if (!!onboardingEventsToAddScribeTo.length) {
                            if (user.metadata.usedGoogleCodeFlow) {
                                TagManager.trackGoogleCodeScribeUserAddedSpinach(user);
                            } else if (
                                user.metadata.creationSource === UserCreationSource.MicrosoftSignInFromCompanyWebsite
                            ) {
                                TagManager.trackMicrosoftCompanyWebsiteScribeUserAddedSpinach(user);
                            }
                            const onboardingVersion = user.featureToggles[FeatureToggle.OnboardingExperiment] as string;
                            TagManager.trackTypeOfMeetingAdded(user, onboardingEventsToAddScribeTo, onboardingVersion);
                        }

                        setLoadingMessage('');

                        progressTo(ExperimentOneOnboardingStep.AboutAndFinish);
                    }}
                    loadingMessage={loadingMessage}
                />
            );
            break;
        case ExperimentOneOnboardingStep.AboutAndFinish:
            activeStep = (
                <ExperimentOneAboutFinishStep
                    firstName={firstName}
                    setFirstName={setFirstName}
                    lastName={lastName}
                    setLastName={setLastName}
                    howDidYouHear={howDidYouHear}
                    setHowDidYouHear={setHowDidYouHear}
                    direction={direction}
                    howDidYouHearOther={howDidYouHearOther}
                    setHowDidYouHearOther={setHowDidYouHearOther}
                    defaultMeetingLanguage={defaultMeetingLanguage}
                    setDefaultMeetingLanguage={setDefaultMeetingLanguage}
                    onSubmit={async () => {
                        setLoadingMessage("Let's do this...");

                        const meta = {
                            HowDidYouHear: howDidYouHear,
                            HowDidYouHearOther: howDidYouHearOther,
                            DefaultMeetingLaugage: defaultMeetingLanguage,
                            IsEnabledForCalendarOnboardingStepV2: user.isEnabledForCalendarOnboardingStepV2,
                        };
                        track(ClientEventType.OnboardingClick, {
                            ClickedOn: 'Submit Step: Personal and Finish',
                            ...meta,
                        });
                        track(ClientEventType.UserSubmittedAboutStep, meta);
                        track(ClientEventType.UserClickedGoToDashboardFromFinished, meta);

                        const updatedUser = await patchUser({
                            metadata: {
                                howDidYouHear,
                                howDidYouHearOther,
                                firstName,
                                lastName,
                                preferredName: `${firstName} ${lastName}`,
                                defaultMeetingLanguage,
                                isOnboarded: true,
                            },
                        });

                        if (updatedUser.user) {
                            setUser(updatedUser.user);
                        }

                        setLoadingMessage('');

                        // clears any subviews / settings modals that may have been popped by integration rows
                        setSubview(null);
                        closeSettingsModal();

                        routeToAIDashboard({ replace: true });
                    }}
                    loadingMessage={loadingMessage}
                    onboardingEventsToAddScribeTo={onboardingEventsToAddScribeTo}
                />
            );
            break;
    }

    const anEventWeWillJoin = data?.events.find((event) => event.isScribeOnEvent);
    return (
        <>
            {anEventWeWillJoin ? <PrepHostForFirstMeeting onClose={() => {}} event={anEventWeWillJoin} /> : null}
            {activeStep}
        </>
    );
}
