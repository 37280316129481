import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { FeatureToggle, SpinachAPIPath } from '@spinach-shared/types';

import { getUser } from '../../../apis/getUser';
import { postAnonymousFeatureFlag } from '../../../apis/postAnonymousFeatureFlag';
import WhiteIconSrc from '../../../assets/google-white-letter.png';
import { getAnonymousUserId } from '../../../hooks/useAnonymousUserId';
import { LoginButton } from '../LoginButton';
import Icon from './icon';
import useGoogleLogin from './useGoogleLogin';

export const GoogleLogin = (props: any) => {
    const {
        onSuccess,
        onSuccessNew,
        onAutoLoadFinished,
        onRequest,
        onFailure,
        onScriptLoadFailure,
        render,
        disabled: disabledProp,
        clientId,
        cookiePolicy,
        loginHint,
        hostedDomain,
        autoLoad,
        isSignedIn,
        fetchBasicProfile,
        redirectUri,
        discoveryDocs,
        uxMode,
        scope,
        accessType,
        responseType,
        jsSrc,
        prompt,
        label,
        variant,
    } = props;

    const { signIn, loaded } = useGoogleLogin({
        onSuccess,
        onAutoLoadFinished,
        onRequest,
        onFailure,
        onScriptLoadFailure,
        clientId,
        cookiePolicy,
        loginHint,
        hostedDomain,
        autoLoad,
        isSignedIn,
        fetchBasicProfile,
        redirectUri,
        discoveryDocs,
        uxMode,
        scope,
        accessType,
        responseType,
        jsSrc,
        prompt,
    });
    const disabled = !!(disabledProp || !loaded);

    const [useCalanderBasedLogin, setUseCalanderBasedLogin] = React.useState(false);
    const [useRecallV2Onboarding, setUseRecallV2Onboarding] = React.useState(false);
    const [useRecallV2, setUseRecallV2] = React.useState(false);
    const shouldUseRecallV2Auth = useRecallV2Onboarding || useRecallV2;
    useEffect(() => {
        const check = async () => {
            const [calenderBasedSignedIn, recallV2Auth, recallV2Onboarding] = await Promise.all([
                postAnonymousFeatureFlag(FeatureToggle.CalenderBasedSignedIn, false, getAnonymousUserId()),
                postAnonymousFeatureFlag(FeatureToggle.RecallV2Auth, false, getAnonymousUserId()),
                postAnonymousFeatureFlag(FeatureToggle.RecallV2Onboarding, false, getAnonymousUserId()),
            ]);
            setUseCalanderBasedLogin(calenderBasedSignedIn);
            setUseRecallV2Onboarding(recallV2Onboarding);
            setUseRecallV2(recallV2Auth);
        };
        check();
    }, []);

    const onClickSignIn: React.MouseEventHandler<HTMLButtonElement> = (e) => {
        if (useCalanderBasedLogin || shouldUseRecallV2Auth) {
            // open a new popup
            const width = 600;
            const height = 600;
            const left = window.screen.width / 2 - width / 2;
            const top = window.screen.height / 2 - height / 2;
            const path = shouldUseRecallV2Auth
                ? SpinachAPIPath.GoogleRecallV2CalendarAuth
                : SpinachAPIPath.GoogleNewAuth;
            const popup = window.open(
                `${process.env.REACT_APP_AUTH_URL}${path}?errorRedirect=${encodeURIComponent(
                    window.location.href
                )}&spinachSource=loginPage`,
                'Google Sign In',
                `toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=${width},height=${height},top=${top},left=${left}`
            );
            if (popup) {
                let didLogin = false;
                // TODO fallback for when message is not received ? what should we do?
                window.addEventListener('message', async (event) => {
                    if (event.origin.toLowerCase().endsWith('spinach.io') && !didLogin) {
                        const user = await getUser();
                        if (user.user) {
                            onSuccessNew(user.user, event.data.isNewUser === 'true');
                            didLogin = true;
                        }
                    }
                });
                document.addEventListener('visibilitychange', async () => {
                    if (document.visibilityState === 'visible' && !didLogin) {
                        setTimeout(async () => {
                            const user = await getUser();
                            const isNewUser = false; // fallback will not be able to determine if user is new
                            if (user.user) {
                                onSuccessNew(user.user, isNewUser);
                                didLogin = true;
                            }
                        }, 5000); // use fallback only if the main one doesn't work
                    }
                });
            } else {
                // popup blocked, open in same window
                window.location.href = `${process.env.REACT_APP_AUTH_URL}${
                    SpinachAPIPath.GoogleNewAuth
                }?redirect=${encodeURIComponent(window.location.href)}&errorRedirect=${encodeURIComponent(
                    window.location.href
                )}&spinachSource=loginPage`;
                // TODO how to report analytics
            }
        } else {
            signIn(e);
        }
    };

    if (render) {
        return render({ onClick: signIn, disabled });
    }

    return (
        <LoginButton
            onClick={onClickSignIn}
            icon={
                variant === 'orange' ? (
                    <img src={WhiteIconSrc} style={{ width: '18px', height: '18px' }} />
                ) : (
                    <Icon disabled={disabled} />
                )
            }
            text={label || 'Sign in with Google'}
            variant={variant}
            disabled={disabled}
        />
    );
};

GoogleLogin.propTypes = {
    onSuccess: PropTypes.func.isRequired,
    onSuccessNew: PropTypes.func,
    onFailure: PropTypes.func.isRequired,
    onScriptLoadFailure: PropTypes.func,
    clientId: PropTypes.string.isRequired,
    jsSrc: PropTypes.string,
    onRequest: PropTypes.func,
    buttonText: PropTypes.node,
    scope: PropTypes.string,
    className: PropTypes.string,
    redirectUri: PropTypes.string,
    cookiePolicy: PropTypes.string,
    loginHint: PropTypes.string,
    hostedDomain: PropTypes.string,
    children: PropTypes.node,
    disabledStyle: PropTypes.object,
    fetchBasicProfile: PropTypes.bool,
    prompt: PropTypes.string,
    tag: PropTypes.string,
    autoLoad: PropTypes.bool,
    disabled: PropTypes.bool,
    discoveryDocs: PropTypes.array,
    uxMode: PropTypes.string,
    isSignedIn: PropTypes.bool,
    responseType: PropTypes.string,
    type: PropTypes.string,
    accessType: PropTypes.string,
    render: PropTypes.func,
    theme: PropTypes.string,
    icon: PropTypes.bool,
    label: PropTypes.string,
    variant: PropTypes.string,
};

GoogleLogin.defaultProps = {
    type: 'button',
    tag: 'button',
    buttonText: 'Connect with Google',
    scope: 'profile email',
    accessType: 'online',
    prompt: '',
    cookiePolicy: 'single_host_origin',
    fetchBasicProfile: true,
    isSignedIn: false,
    uxMode: 'popup',
    disabledStyle: {
        opacity: 0.6,
    },
    icon: true,
    theme: 'light',
    variant: 'default',
    onRequest: () => {},
};
