import { Box, IconButton } from '@material-ui/core';
import { ChevronLeft, InfoOutlined } from '@material-ui/icons';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import { CSSProperties, useState } from 'react';
import styled from 'styled-components';

import { INTERCOM_SUPPORT_URL } from '@spinach-shared/constants';

import { useGlobalAuthedUser } from '../../../..';
import { ReactComponent as CopyIcon } from '../../../assets/copy-icon.svg';
import { useScribeEmail } from '../../../hooks/useScribe';
import { BodyRegularOnboard, lightTheme } from '../../../styles';
import { copyTextToClipboard } from '../../../utils';
import { CircleBullet, Direction, Row, Spacing, SpinachStationary } from '../../common';
import { ButtonContainer } from '../styles';

export enum OnboardingStep {
    WelcomeToReverseTrial = 'start-reverse-trial',
    About = 'about',
    CalendarPermissions = 'calendar-permissions',
    AddSpinachToMeetings = 'add-spinach-to-meetings',
    ManualInvite = 'manual-invite',
    Slack = 'slack-integration',
    SlackDefaults = 'slack-defaults',
    Tickets = 'tickets',
    KnowledgeBase = 'knowledge-base',
    Finished = 'finished',
    ReverseTrialFinishFlow = 'finished-reverse-trial',
}

export enum ExperimentOneOnboardingStep {
    AddSpinachToMeetings = 'add-spinach-to-meetings',
    AboutAndFinish = 'about-and-finish',
}

export type StepProps = {
    direction: Direction;
    onSubmit: () => Promise<void>;
    loadingMessage: string;
};

export const Stationary = styled(SpinachStationary)`
    position: relative;
    min-height: 40%;
    max-height: 90%;
    padding: unset;
    filter: unset;
    background-color: rgba(0, 0, 0, 0);
`;

export function BackButton({ onBack, style = {} }: { onBack: () => void; style?: CSSProperties }): JSX.Element {
    return (
        <ButtonContainer>
            <IconButton
                aria-label="back to previous step"
                size="small"
                style={{ marginTop: '-5px', marginLeft: '-5px', ...style }}
                onClick={() => onBack()}
            >
                <ChevronLeft fontSize="default" />
            </IconButton>
        </ButtonContainer>
    );
}

export function BackButtonRow({ onBack, style = {} }: { onBack: () => void; style?: CSSProperties }): JSX.Element {
    const [isHovered, setIsHovered] = useState(false);
    return (
        <Row style={{ marginBottom: '6vh' }}>
            <IconButton
                aria-label="back to previous step"
                size="small"
                style={{
                    marginTop: '-5px',
                    marginLeft: '-5px',
                    backgroundColor: isHovered ? lightTheme.neutrals.gray : lightTheme.neutrals.grayLight,
                }}
                onClick={() => onBack()}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            >
                <ChevronLeft fontSize="default" />
            </IconButton>
        </Row>
    );
}

export function CopiableBotEmail(): JSX.Element {
    const scribeEmail = useScribeEmail();
    return (
        <span
            style={{
                margin: '0px 5px',
                padding: '5px',
                borderRadius: '8px',
                backgroundColor: lightTheme.neutrals.gray,
                cursor: 'pointer',
                fontWeight: 600,
                color: lightTheme.primary.greenDark,
                display: 'inline-flex',
                alignItems: 'center',
            }}
            onClick={() => {
                copyTextToClipboard(scribeEmail);
            }}
        >
            {scribeEmail}
            <CopyIcon style={{ marginLeft: '5px' }} />
        </span>
    );
}

export function DoItManually(): JSX.Element {
    const [user] = useGlobalAuthedUser();

    return (
        <>
            {user.isEnabledForCombinedSummaries ? (
                <>
                    <Spacing factor={1 / 2} />

                    <Row style={{ marginBottom: '50px' }}>
                        <InfoOutlined style={{ color: 'red', width: '32px', height: '32px', marginRight: '5px' }} />
                        <Box display="flex" flexDirection="column" mt="6px">
                            <Box fontWeight="bold">{'In meeting agendas require calendar access.'}</Box>
                            <Box>
                                {'Please '}
                                <a
                                    style={{ textDecoration: 'underline', color: 'inherit' }}
                                    href={INTERCOM_SUPPORT_URL}
                                >
                                    {'contact us'}
                                </a>
                                {' so we can help get you connected!'}
                            </Box>
                        </Box>
                    </Row>
                </>
            ) : null}

            <Spacing factor={1 / 2} />

            <Row>
                <CircleBullet>
                    <PersonAddIcon htmlColor="white" fontSize="small" />
                </CircleBullet>
                <BodyRegularOnboard style={{ fontWeight: 600, lineHeight: 1.7 }}>
                    {'Add '}
                    <CopiableBotEmail />
                    {
                        ' to the calendar invite. You can go to your dashboard for additional in meeting and post meeting controls.'
                    }
                </BodyRegularOnboard>
            </Row>
            <Spacing />
        </>
    );
}
