import React, { useCallback } from 'react';

import { getCommandContents } from '@spinach-shared/models';
import { ClientEventType, PostVideoAgentChatMessageRequest } from '@spinach-shared/types';

import {
    AGENT_SAYINGS,
    getAnnouncementForTopicKind,
    patchVideoAgentSession,
    postVideoAgentChatMessage,
} from '../../../..';
import { AgentCommandType, SayFunction } from '../../../types/agent';
import { useActivityTracking } from '../../useExperienceTracking';
import { useGlobalVideoAgent } from '../useGlobalVideoAgent';
import { useStartAgenda } from './useStartAgenda';

export function isNextAgendaTopicCommand(message: string): boolean {
    const nextTopicCommands = [
        'next',
        '/topic next',
        '/agenda next',
        'skip',
        'forward',
        '/spinach next',
        'spinach next',
    ];
    const { commandMatched: isNextTopicTrigger } = getCommandContents(message, nextTopicCommands);

    return !!isNextTopicTrigger;
}

export function useNextAgendaTopic(say: SayFunction) {
    const {
        state: { session },
        setSession,
    } = useGlobalVideoAgent();
    const trackActivity = useActivityTracking();

    const startAgenda = useStartAgenda(say);

    const announceNextTopic = React.useCallback(() => {
        const topic = session?.followingTopic;
        if (!topic) {
            throw new Error('No next topic when trying to announce');
        }
        const theFollowingTopic = session.getFollowingTopicFrom(2);

        // Announce the current topic
        const announcement = getAnnouncementForTopicKind(topic.kind, topic, session.currentTopicIndex ?? 0 + 1);
        say(announcement);

        // Precache the following topic if it exists
        if (theFollowingTopic) {
            const precacheAnnouncement = getAnnouncementForTopicKind(
                theFollowingTopic.kind,
                theFollowingTopic,
                session.currentTopicIndex ?? 0 + 2
            );
            say(precacheAnnouncement, true); // triggers caching
        }
    }, [session, say]);

    // TODO pass in voice or chat trigger source
    return useCallback(
        async (triggerMedium = AgentCommandType.Voice) => {
            if (!session) {
                return;
            }

            const currentIndex = session.currentTopicIndex;
            const lastTopicIndex = session.lastTopicIndex;

            if (session.isLobbyPhase) {
                startAgenda();
                return;
            }

            if (lastTopicIndex === undefined || currentIndex === undefined) {
                // no topics added at all, this won't happen due to other protections, but making TS happy below
                return;
            }

            const updatedSession = session.withProgressedAgenda();
            setSession(updatedSession);

            trackActivity(ClientEventType.VideoAgentActivity, 'Next Topic', {
                TriggeredBy: triggerMedium, // TODO update voice or chat trigger source
                When: session.currentTopic?.id ? 'After Agenda Started' : 'Before Agenda Started',
                ...session?.analyticsPayload,
            });

            if (updatedSession.isOnLastTopic) {
                if (triggerMedium === AgentCommandType.Voice || session.isChatCommandAudioOutputEnabled) {
                    if (updatedSession.feedbackTopic) {
                        say(AGENT_SAYINGS.GoToLastTopicAsFeedback);
                    } else {
                        say(AGENT_SAYINGS.GoToLastTopicNoFeedback);
                    }
                }
                trackActivity(ClientEventType.VideoAgentActivity, 'Last Topic', {
                    TriggeredBy: triggerMedium,
                    ...updatedSession?.analyticsPayload,
                });
            } else {
                if (triggerMedium === AgentCommandType.Voice || session.isChatCommandAudioOutputEnabled) {
                    announceNextTopic();
                }
                trackActivity(ClientEventType.VideoAgentActivity, 'Next Topic', {
                    TriggeredBy: triggerMedium,
                    ...updatedSession?.analyticsPayload,
                });
            }

            let chatPayload: PostVideoAgentChatMessageRequest | null = null;
            if (updatedSession.currentTopic?.title) {
                const topicQualifier =
                    updatedSession.currentTopic.id === updatedSession.lastTopic?.id ? 'Last' : 'Next';
                const nextTopicMessage = `${topicQualifier} topic: "${updatedSession.currentTopic.title}"`;
                chatPayload = {
                    message: nextTopicMessage,
                    botId: updatedSession.botId,
                };
            }

            await Promise.all([
                patchVideoAgentSession(updatedSession.toJSON()),
                chatPayload ? postVideoAgentChatMessage(chatPayload) : undefined,
            ]);

            if (updatedSession.isOnLastTopic) {
                const lastTopicMessagePayload: PostVideoAgentChatMessageRequest = {
                    message: `When the meeting is over, I'll send out the notes.`,
                    botId: updatedSession.botId,
                    options: {
                        oncePerMeetingProtectionKey: 'video-agent-share-notes-after',
                    },
                };
                await postVideoAgentChatMessage(lastTopicMessagePayload);
            }
        },
        [session, setSession, trackActivity, startAgenda, say, announceNextTopic]
    );
}
