import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { SetterOrUpdater, useRecoilState } from 'recoil';

import { DashboardSection } from '@spinach-shared/constants';
import { WebUrlQuery } from '@spinach-shared/types';

import { GlobalAiDashboardState, atomAiDashboardState } from '../atoms';

export type SetGlobalAiDashboardState = SetterOrUpdater<GlobalAiDashboardState>;

export type GlobalAiDashboardProps = {
    state: GlobalAiDashboardState;
    toastText: GlobalAiDashboardState['toastText'];
    activeSection: DashboardSection;
    openMeetingsSection: () => void;
    openIntegrationsSection: () => void;
    openTranscriptsSection: () => void;
    openSummariesSection: () => void;
    setActiveSection: (activeSection: DashboardSection) => void;
    setToastText: (toastText: GlobalAiDashboardState['toastText']) => void;
    setState: SetGlobalAiDashboardState;
};

export function useGlobalAiDashboard(): GlobalAiDashboardProps {
    const [state, setState] = useRecoilState(atomAiDashboardState);
    const [searchParams, setSearchParams] = useSearchParams();

    function openMeetingsSection() {
        setActiveSection(DashboardSection.Meetings);
    }

    function openIntegrationsSection() {
        setActiveSection(DashboardSection.Integrations);
    }

    function openTranscriptsSection() {
        setActiveSection(DashboardSection.Transcripts);
    }

    function openSummariesSection() {
        setActiveSection(DashboardSection.Summaries);
    }

    function setActiveSection(activeSection: DashboardSection) {
        setState({ ...state, activeSection });
        const search = new URLSearchParams({
            ...Object.fromEntries(searchParams),
            ...{ [WebUrlQuery.Section]: activeSection },
        }).toString();
        setSearchParams(search, { replace: true });
    }

    const setToastText = useCallback(
        function setToastText(toastText: GlobalAiDashboardState['toastText']) {
            setState((state) => ({ ...state, toastText }));
        },
        [setState]
    );

    return {
        state,
        toastText: state.toastText,
        activeSection: state.activeSection,
        openMeetingsSection,
        setActiveSection,
        openIntegrationsSection,
        openTranscriptsSection,
        openSummariesSection,
        setToastText,
        setState,
    };
}
