import { CircularProgress } from '@material-ui/core';
import lottie from 'lottie-web';
import { useEffect } from 'react';
import styled from 'styled-components';

import { CalendarEvent, ClientEventType } from '@spinach-shared/types';

import { MeetingsAndSuggestions, useExperienceTracking, useLandingAnalytic, useWindowSize } from '../../../../..';
import celebrateLottieJSON from '../../../../assets/celebrate.json';
import { ReactComponent as SpinachLogo } from '../../../../assets/logo-spinachio.svg';
import SpinachEcosystemSrc from '../../../../assets/onb-v1-add-graphic.png';
import SpinachProofSrc from '../../../../assets/onb-v1-social-proof.png';
import { BodyRegularOnboard, HeaderThreeOnboard, lightTheme } from '../../../../styles';
import { Column, Row, SlidingColumn, Spacing } from '../../../common';
import { ScrollView } from '../../../series';
import { PrimaryButton } from '../../../stand-up';
import SecondaryButton from '../../../stand-up/SecondaryButton';
import { StepProps } from '../common';
import { ExperimentOneCalendarSelections } from './ExperimentOneCalendarSelection';

export type ExperimentOneSelectEventForMeetingProps = StepProps & {
    isFetchingEvents: boolean;
    hasError: boolean;
    updatingEvents: string[];
    onEventClick: (event: CalendarEvent) => void;
    data: MeetingsAndSuggestions | undefined;
    onboardingEventsToAddScribeTo: CalendarEvent[];
    refetch: () => Promise<void>;
};

const ErrorMessage = styled.div`
    background: #f6cbcb;
    padding: 16px;
    border-radius: 12px;
`;

const HeaderThree = styled(HeaderThreeOnboard)`
    position: relative;
    .celebrate-welcome-animation {
        position: absolute;
        height: 400px !important;
        top: -200%;
        left: 0;
        right: 0;
        margin: auto;
        pointer-events: none;
    }
`;

export function ExperimentOneAddToMeetingsStep({
    direction,
    onSubmit,
    loadingMessage,
    hasError,
    data,
    onEventClick,
    onboardingEventsToAddScribeTo,
    isFetchingEvents,
    updatingEvents,
    refetch,
}: ExperimentOneSelectEventForMeetingProps): JSX.Element {
    const track = useExperienceTracking();
    const eventsToDisplay = data?.events;
    useLandingAnalytic(ClientEventType.UserViewedAddSpinachToMeetingsStep);

    useEffect(() => {
        const container = document.querySelector('#welcome-onb-header');
        if (!!container) {
            const animation = lottie.loadAnimation({
                container,
                autoplay: false,
                loop: false,
                animationData: celebrateLottieJSON,
                rendererSettings: {
                    className: 'celebrate-welcome-animation',
                },
            });
            animation.setSpeed(0.33);
            animation.play();
        }
    }, [data?.suggestionsWithoutSpinach.length]);

    const fetchingEventsMessage = eventsToDisplay && !isFetchingEvents ? '' : 'Analyzing events...';
    const noEventsFound = eventsToDisplay?.length === 0;
    const proceedIfCalendarEmpty = noEventsFound && !isFetchingEvents;
    const { width, isDesktopView } = useWindowSize();
    return (
        <Row style={{ height: '100%' }}>
            <Column vCentered={true} centered={true} style={{ flex: 1, margin: '20px', width: 'calc(100% - 40px)' }}>
                <Row>
                    <SpinachLogo
                        style={{
                            height: isDesktopView ? '55px' : '25px',
                        }}
                    />
                </Row>
                <Column style={{ maxWidth: '600px', height: 'calc(100% - 55px)' }}>
                    <Spacing factor={2} />
                    <HeaderThree id={data?.suggestionsWithoutSpinach.length ? 'welcome-onb-header' : undefined}>
                        Welcome! We've found the best meetings for Spinach
                    </HeaderThree>
                    <Spacing factor={1 / 2} />
                    <BodyRegularOnboard>Spinach is optimized for product development meetings.</BodyRegularOnboard>
                    <Spacing factor={1 / 2} />

                    <ScrollView sidePadding={0} style={{ backgroundColor: 'rgba(0,0,0,0)', background: 'unset' }}>
                        <SlidingColumn centered direction={direction}>
                            {!!eventsToDisplay?.length && !isFetchingEvents ? (
                                <>
                                    <ExperimentOneCalendarSelections
                                        updatingEvents={updatingEvents}
                                        isOnboardingFlow={true}
                                        hasError={hasError}
                                        setShouldShowNotification={() => undefined}
                                        shouldShowNotification={false}
                                        onEventClick={onEventClick}
                                        relevantEvents={eventsToDisplay}
                                        onboardingEventsToAddScribeTo={onboardingEventsToAddScribeTo}
                                        meetingsAndSuggestions={data}
                                    />
                                </>
                            ) : noEventsFound ? (
                                <BodyRegularOnboard style={{ textAlign: 'center' }}>
                                    It looks like your calendar is empty.
                                </BodyRegularOnboard>
                            ) : null}
                        </SlidingColumn>
                    </ScrollView>
                    {!!fetchingEventsMessage || hasError ? (
                        <>
                            {hasError ? (
                                <ErrorMessage style={{ marginTop: '5px' }}>
                                    We're having some trouble with your calendar. Keep going and you can try again
                                    later.
                                </ErrorMessage>
                            ) : !!fetchingEventsMessage ? (
                                <Row centered>
                                    <Column centered>
                                        <CircularProgress
                                            size={'30px'}
                                            style={{
                                                marginRight: '5px',
                                                marginBottom: '5px',
                                                color: lightTheme.primary.orangeDark,
                                            }}
                                        />
                                        <BodyRegularOnboard>
                                            <b>{fetchingEventsMessage}</b>
                                        </BodyRegularOnboard>
                                    </Column>
                                </Row>
                            ) : (
                                <></>
                            )}
                        </>
                    ) : (
                        <></>
                    )}
                    <>
                        <Row centered={true} style={{ marginTop: '30px', marginBottom: '10px' }}>
                            <SecondaryButton
                                title="Refresh meetings"
                                onClick={() => {
                                    track(ClientEventType.OnboardingClick, {
                                        ClickedOn: 'Refresh Meetings',
                                    });
                                    refetch();
                                }}
                                disabled={isFetchingEvents}
                            />
                        </Row>
                        <Row centered>
                            <PrimaryButton
                                style={{ zIndex: 1000, marginTop: '10px', marginBottom: '5px' }}
                                title={'Continue'}
                                onClick={onSubmit}
                                loadingText={loadingMessage}
                                isLoading={!!loadingMessage}
                                disabled={!onboardingEventsToAddScribeTo.length || hasError || proceedIfCalendarEmpty}
                            />
                        </Row>
                        <Row style={{ marginBottom: '30px', marginTop: '5px' }} centered={true}>
                            <BodyRegularOnboard>You can modify this later</BodyRegularOnboard>
                        </Row>
                    </>
                </Column>
            </Column>
            {width > 900 ? (
                <Column style={{ backgroundColor: '#F4F6F6', flex: 1 }} centered={true} vCentered={true}>
                    <img src={SpinachEcosystemSrc} style={{ maxWidth: '70%', maxHeight: '60%' }} />
                    <Spacing factor={2} />
                    <img src={SpinachProofSrc} style={{ maxWidth: '70%', maxHeight: '10%' }} />
                </Column>
            ) : null}
        </Row>
    );
}
