import {
    Box,
    Checkbox,
    CircularProgress,
    Collapse,
    FormControlLabel,
    FormGroup,
    InputAdornment,
    TextField,
    TextareaAutosize,
} from '@material-ui/core';
import { Stop } from '@material-ui/icons';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import Vapi from '@vapi-ai/web';
import { OverrideAssistantDTO } from '@vapi-ai/web/dist/api';
import axios from 'axios';
import { ChangeEvent, useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';

import { ClientEventType, FeatureToggle, IClientUser, SpinachAPIPath } from '@spinach-shared/types';

import {
    ClientLogger,
    CollapsableAutocomplete,
    Row,
    SetValue,
    Spacing,
    copyElementContent,
    postSpinachAPI,
    useGlobalAiDashboard,
    useGlobalAuthedUser,
} from '../../../../..';
import { ReactComponent as SpinachAiIcon } from '../../../../assets/Spinach-ai-icon.svg';
import { ReactComponent as Star } from '../../../../assets/Star.svg';
import { ReactComponent as Shape } from '../../../../assets/ask-spinach/AskSpinach-Shape.svg';
import { ReactComponent as SpinachAvatar } from '../../../../assets/ask-spinach/Spinach_avatar.svg';
import { ReactComponent as OutlinedMicrophone } from '../../../../assets/ask-spinach/outlined-microphone.svg';
import { ReactComponent as CopyIcon } from '../../../../assets/copy-icon.svg';
import { useAskSpinachEnabledQueries, useExperienceTracking } from '../../../../hooks';
import { useGlobalStoredSeriesList } from '../../../../hooks/useGlobalStoredSeriesList';
import { ButtonSize, lightTheme } from '../../../../styles';
import { PrimaryButton } from '../../../stand-up';

const filter = createFilterOptions();
const VAPI_ASSISTANT_ID = process.env.REACT_APP_VAPI_ASSISTANT_ID;

const useVapi = (user: IClientUser) => {
    return useState<Vapi | null>(() => {
        if (
            process.env.REACT_APP_VAPI_API_TOKEN &&
            VAPI_ASSISTANT_ID &&
            !!user.featureToggles?.[FeatureToggle.RealtimeAskSpinach]
        ) {
            const vapiInstance = new Vapi(process.env.REACT_APP_VAPI_API_TOKEN);
            return vapiInstance;
        }
        return null;
    });
};

const defaultVapiClientMessages: OverrideAssistantDTO['clientMessages'] = [
    'speech-update',
    'transcript',
    'metadata',
    'conversation-update',
    'model-output',
    'voice-input',
];

type AskSpinachResponse = { type: 'success' | 'error' | 'loading'; text?: string };

enum RealtimeAskSpinachStatus {
    Connecting = 'connecting',
    Speaking = 'speaking',
    Listening = 'listening',
}

export enum AskSpinachFrom {
    AsyncSummary = 'async-summary',
    MediaComponent = 'media-component',
    MeetingHistory = 'meeting-history',
}

type AskSpinachTextButtonProps = {
    isTalkWithSpinachSelected: boolean;
    setIsTalkWithSpinachSelected: SetValue<boolean>;
    isFreeForm: boolean;
    isDisabled: boolean;
    askSpinachQuery?: string;
    askSpinachFreeform?: string;
    from: AskSpinachFrom;
    botId: string;
    setAskSpinachResponse: SetValue<AskSpinachResponse | undefined>;
};

type AskSpinachVoiceButtonProps = {
    from: AskSpinachFrom;
    isDisabled: boolean;
    onClick: () => void;
};

type AskSpinachButtonRowProps = Omit<AskSpinachTextButtonProps, 'isDisabled'> & {
    shouldShowRealTimeAskSpinachComponents: boolean;
    askSpinachResponse?: AskSpinachResponse;
    askSpinachOptions: AskSpinachOptions[];
};

const AskSpinachButtonRow = (props: AskSpinachButtonRowProps) => {
    const {
        askSpinachQuery,
        from,
        isTalkWithSpinachSelected,
        setIsTalkWithSpinachSelected,
        askSpinachResponse,
        isFreeForm,
        askSpinachFreeform,
        shouldShowRealTimeAskSpinachComponents,
        setAskSpinachResponse,
        botId,
        askSpinachOptions,
    } = props;
    return (
        <Row>
            <AskSpinachTextButton
                from={from}
                isTalkWithSpinachSelected={isTalkWithSpinachSelected}
                setIsTalkWithSpinachSelected={setIsTalkWithSpinachSelected}
                askSpinachQuery={askSpinachQuery}
                isFreeForm={!!isFreeForm}
                isDisabled={
                    !isTalkWithSpinachSelected &&
                    ((askSpinachResponse && askSpinachResponse.type === 'loading') ||
                        !(
                            askSpinachOptions.find((option) => option.id === askSpinachQuery)?.freeform === false ||
                            !!askSpinachFreeform
                        ))
                }
                askSpinachFreeform={askSpinachFreeform}
                setAskSpinachResponse={setAskSpinachResponse}
                botId={botId}
            />
            {shouldShowRealTimeAskSpinachComponents ? (
                <>
                    <Spacing horizontal={true} />
                    <AskSpinachVoiceButton
                        from={from}
                        isDisabled={isTalkWithSpinachSelected}
                        onClick={() => {
                            if (isTalkWithSpinachSelected) {
                                return;
                            }
                            setIsTalkWithSpinachSelected(!isTalkWithSpinachSelected);
                        }}
                    />
                </>
            ) : (
                <></>
            )}
        </Row>
    );
};

const AskSpinachVoiceButton = ({ isDisabled, onClick, from }: AskSpinachVoiceButtonProps) => {
    return (
        <PrimaryButton
            size={from !== AskSpinachFrom.MeetingHistory ? ButtonSize.Small : ButtonSize.Normal}
            disabled={isDisabled}
            containerStyles={{
                paddingLeft: '20px',
                paddingRight: '20px',
                marginTop: '10px',
                width: 'fit-content',
            }}
            title={'Or Talk with Spinach'}
            onClick={onClick}
        />
    );
};

const AskSpinachTextButton = (props: AskSpinachTextButtonProps) => {
    const {
        askSpinachQuery,
        isFreeForm,
        askSpinachFreeform,
        from,
        setAskSpinachResponse,
        botId,
        isTalkWithSpinachSelected,
        setIsTalkWithSpinachSelected,
        isDisabled,
    } = props;
    const track = useExperienceTracking();

    const isSuggestion = askSpinachQuery === ASK_SPINACH_FEATURE_REQUEST_ID;
    return (
        <PrimaryButton
            size={from !== AskSpinachFrom.MeetingHistory ? ButtonSize.Small : ButtonSize.Normal}
            containerStyles={{
                paddingLeft: '20px',
                paddingRight: '20px',
                marginTop: '10px',
                width: 'fit-content',
            }}
            title={isSuggestion ? 'Request Feature' : 'Ask'}
            disabled={isDisabled}
            onClick={async () => {
                if (isTalkWithSpinachSelected) {
                    setIsTalkWithSpinachSelected(false);
                    return;
                }
                if (isSuggestion) {
                    track(ClientEventType.AIDashboardClick, {
                        ClickedOn: 'Ask Spinach - Submit Feature Request',
                        suggestion: askSpinachFreeform,
                    });
                    setAskSpinachResponse({
                        type: 'success',
                        text: 'Thank you for your suggestion! We will work hard to make it happen.',
                    });
                } else {
                    track(ClientEventType.AIDashboardClick, {
                        ClickedOn: 'Ask Spinach - Submit Query',
                        query: askSpinachQuery,
                    });
                    setAskSpinachResponse({ type: 'loading' });
                    try {
                        const response = await Promise.race([
                            postSpinachAPI(
                                SpinachAPIPath.AskSpinach,
                                isFreeForm
                                    ? {
                                          query: askSpinachQuery,
                                          freeform: askSpinachFreeform,
                                          botId,
                                      }
                                    : {
                                          query: askSpinachQuery,
                                          botId,
                                      },
                                { throwOnError: true }
                            ),
                            new Promise((resolve) => setTimeout(resolve, 25000)),
                        ]);
                        if (response && (response as any).text) {
                            track(ClientEventType.AIDashboardActivity, {
                                ClickedOn: 'Ask Spinach - response success',
                                query: askSpinachQuery,
                            });
                            setAskSpinachResponse({
                                type: 'success',
                                text: (response as any).text as string,
                            });
                        } else {
                            track(ClientEventType.AIDashboardActivity, {
                                ClickedOn: 'Ask Spinach - error in overall response',
                                query: askSpinachQuery,
                            });
                            setAskSpinachResponse({ type: 'error' });
                        }
                    } catch (e) {
                        if (axios.isAxiosError(e) && e.response?.status === 429) {
                            setAskSpinachResponse({
                                type: 'error',
                                text:
                                    e.response.data.message ?? 'You have reached the limit of requests for this hour.',
                            });
                            track(ClientEventType.AIDashboardActivity, {
                                Activity: 'Ask Spinach - rate limit exceeded',
                                query: askSpinachQuery,
                            });
                        } else {
                            setAskSpinachResponse({ type: 'error' });
                            track(ClientEventType.AIDashboardActivity, {
                                ClickedOn: 'Ask Spinach - error in overall response',
                                query: askSpinachQuery,
                            });
                        }
                    }
                }
            }}
        />
    );
};

const RealTimeAskSpinachStartTextAdornment = ({
    realtimeAskSpinachStatus,
}: {
    realtimeAskSpinachStatus: RealtimeAskSpinachStatus | null;
}) => {
    if (realtimeAskSpinachStatus === RealtimeAskSpinachStatus.Connecting) {
        return <CircularProgress size={'24px'} style={{ marginRight: '11px' }} />;
    }
    if (realtimeAskSpinachStatus === RealtimeAskSpinachStatus.Speaking) {
        return (
            <InputAdornment position="start">
                <Stop htmlColor={lightTheme.primary.orangeLight} />
            </InputAdornment>
        );
    }
    return (
        <InputAdornment position="start">
            <OutlinedMicrophone
                style={{
                    cursor: 'pointer',
                }}
                fill={
                    realtimeAskSpinachStatus === RealtimeAskSpinachStatus.Listening
                        ? lightTheme.primary.orangeLight
                        : '#B8B8C5'
                }
            />
        </InputAdornment>
    );
};

const RealTimeAskSpinach = ({ minimalTranscriptResponse }: { minimalTranscriptResponse: string[] | null }) => {
    const [user] = useGlobalAuthedUser();
    const track = useExperienceTracking();
    const [realtimeAskSpinachStatus, setRealtimeAskSpinachStatus] = useState<RealtimeAskSpinachStatus | null>(null);

    const { setToastText } = useGlobalAiDashboard();

    const [vapi] = useVapi(user);

    useEffect(() => {
        const stopVapi = () => {
            vapi?.stop();
        };
        window.addEventListener('beforeunload', stopVapi);
        return () => window.removeEventListener('beforeunload', stopVapi);
    }, [vapi]);

    const registerVapiListeners = (vapi: Vapi) => {
        vapi.on('speech-start', () => {
            track(ClientEventType.AIDashboardActivity, {
                Activity: 'Spinach began speaking',
            });
            setRealtimeAskSpinachStatus(RealtimeAskSpinachStatus.Speaking);
        });

        vapi.on('speech-end', () => {
            track(ClientEventType.AIDashboardActivity, {
                Activity: 'Spinach stopped speaking',
            });
            if (realtimeAskSpinachStatus === RealtimeAskSpinachStatus.Speaking) {
                setRealtimeAskSpinachStatus(RealtimeAskSpinachStatus.Listening);
            }
        });
        vapi.on('call-end', () => {
            track(ClientEventType.AIDashboardActivity, {
                Activity: 'Spinach call ended',
            });
            setRealtimeAskSpinachStatus(null);
        });
        vapi.on('message', (message) => {
            if (message && message.type === 'speech-update' && message.status === 'started') {
                setRealtimeAskSpinachStatus(RealtimeAskSpinachStatus.Listening);
            }
        });
        vapi.on('error', (errorResponse) => {
            track(ClientEventType.AIDashboardActivity, {
                Activity: 'Vapi Error',
                StatusCode: errorResponse.status,
                RealtimeAskSpinachStatus: realtimeAskSpinachStatus,
            });
            ClientLogger.info('Vapi emitted an error', {
                errorResponse: errorResponse,
                statusCode: errorResponse.status,
                url: errorResponse.url,
            });
            if ('status' in errorResponse && errorResponse.status === 400) {
                setToastText('There are too many people using Ask Spinach right now. Please try again later');
            }
            setRealtimeAskSpinachStatus(null);
        });
    };

    const realTimeAskSpinachClick = (vapiOverrides: OverrideAssistantDTO) => {
        track(ClientEventType.AIDashboardClick, {
            ClickedOn: 'Realtime Ask Spinach',
            RealtimeAskSpinachStatus: realtimeAskSpinachStatus,
            IsVapiInitialized: !!vapi,
        });

        if (!vapi) {
            return;
        }
        /**
         * @NOTE if spinach WAS listening, but is no longer listening, then stop speach
         */
        if (realtimeAskSpinachStatus !== null) {
            vapi.stop();
            setRealtimeAskSpinachStatus(null);
            return;
        }

        const assistantOverrides: OverrideAssistantDTO = {
            backgroundSound: 'office',
            clientMessages: defaultVapiClientMessages,
            ...vapiOverrides,
        };
        registerVapiListeners(vapi);
        vapi.start(VAPI_ASSISTANT_ID!, assistantOverrides);
        setRealtimeAskSpinachStatus(RealtimeAskSpinachStatus.Connecting);
    };

    /**
     * @NOTE we don't want the text field to focus since the user is not
     * allowed to type here. However, the TextField component is nice to use
     * and gives us some good UX out of the box, so I'd like to use it even
     * though the user is not meant to type here
     * */
    return (
        <TextField
            variant="outlined"
            fullWidth
            size="small"
            onFocus={(e) => {
                e.preventDefault();
            }}
            onClick={(e) => {
                e.preventDefault();
                realTimeAskSpinachClick({
                    variableValues: {
                        transcript: minimalTranscriptResponse?.join(' '),
                    },
                });
            }}
            style={{
                fontSize: '17px',
                borderRadius: '5px',
                cursor: 'pointer',
                marginRight: '15px',
                borderColor: lightTheme.neutrals.midnight,
                outline: 'none',
            }}
            placeholder={
                realtimeAskSpinachStatus === RealtimeAskSpinachStatus.Speaking
                    ? 'Speaking...'
                    : realtimeAskSpinachStatus === RealtimeAskSpinachStatus.Listening
                    ? 'Listening'
                    : 'Click to have a conversation with Spinach'
            }
            inputProps={{ style: { cursor: 'pointer' } }}
            InputProps={{
                style: { cursor: 'pointer' },
                readOnly: true,
                onClick: () =>
                    realTimeAskSpinachClick({
                        variableValues: {
                            transcript: minimalTranscriptResponse?.join(' '),
                        },
                    }),
                startAdornment: (
                    <RealTimeAskSpinachStartTextAdornment realtimeAskSpinachStatus={realtimeAskSpinachStatus} />
                ),
            }}
        />
    );
};

const askSpinachMarkDownId = 'ask-spinach-markdown';

const AskSpinachResponseSection = ({
    askSpinachResponse,
    askSpinachQuery,
}: {
    askSpinachResponse?: AskSpinachResponse;
    askSpinachQuery?: string;
}) => {
    const track = useExperienceTracking();
    return (
        <Box display="flex" flexDirection="column" marginTop="10px">
            {askSpinachResponse ? (
                <Box>
                    {askSpinachResponse.type === 'loading' ? (
                        <Box width="50px" height="50px">
                            <CircularProgress />
                        </Box>
                    ) : askSpinachResponse.type === 'success' && askSpinachResponse.text ? (
                        <Box
                            display="flex"
                            flexDirection="column"
                            position="relative"
                            style={{
                                background: '#EEEEEE',
                                borderRadius: '5px',
                            }}
                        >
                            <Box
                                display="flex"
                                style={{
                                    position: 'absolute',
                                    right: 0,
                                    top: 0,
                                    padding: '5px',
                                    cursor: 'pointer',
                                    zIndex: 1,
                                }}
                                onClick={() => {
                                    if (askSpinachResponse.text) {
                                        copyElementContent(askSpinachMarkDownId);
                                        track(ClientEventType.AIDashboardClick, {
                                            ClickedOn: 'Ask Spinach - Copy response',
                                            query: askSpinachQuery,
                                        });
                                    }
                                }}
                            >
                                <CopyIcon
                                    style={{
                                        padding: '5px',
                                        marginTop: '3px',
                                        color: lightTheme.primary.green,
                                    }}
                                />
                                <Box
                                    mr="10px"
                                    style={{
                                        color: lightTheme.primary.green,
                                        fontSize: '16px',
                                        fontWeight: 'bold',
                                    }}
                                    mt="6px"
                                >{`copy`}</Box>
                            </Box>
                            <Box
                                display="flex"
                                position="relative"
                                flexDirection="column"
                                style={{
                                    padding: '5px',
                                }}
                            >
                                <SpinachAvatar style={{ marginTop: '10px', alignSelf: 'center', height: '30px' }} />
                                <Box style={{ overflow: 'hidden' }} p="7px" id={askSpinachMarkDownId}>
                                    <ReactMarkdown>{askSpinachResponse.text}</ReactMarkdown>
                                </Box>
                            </Box>
                            <Box
                                style={{
                                    borderRadius: '5px',
                                    marginTop: '20px',
                                    marginBottom: '20px',
                                    marginLeft: 'auto',
                                    // marginRight: 'auto',
                                }}
                            >
                                {`Regenerate the answer by asking Spinach again.`}
                            </Box>
                        </Box>
                    ) : (
                        <Box>{askSpinachResponse.text ?? 'Failed to get response from Spinach'}</Box>
                    )}
                </Box>
            ) : null}
        </Box>
    );
};

type AskSpinachOptions = {
    id: string;
    name: string;
    default?: boolean;
    inputValue?: string;
    hint?: string;
    freeformLimit?: number;
    featureRequest?: boolean;
    freeform?: boolean;
    isMultiMeeting?: boolean;
};

const ASK_SPINACH_FEATURE_REQUEST_ID = 'featureRequestId';
const ASK_SPINACH_FEATURE_REQUEST_OPTION = {
    id: ASK_SPINACH_FEATURE_REQUEST_ID,
    name: 'Feedback - Request my own prompt',
    hint: 'What would you like Spinach to do?',
    inputValue: '',
    featureRequest: true,
    freeformLimit: 200,
    freeform: true,
};

const BetaFeatureTag = () => {
    return (
        <Box
            style={{
                background: lightTheme.primary.green,
                color: lightTheme.neutrals.white,
                padding: '3px',
                fontWeight: 'bold',
                fontSize: '12px',
                marginLeft: '10px',
                borderRadius: '3px',
                width: '28px',
                height: '15px',
            }}
        >
            {'Beta'}
        </Box>
    );
};

export const AskSpinach = ({
    botId,
    from,
    minimalTranscriptResponse,
    seriesId,
}: {
    botId: string;
    from: AskSpinachFrom;
    seriesId?: string | null;
    minimalTranscriptResponse: string[] | null;
}) => {
    const track = useExperienceTracking();
    const [user] = useGlobalAuthedUser();
    const [vapi] = useVapi(user);
    const askSpinachConfig = useAskSpinachEnabledQueries();
    const {
        storedSeriesListState: { storedSeriesList },
    } = useGlobalStoredSeriesList();

    const isCurrentSeriesRecurring = storedSeriesList.find((series) => series.id === seriesId)?.metadata.scribeMetadata
        ?.isRecurring;
    const multiMeetingAskSpinachEnabled = user.isMultiMeetingAskSpinachEnabled && !!isCurrentSeriesRecurring;

    let askSpinachOptions: AskSpinachOptions[] = Object.entries(askSpinachConfig)
        .map(([id, config]) => ({
            id,
            ...config,
        }))
        .sort((a, b) => {
            // use sortingScore with alphabetical as tie breaker, missing sortingScore is same as 0
            const aScore = a.sortingScore ?? 0;
            const bScore = b.sortingScore ?? 0;
            if (aScore === bScore) {
                return a.name.localeCompare(b.name);
            }
            return bScore - aScore;
        });

    // filter out multi meeting queries
    if (!multiMeetingAskSpinachEnabled) {
        askSpinachOptions = askSpinachOptions.filter((option) => !option.isMultiMeeting);
    }

    askSpinachOptions.push(ASK_SPINACH_FEATURE_REQUEST_OPTION);

    const [askSpinachQuery, setAskSpinachQuery] = useState<string | undefined>(undefined);

    const [isTalkWithSpinachSelected, setIsTalkWithSpinachSelected] = useState(false);
    const [askSpinachFreeform, setAskSpinachFreeform] = useState<string | undefined>(undefined);

    const [askSpinachResponse, setAskSpinachResponse] = useState<
        { type: 'success' | 'error' | 'loading'; text?: string } | undefined
    >(undefined);

    const defaultAskSpinachOption = askSpinachOptions.find((option) => option.default);
    const selectedAskSpinachOption = askSpinachOptions.find((option) => option.id === askSpinachQuery);
    const isFreeForm = askSpinachOptions && selectedAskSpinachOption?.freeform === true;

    const isRealtimeAskSpinachEnabled = user.isRealtimeAskSpinachEnabled;
    const shouldShowRealTimeAskSpinachComponents = Boolean(
        minimalTranscriptResponse?.length && isRealtimeAskSpinachEnabled && !!vapi && VAPI_ASSISTANT_ID
    );

    const onAskSpinachFeatureRequestSelection = (_: ChangeEvent<{}>, selection: AskSpinachOptions | null) => {
        if (selection && 'fetureRequest' in selection) {
            setAskSpinachQuery(ASK_SPINACH_FEATURE_REQUEST_ID);
            setAskSpinachFreeform(selection.inputValue);
        } else if (selection?.default && defaultAskSpinachOption) {
            setAskSpinachQuery(defaultAskSpinachOption.id);
            setAskSpinachFreeform(selection.inputValue);
            track(ClientEventType.AIDashboardClick, {
                ClickedOn: 'Ask Spinach - selected query from dropdown',
                query: defaultAskSpinachOption.id,
            });
        } else {
            setAskSpinachQuery(selection?.id);
            setAskSpinachFreeform('');
            track(ClientEventType.AIDashboardClick, {
                ClickedOn: 'Ask Spinach - selected query from dropdown',
                query: selection?.id,
            });
        }
    };

    return (
        <>
            {Object.keys(askSpinachConfig).length > 0 ? (
                <Box
                    display="flex"
                    flexDirection="column"
                    mr="10px"
                    mb="10px"
                    style={{
                        border: `solid 1px ${lightTheme.secondary.greenLight}`,
                        borderRadius: '10px',
                    }}
                >
                    <Box display="flex" flexDirection="row" position="relative">
                        <Box
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                borderRadius: '10px',
                                overflow: 'hidden',
                            }}
                        >
                            <Shape />
                        </Box>
                        <Box style={{ zIndex: 1 }} display="flex" flexDirection="row" mt="10px" ml="16px">
                            <Box
                                mr="10px"
                                style={{ color: lightTheme.primary.green, fontSize: '20px', fontWeight: 'bold' }}
                            >{`Ask Spinach`}</Box>
                            <SpinachAiIcon />
                            <BetaFeatureTag />
                        </Box>
                    </Box>
                    <Box display="flex" flexDirection="column" marginTop="32px" mx="15px">
                        <CollapsableAutocomplete
                            isCollapsed={isTalkWithSpinachSelected}
                            options={askSpinachOptions}
                            getOptionLabel={(option) => {
                                return option.name;
                            }}
                            value={askSpinachOptions.find((option) => option.id === askSpinachQuery) ?? null}
                            size="small"
                            style={{
                                marginBottom: '10px',
                                width: '100%',
                            }}
                            autoComplete
                            autoHighlight
                            onChange={onAskSpinachFeatureRequestSelection}
                            filterOptions={(options, params) => {
                                const filtered = filter(options, params as any);

                                // Suggest the creation of a new value
                                if (params.inputValue !== '' && defaultAskSpinachOption) {
                                    filtered.push({
                                        inputValue: params.inputValue,
                                        name: `Ask spinach "${params.inputValue}"`,
                                        default: true,
                                    });
                                    filtered.push({
                                        inputValue: params.inputValue,
                                        name: `Request a prompt that "${params.inputValue}"`,
                                        fetureRequest: true,
                                    });
                                }
                                return filtered as any;
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Ask Spinach about this meeting:"
                                    placeholder="What can we do to help?"
                                    InputProps={{
                                        ...params.InputProps,
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Star />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            )}
                        />

                        {isFreeForm && !isTalkWithSpinachSelected ? (
                            <TextareaAutosize
                                minRows={2}
                                style={{
                                    fontSize: '17px',
                                    padding: '7px',
                                    borderRadius: '5px',
                                    borderColor: lightTheme.neutrals.midnight,
                                    outline: 'none',
                                }}
                                value={askSpinachFreeform}
                                placeholder={askSpinachOptions.find((option) => option.id === askSpinachQuery)?.hint}
                                maxLength={
                                    askSpinachOptions.find((option) => option.id === askSpinachQuery)?.freeformLimit
                                }
                                onChange={(e) => {
                                    const value = e.target.value;
                                    setAskSpinachFreeform(value);
                                }}
                            />
                        ) : null}

                        <AskSpinachButtonRow
                            from={from}
                            shouldShowRealTimeAskSpinachComponents={shouldShowRealTimeAskSpinachComponents}
                            askSpinachOptions={askSpinachOptions}
                            askSpinachQuery={askSpinachQuery}
                            askSpinachResponse={askSpinachResponse}
                            askSpinachFreeform={askSpinachFreeform}
                            isFreeForm={!!isFreeForm}
                            botId={botId}
                            setAskSpinachResponse={setAskSpinachResponse}
                            isTalkWithSpinachSelected={isTalkWithSpinachSelected}
                            setIsTalkWithSpinachSelected={setIsTalkWithSpinachSelected}
                        />
                        {shouldShowRealTimeAskSpinachComponents ? (
                            <Collapse
                                style={{ width: 'unset' }}
                                mountOnEnter={true}
                                unmountOnExit={true}
                                in={isTalkWithSpinachSelected}
                            >
                                <Spacing factor={1 / 2} />
                                <RealTimeAskSpinach minimalTranscriptResponse={minimalTranscriptResponse} />
                            </Collapse>
                        ) : (
                            <></>
                        )}

                        <AskSpinachResponseSection
                            askSpinachResponse={askSpinachResponse}
                            askSpinachQuery={askSpinachQuery}
                        />
                    </Box>
                </Box>
            ) : null}
        </>
    );
};
