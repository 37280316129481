function createCopyListener(text: string, withoutHtml?: boolean) {
    return function listener(e: ClipboardEvent) {
        if (!withoutHtml) {
            e.clipboardData?.setData('text/html', text);
        }
        e.clipboardData?.setData('text/plain', text);
        e.preventDefault();
    };
}

/**
 *
 * @param text Text to be copied to clipboard
 * @param customId an element ID to append the copy scratch text area in case body is blocked by a modal or some such element
 */
export function copyTextToClipboard(text: string, customId?: string, withoutHtml?: boolean): void {
    const textArea = document.createElement('textarea');
    textArea.value = text;

    textArea.style.position = 'absolute';
    textArea.style.left = '-9999px';
    textArea.contentEditable = 'true';
    textArea.readOnly = true;

    if (customId) {
        document.getElementById(customId)!.appendChild(textArea);
    } else {
        document.body.appendChild(textArea);
    }

    textArea.focus();
    textArea.select();

    const listener = createCopyListener(text, withoutHtml);

    try {
        document.addEventListener('copy', listener);
        document.execCommand('copy');
        document.removeEventListener('copy', listener);
    } catch (_) {}

    if (customId) {
        document.getElementById(customId)!.removeChild(textArea);
    } else {
        document.body.removeChild(textArea);
    }
}

export function copyElementContent(elementId: string): void {
    // Get the element by its ID
    const element = document.getElementById(elementId);

    if (!element) {
        return;
    }

    // Create a range and select the element's contents
    const range = document.createRange();
    range.selectNodeContents(element);

    // Create a selection and add the range
    const selection = window.getSelection();
    if (!selection) {
        return;
    }
    selection.removeAllRanges();
    selection.addRange(range);

    document.execCommand('copy');

    // Clear the selection
    selection.removeAllRanges();
}
