import { CircularProgress, IconButton, InputAdornment, TextField, Typography, withStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { SearchOutlined } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import { useRef } from 'react';
import React from 'react';

import { ClientEventType } from '@spinach-shared/types';

import { useClickTracking } from '../../../../..';
import { lightTheme } from '../../../../styles';
import { minSearchTermLength } from './GlobalSearch';
import { MatchItem } from './MatchItem';
import { GlobalSearchUIProps } from './globalSearchTypes';
import { useSearchShortcuts } from './shortcuts';

//declare the const and add the material UI style
const CssTextField = withStyles({
    root: {
        '& .MuiInput-underline:after': {
            borderBottomColor: lightTheme.primary.green,
        },
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                borderColor: lightTheme.primary.green,
            },
        },
    },
})(TextField);

export const GlobalSearchUI = ({
    searchTerm,
    setSearchTerm,
    searchResults,
    handleResultClick,
    selectedResult,
    detailedMatches,
    handleMatchClick,
    hideRequestCallback,
    meetingCount,
    isSearching,
}: GlobalSearchUIProps) => {
    const inputRef = useRef<HTMLInputElement | null>(null);
    useSearchShortcuts({
        inputRef,
        searchResults,
        selectedResult,
        handleResultClick,
        hideRequestCallback,
        handleMatchClick,
    });
    const trackClick = useClickTracking();
    const isLoading = isSearching && searchTerm && searchTerm.length >= minSearchTermLength;
    return (
        <Box
            id="global-search-container"
            display="flex"
            position="fixed"
            flexDirection={'column'}
            style={{
                backgroundColor: 'white',
                padding: 0,
                top: 120,
                left: 100,
                right: 100,
                maxHeight: 'calc(100vh - 240px)',
                borderRadius: 5,
                zIndex: 100000,
                boxShadow: '0px 0px 5000px rgba(0, 0, 0, 0.4)',
                overflow: 'hidden',
            }}
        >
            {isLoading ? (
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    position="absolute"
                    top={35}
                    left={0}
                    right={0}
                    zIndex={9999}
                >
                    <>
                        <CircularProgress size="1rem" style={{ marginRight: '10px' }} />
                        <Typography variant="caption">Searching...</Typography>
                    </>
                </Box>
            ) : null}
            <Box display="flex" flexDirection="row" px={5} style={{ padding: '20px', alignItems: 'center' }}>
                <CssTextField
                    inputRef={(r) => {
                        inputRef.current = r;
                    }}
                    variant="standard"
                    value={searchTerm}
                    inputProps={{ style: { height: '40px' } }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchOutlined style={{ color: '#A0A0A0' }} />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={hideRequestCallback}>
                                    <CloseIcon style={{ color: '#A0A0A0' }} />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    style={{ width: '100%' }}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    autoFocus
                />
            </Box>
            {searchTerm && searchResults.length === 0 && !isSearching && searchTerm.length >= minSearchTermLength && (
                <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    style={{ height: '100%', padding: 20 }}
                >
                    <Typography variant="h6">No results found</Typography>
                </Box>
            )}
            {searchTerm && (selectedResult || !isLoading) && searchResults.length > 0 && (
                <Box display="flex" flexDirection="row" flex={1} style={{ overflowY: 'hidden', paddingTop: 0 }}>
                    <Box
                        width="40%"
                        display="flex"
                        flexDirection="column"
                        style={{ overflowY: 'scroll', paddingLeft: '40px' }}
                    >
                        <Box>
                            <Box
                                style={{
                                    fontSize: '18px',
                                    color: '#909090',
                                    borderBottom: '1px solid #e0e0e0',
                                    paddingBottom: '10px',
                                }}
                            >
                                Results from last {meetingCount} meetings
                            </Box>
                            {searchResults.map((result) => (
                                <Box
                                    key={result.botId}
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    onClick={() => {
                                        handleResultClick(result);
                                        trackClick(ClientEventType.AIDashboardClick, 'search result clicked', {
                                            numberOfOtherResults: searchResults.length,
                                            queryLength: searchTerm.length,
                                        });
                                    }}
                                    style={{
                                        padding: '10px 0',
                                        borderBottom: '1px solid #e0e0e0',
                                        cursor: 'pointer',
                                        color:
                                            selectedResult?.botId === result.botId
                                                ? lightTheme.primary.greenLight
                                                : 'inherit',
                                    }}
                                >
                                    <Box display="flex" flexDirection="column">
                                        <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                                            {result.meetingTitle} {moment(result.createdAt).format('YYYY/MM/DD')}
                                        </Typography>
                                        <Typography variant="body2" style={{ opacity: 0.8 }}>
                                            mentioned <b>{result.matches}</b> times (
                                            {moment(result.createdAt).fromNow()})
                                        </Typography>
                                    </Box>
                                    <Typography variant="body2" color="textSecondary"></Typography>
                                </Box>
                            ))}
                        </Box>
                    </Box>
                    <Box width="70%" display="flex" flexDirection="column" style={{ overflow: 'auto', padding: 20 }}>
                        {selectedResult && (
                            <Box>
                                {detailedMatches.map((match, index) => (
                                    <MatchItem
                                        key={index}
                                        match={match}
                                        handleMatchClick={handleMatchClick}
                                        detailedMatches={detailedMatches}
                                        selectedResult={selectedResult}
                                        searchTerm={searchTerm}
                                    />
                                ))}
                            </Box>
                        )}
                    </Box>
                </Box>
            )}
        </Box>
    );
};
