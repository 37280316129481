import { Box } from '@material-ui/core';
import { EmbeddedCheckout, EmbeddedCheckoutProvider } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { set } from 'lodash';
import lottie from 'lottie-web';
import moment from 'moment';
import pRetry from 'p-retry';
import { useEffect, useState } from 'react';
import { z } from 'zod';

import { PUBLIC_DOMAINS } from '@spinach-shared/constants';
import { BillingPlanSchema } from '@spinach-shared/schemas';
import {
    ClientEventType,
    FeatureToggle,
    SpinachAPIPath,
    StripeSessionStatus,
    WebUrlQuery,
} from '@spinach-shared/types';

import { getSpinachAPI, postCreateCheckoutSession } from '../../../../apis';
import { postCreateCustomerPortalSession } from '../../../../apis/postCreateCustomerPortalSession';
import celebrateLottieJSON from '../../../../assets/celebrate.json';
import { useDefaultUsageCycleDays, useExperienceTracking, useGlobalAiDashboard } from '../../../../hooks';
import { useGlobalAuthedUser, useStripeCustomerPortalEnablement, useStripePlans } from '../../../../hooks';
import { BodyRegularOnboard, HeaderThree, HeaderThreeOnboard } from '../../../../styles';
import { ClientLogger, URLUtil } from '../../../../utils';
import { Anchor, DropDown, LoadingSquares, Row, Spacing } from '../../../common';
import { AccountManagementSection } from './AccountManagementSection';
import { BillingPortalBanner } from './BillingPortalBanner';
import { FreePlanBanner, ProBanner } from './PlanBanner';

const apiKey = process.env.REACT_APP_STRIPE_PUBLIC_API_KEY as string;
async function initStripe() {
    return await loadStripe(apiKey);
}

const stripePromise = pRetry(initStripe, { retries: 3 });

export const PayingAccountDetails = () => {
    const [user] = useGlobalAuthedUser();
    const { setToastText } = useGlobalAiDashboard();
    const [isLoading, setLoading] = useState(true);
    const [data, setData] = useState<z.infer<typeof BillingPlanSchema> | null>(null);
    useEffect(() => {
        (async () => {
            try {
                const payingUserBillingData = BillingPlanSchema.parse(
                    await getSpinachAPI(SpinachAPIPath.UserBilling, { throwOnError: true })
                );
                setData(payingUserBillingData);
            } catch (e) {
                setToastText('Error fetching data. Please try again later.');
            } finally {
                setLoading(false);
            }
        })();
    }, [setToastText]);

    if (isLoading) {
        return <LoadingSquares />;
    }

    if (!data) {
        return <></>;
    }

    return (
        <Row>
            <BodyRegularOnboard>
                <Box mt={2}>
                    Current Cycle:{' '}
                    <b>
                        {moment(data.currentPeriodStart).format('LL')} - {moment(data.currentPeriodEnd).format('LL')}
                    </b>
                </Box>
                <Box>
                    <b>{user.metadata.teamSubscriptionPayerId ? 'Plan' : 'Account'}</b> meeting hours used:{' '}
                    <b>{(data.latestUsageForPeriodInMinutes / 60).toFixed(2)} hours</b>
                </Box>
                <Box>
                    <b>Your </b> meeting hours used:{' '}
                    <b>{(data.latestUsageForPeriodInMinutesForUser / 60).toFixed(2)} hours</b>
                </Box>
            </BodyRegularOnboard>
        </Row>
    );
};

export function AccountSection() {
    const [clientSecret, setClientSecret] = useState('');
    const [isStripeInitialized, setIsStripeInitialized] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [user] = useGlobalAuthedUser();
    const plansInfo = useStripePlans();
    const plans = plansInfo?.plans || [];
    const isConsumptionBased = !!plansInfo?.isConsumptionBased;
    const [selectedPlan, setSelectedPlan] = useState<string>(plans[0].planName);
    const track = useExperienceTracking();
    const [isNavigatingToCustomerPortalFromPlanBanner, setIsNavigatingToCustomerPortalFromPlanBanner] = useState(false);
    const [isNavigatingToCustomerPortalFromBillingBanner, setIsNavigatingToCustomerPortalFromBillingBanner] =
        useState(false);
    const { setToastText } = useGlobalAiDashboard();
    const defaultCycleDuration = useDefaultUsageCycleDays();

    // portal shows to anyone that has ever paid, regardless of current paid/free status
    const isCustomerPortalEnabled = useStripeCustomerPortalEnablement();
    const showCustomerPortal = !!user.stripeCustomerId && isCustomerPortalEnabled;

    // show the support message if the customer portal is not enabled and the user is a paying user
    // otherwise there's a support message in the customer portal banner
    const showSupportMessage = !isCustomerPortalEnabled && user.isPayingUser;

    // show the upgrade if the user does not have the customer portal and is a not a paying user
    const showUpgrade = (user.isOnLiveReverseTrial || !user.hasProFeatures) && !showCustomerPortal;
    const planName = user.hasProFeatures ? 'Pro' : 'Free';
    const daysLeftText = user.isOnManuallyManagedTrial ? '.' : ` for ${user.reverseTrialDaysLeft} days.`;
    let subtitle = user.isOnLiveReverseTrial ? (
        `You are trialing the Pro plan${daysLeftText}`
    ) : (
        <span>
            You are on the <b>{planName}</b> plan.
        </span>
    );
    if (user.isPersonal && !user.isOnLiveReverseTrial) {
        subtitle = 'Your trial is over, please upgrade to continue using Spinach';
    }

    async function navigateToCustomerPortalSession(stateSetter: (value: boolean) => void) {
        stateSetter(true);
        try {
            const response = await postCreateCustomerPortalSession();
            if (!!response?.sessionUrl) {
                URLUtil.openURL(response.sessionUrl);
            }
        } catch (e: any) {
            ClientLogger.error('Failed to get customer portal url', {
                errorMessage: e.message,
            });
            setToastText(
                <>
                    Oops, something went wrong, please try again later. If the problem persists, please contact support{' '}
                    <Anchor
                        id={'spinach_intercom'}
                        // dynamic id if we have no article
                        onClick={() => {
                            track(ClientEventType.AIDashboardClick, {
                                ClickedOn: `Help for Bad Billing Portal URL`,
                            });
                        }}
                    >
                        here
                    </Anchor>
                </>
            );
        } finally {
            stateSetter(false);
        }
    }

    useEffect(() => {
        async function createCheckoutSession() {
            setIsStripeInitialized(false);
            if (showUpgrade) {
                try {
                    const response = await postCreateCheckoutSession(selectedPlan);
                    if (!!response?.clientSecret) {
                        setClientSecret(response.clientSecret);
                    } else {
                        throw new Error('Failed to get client secret from response');
                    }
                } catch (e: any) {
                    ClientLogger.error('Failed to create checkout session', { errorMessage: e.message });
                    track(ClientEventType.AIDashboardActivity, {
                        Activity: 'Failed to Create Checkout Session',
                    });
                    setHasError(true);
                }
            }

            try {
                await stripePromise;
            } catch (e: any) {
                ClientLogger.error('Failed to load stripe', { errorMessage: e.message });
                track(ClientEventType.AIDashboardActivity, {
                    Activity: 'Failed to load stripe on account section',
                });
                setHasError(true);
                return;
            }

            setIsStripeInitialized(true);
        }

        createCheckoutSession();
    }, [showUpgrade, selectedPlan]);

    useEffect(() => {
        const container = document.querySelector('#banner-container');
        const urlParams = new URLSearchParams(window.location.search);
        const sessionStatus = urlParams.get(WebUrlQuery.StripeSessionStatus);
        if (!!container && sessionStatus === StripeSessionStatus.Complete) {
            const animation = lottie.loadAnimation({
                container,
                autoplay: false,
                loop: false,
                animationData: celebrateLottieJSON,
                rendererSettings: {
                    className: 'celebrate-pro-account-animation',
                },
            });
            animation.setSpeed(0.33);
            animation.play();
        }
    }, []);

    const shouldDisplayStarterCycleUsage = !!user.autoCappingCycleStartDate;

    return (
        <>
            <Spacing factor={1 / 2} />
            <Row>
                <HeaderThreeOnboard>Account</HeaderThreeOnboard>
            </Row>
            <Spacing />
            <Row>
                <HeaderThree>Plan</HeaderThree>
            </Row>
            <Spacing factor={1 / 2} />
            <BodyRegularOnboard>{subtitle}</BodyRegularOnboard>

            {!user.isPayingUser && user.isEnabledForUsageVisibility ? (
                <>
                    <Spacing factor={1 / 2} />
                    <Row>
                        {user.personalUsageHours ? (
                            <BodyRegularOnboard>
                                Your usage in the last {defaultCycleDuration} days:{' '}
                                <b>{user.personalUsageHours} hours</b>
                            </BodyRegularOnboard>
                        ) : null}
                    </Row>
                    <Row>
                        {!PUBLIC_DOMAINS.includes(user.rootDomain) && user.accountUsageHours ? (
                            <BodyRegularOnboard>
                                Your company&apos;s usage in the last {defaultCycleDuration} days:{' '}
                                <b>{user.accountUsageHours} hours</b>
                            </BodyRegularOnboard>
                        ) : null}
                    </Row>
                    {shouldDisplayStarterCycleUsage ? (
                        <>
                            <Row>
                                <BodyRegularOnboard>
                                    Your {user.isBusiness ? "company's " : ''} usage this cycle:{' '}
                                    <b>{user.usageHoursForCurrentLimitedCycle} hours</b>
                                </BodyRegularOnboard>
                            </Row>
                        </>
                    ) : null}
                </>
            ) : null}

            {user.isPayingUser && user.featureToggles[FeatureToggle.PaidAccountUsageDetails] ? (
                <PayingAccountDetails />
            ) : null}

            <Spacing factor={1 / 2} />
            {(!user.hasProFeatures && !user.isPersonal) || user.isOnLiveReverseTrial ? (
                <>
                    <Row>
                        {user.isOnLiveReverseTrial ? (
                            <ProBanner isNavigatingToCustomerPortal={isNavigatingToCustomerPortalFromPlanBanner} />
                        ) : (
                            <FreePlanBanner
                                isNavigatingToCustomerPortal={isNavigatingToCustomerPortalFromPlanBanner}
                                onRestartPlanClick={
                                    showCustomerPortal
                                        ? () => {
                                              track(ClientEventType.AIDashboardClick, {
                                                  ClickedOn: `Restart Your Plan`,
                                              });

                                              navigateToCustomerPortalSession(
                                                  setIsNavigatingToCustomerPortalFromPlanBanner
                                              );
                                          }
                                        : undefined
                                }
                            />
                        )}
                    </Row>
                    <Spacing />
                </>
            ) : null}

            {showCustomerPortal ? (
                <Row>
                    <BillingPortalBanner
                        onBillingPortalClick={() => {
                            track(ClientEventType.AIDashboardClick, {
                                ClickedOn: `Go To Billing Portal`,
                            });

                            navigateToCustomerPortalSession(setIsNavigatingToCustomerPortalFromBillingBanner);
                        }}
                        isNavigatingToCustomerPortal={isNavigatingToCustomerPortalFromBillingBanner}
                    />
                </Row>
            ) : null}
            {showSupportMessage ? (
                <Row>
                    <BodyRegularOnboard>
                        To cancel your subscription anytime, contact support{' '}
                        <Anchor
                            id={'spinach_intercom'}
                            // dynamic id if we have no article
                            onClick={() => {
                                track(ClientEventType.AIDashboardClick, {
                                    ClickedOn: `Cancel Subscription Help`,
                                });
                            }}
                        >
                            here
                        </Anchor>
                    </BodyRegularOnboard>
                </Row>
            ) : null}
            {showUpgrade ? (
                <div id="checkout">
                    <Row>
                        <HeaderThree>Upgrade</HeaderThree>
                    </Row>

                    {isConsumptionBased && plans.length > 1 ? (
                        <>
                            <Spacing factor={1 / 2} />
                            <Row
                                style={{
                                    alignItems: 'center',
                                }}
                            >
                                <BodyRegularOnboard
                                    style={{
                                        marginRight: '16px',
                                    }}
                                >
                                    Meeting hours per month:
                                </BodyRegularOnboard>
                                <DropDown
                                    title={''}
                                    values={plans.map((p) => ({ code: p.planName, label: p.planName }))}
                                    defaultValue={selectedPlan}
                                    selected={selectedPlan}
                                    handleSelection={(code) => {
                                        setSelectedPlan(code);
                                        track(ClientEventType.AIDashboardClick, {
                                            ClickedOn: 'Upgrade Plan Select',
                                            SelectedPlan: code,
                                        });
                                    }}
                                ></DropDown>
                            </Row>
                        </>
                    ) : null}

                    {user.talkToSalesUrl ? (
                        <>
                            <Spacing factor={1 / 2} />
                            <Row vCenter={true}>
                                Have questions?{' '}
                                <Anchor
                                    style={{ marginLeft: '5px' }}
                                    onClick={() => {
                                        track(ClientEventType.AIDashboardClick, {
                                            ClickedOn: 'Talk to Sales',
                                        });
                                        URLUtil.openURL(user.talkToSalesUrl);
                                    }}
                                >
                                    Talk to sales
                                </Anchor>
                            </Row>
                        </>
                    ) : null}

                    <Spacing />

                    {clientSecret && isStripeInitialized && showUpgrade ? (
                        <EmbeddedCheckoutProvider stripe={stripePromise} options={{ clientSecret }}>
                            <EmbeddedCheckout />
                        </EmbeddedCheckoutProvider>
                    ) : hasError ? (
                        <Row>
                            <BodyRegularOnboard>
                                Oops, something went wrong, please try again later. If the problem persists, please
                                contact support{' '}
                                <Anchor
                                    id={'spinach_intercom'}
                                    // dynamic id if we have no article
                                    onClick={() => {
                                        track(ClientEventType.AIDashboardClick, {
                                            ClickedOn: `Issue Loading Stripe Help`,
                                        });
                                    }}
                                >
                                    here
                                </Anchor>
                            </BodyRegularOnboard>
                        </Row>
                    ) : (
                        <LoadingSquares />
                    )}
                </div>
            ) : null}
            <Spacing />
            <AccountManagementSection />
            <Spacing />
        </>
    );
}
