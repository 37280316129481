import { SeriesTypePrefix } from '@spinach-shared/constants';
import { ClientUser } from '@spinach-shared/models';
import {
    FeatureFlagValue,
    FeatureToggle,
    IClientUser,
    KnowledgeBaseIntegration,
    ScribeEventStatus,
    ScribeMeetingType,
    ScribeMetadata,
    SeriesCounters,
    SeriesCreationSource,
    SeriesDestinationSettings,
    SeriesIntegrationSettings,
    SeriesMetadata,
    SeriesOutputChannel,
    SeriesOutputContent,
    SeriesOutputOptions,
    SeriesUserMetadata,
    SpinachCode,
    SpinachComponents,
    SpinachIntegration,
    StoredSeries,
    StoredSpinachSeriesMetadata,
    SummaryFeatureIntents,
    SummaryOutputSettings,
    UUID,
} from '@spinach-shared/types';

import { SeriesSchedule } from './schedule';

export function isDemoSeries(seriesId: string): boolean {
    if (seriesId.includes(SeriesTypePrefix.Demo)) {
        return true;
    } else {
        return false;
    }
}

export function getDailySubscribedEmails(storedSeries: StoredSeries | null): string[] {
    return (
        storedSeries?.outputOptions
            ?.filter(
                (option): option is SeriesOutputOptions =>
                    option.channel === SeriesOutputChannel.Email &&
                    option.content.includes(SeriesOutputContent.DailySummary)
            )
            .map((option) => option.to) ?? []
    );
}

export class StoredSpinachSeries {
    id: string;
    slug: SpinachCode;
    name: string;
    userMetadataList: SeriesUserMetadata[];
    createdBy: UUID;
    metadata: StoredSpinachSeriesMetadata;
    integrationSettings?: SeriesIntegrationSettings;
    userIntegrationSettings?: SeriesIntegrationSettings;
    enabledSummarySections?: SummaryFeatureIntents[];
    components: SpinachComponents[];
    outputOptions: SeriesOutputOptions[];
    counters: Record<SeriesCounters, number>;
    featureToggles?: Record<FeatureToggle, FeatureFlagValue>;
    icpId: string | undefined;
    createdAt?: Date;
    icpEmail: string | undefined;

    constructor(storedSeries: StoredSeries) {
        this.id = storedSeries.id;
        this.slug = storedSeries.slug;
        this.name = storedSeries.name;
        this.featureToggles = storedSeries.featureToggles;
        this.createdAt = storedSeries.createdAt;
        this.userMetadataList = storedSeries.userMetadataList as SeriesUserMetadata[];
        this.createdBy = storedSeries.createdBy;
        this.integrationSettings = storedSeries.integrationSettings;
        this.components = storedSeries.components;
        this.outputOptions = storedSeries.outputOptions;
        this.counters = storedSeries.counters ?? { [SeriesCounters.InVideoMeetingSlackConnectMessage]: 0 };
        this.icpId =
            storedSeries.icpUserId ?? storedSeries.metadata?.scribeMetadata?.inviterId ?? storedSeries.createdBy;
        this.icpEmail =
            this.userMetadataList.find((u) => u._id === this.icpId)?.email ??
            this.userMetadataList.find((u) => u._id === this.createdBy)?.email;

        this.enabledSummarySections = storedSeries.metadata?.scribeMetadata?.enabledSummarySections;
        this.metadata = {
            dateTimes: storedSeries.metadata?.dateTimes
                ? new SeriesSchedule(storedSeries.metadata.dateTimes)
                : undefined,
            reminderSettings: storedSeries.metadata?.reminderSettings,
            scribeMetadata: storedSeries.metadata?.scribeMetadata,
            creationSource: storedSeries.metadata?.creationSource,
            inMeetingEnabled: storedSeries.metadata?.inMeetingEnabled,
            forcedVideoAgentTopics: storedSeries.metadata?.forcedVideoAgentTopics,
        };
    }

    get isDemoSeries(): boolean {
        if (this.slug.includes(SeriesTypePrefix.Demo)) {
            return true;
        } else {
            return false;
        }
    }

    get isScribeSeriesVisible(): boolean {
        return (
            this.isScribeSeries &&
            !!this.scribeMetadata?.seriesTitle &&
            (this.scribeMetadata?.eventStatus === ScribeEventStatus.Active ||
                this.scribeMetadata?.eventStatus === undefined)
        );
    }

    get isScribeSeries(): boolean {
        return !!this.metadata?.scribeMetadata?.iCalUid;
    }

    get schedule(): SeriesSchedule | undefined {
        return this.metadata?.dateTimes;
    }

    get scribeMetadata(): ScribeMetadata | undefined {
        return this.metadata.scribeMetadata;
    }

    get iCalUid(): string | undefined {
        return this.metadata.scribeMetadata?.iCalUid;
    }

    get meetingTitle(): string | undefined {
        return this.metadata.scribeMetadata?.seriesTitle;
    }

    get dailySubscribedEmails(): string[] {
        return (
            this.outputOptions
                .filter(
                    (option): option is SeriesOutputOptions =>
                        option.channel === SeriesOutputChannel.Email &&
                        option.content.includes(SeriesOutputContent.DailySummary)
                )
                .map((option) => option.to) ?? []
        );
    }

    get meetingType(): ScribeMeetingType | undefined {
        return this.metadata.scribeMetadata?.meetingType;
    }

    get slackChannel(): Required<SeriesDestinationSettings> | undefined {
        const slackIntegration = this.slackSettings;
        const channels = slackIntegration?.destinationSettings?.filter(
            (d): d is Required<SeriesDestinationSettings> => !!d && !!d.channelName && !!d.channelId
        );

        if (channels && channels.length) {
            return channels[0];
        }

        return undefined;
    }

    get isEmailingIcpOnly(): boolean {
        return !!this.scribeMetadata?.isEmailingIcpOnly;
    }

    set isEmailingIcpOnly(isEnabled: boolean) {
        if (!this.scribeMetadata) {
            return;
        }
        this.scribeMetadata.isEmailingIcpOnly = isEnabled;
    }

    set outputLanguage(language: string) {
        if (!this.scribeMetadata) {
            return;
        }
        this.scribeMetadata.outputLanguage = language;
    }

    get isEditSummaryEnabled(): boolean {
        return !!this.scribeMetadata?.isHostEditing;
    }

    set isEditSummaryEnabled(isEnabled: boolean) {
        if (!this.scribeMetadata) {
            return;
        }
        this.scribeMetadata.isHostEditing = isEnabled;
    }

    get slackSettings(): SummaryOutputSettings | undefined {
        return this.getIntegrationSettings(SpinachIntegration.Slack);
    }

    get isStandupScribeSeries(): boolean {
        return this.metadata?.scribeMetadata?.scribeType === 'standup';
    }

    get userIds(): string[] {
        return this.userMetadataList.map((u) => u._id);
    }

    get isBotInvitedWithoutLink(): boolean {
        return (
            !this.metadata.scribeMetadata?.createdBotId &&
            this.metadata.creationSource === SeriesCreationSource.AiCalendarInvite
        );
    }

    get hasSomeBotId(): boolean {
        const someBotId = this.scribeMetadata?.createdBotId || this.scribeMetadata?.lastKnownBotId;
        return !!someBotId;
    }

    get isIssueBased(): boolean {
        return !!this.featureToggles?.[FeatureToggle.IssueBased];
    }

    get isGenericCombinedSummaryEnabled(): boolean {
        return this.scribeMetadata?.meetingType === ScribeMeetingType.Generic && !!this.metadata.inMeetingEnabled;
    }

    isEligibleForCombinedSummary(isGenericCombinedSummaryEnabled: boolean): boolean {
        const conditionalGenericCombined =
            isGenericCombinedSummaryEnabled && this.meetingType === ScribeMeetingType.Generic;
        const isInMeetingAvailable = this.meetingType === ScribeMeetingType.Standup || conditionalGenericCombined;

        return isInMeetingAvailable;
    }

    updateSpinachHost(user: IClientUser) {
        const clientUser = new ClientUser(user);
        this.icpId = clientUser.spinachUserId;
        this.icpEmail = clientUser.email;
        this.isEditSummaryEnabled = clientUser.isEnabledForEditSummary;
        this.isEmailingIcpOnly = clientUser.isEmailingHostOnly;
        if (clientUser.defaultOutputLanguage) {
            this.outputLanguage = clientUser.defaultOutputLanguage;
        }
    }

    getSummaryParentPageId(knowledgeBaseIntegration: KnowledgeBaseIntegration): string | number | undefined {
        return this.integrationSettings?.[knowledgeBaseIntegration]?.summaryParentPageId;
    }

    getIntegrationSettings<T extends keyof SeriesIntegrationSettings>(
        integrationSettings: T
    ): SeriesIntegrationSettings[T] | undefined {
        if (integrationSettings === SpinachIntegration.Slack || integrationSettings === 'summaryOutputSettings') {
            return (this.integrationSettings?.slack ??
                this.integrationSettings?.summaryOutputSettings?.[0]) as SeriesIntegrationSettings[T];
        }
        return this.integrationSettings?.[integrationSettings];
    }

    toJSON(): StoredSeries {
        return {
            id: this.id,
            slug: this.slug,
            name: this.name,
            userMetadataList: this.userMetadataList,
            createdBy: this.createdBy,
            metadata: this.getJSONMetadata(),
            integrationSettings: this.integrationSettings,
            components: this.components,
            outputOptions: this.outputOptions,
            counters: this.counters,
            icpUserId: this.icpId,
            featureToggles: this.featureToggles,
            createdAt: this.createdAt,
        };
    }

    private getJSONMetadata(): SeriesMetadata | undefined {
        let metadata: SeriesMetadata | undefined = undefined;

        if (this.metadata) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { dateTimes, ...rest } = this.metadata;

            metadata = {
                ...rest,
            };

            if (this.metadata.dateTimes) {
                metadata = {
                    ...metadata,
                    dateTimes: this.metadata.dateTimes.toJSON(),
                };
            }
        }

        return metadata;
    }
}

export function getTopicsLabel(isJustTopics: boolean): string {
    if (isJustTopics) {
        return 'Agenda';
    } else {
        return 'Parking Lot';
    }
}
