import { Slide, Tooltip } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Titles } from '@spinach-clients/constants';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { v4 } from 'uuid';

import {
    ClientEventType,
    ContextItemClickPayload,
    JiraIssueSelectionType,
    Sentiment,
    SpinachUpdateType,
    TypedUpdate,
} from '@spinach-shared/types';
import { getTicketSource } from '@spinach-shared/utils';

import { postExperienceEvent } from '../../apis';
import { ReactComponent as CheckIcon } from '../../assets/check-icon.svg';
import { ReactComponent as JiraLogo } from '../../assets/jira-logo.svg';
import {
    useExperienceTracking,
    useGlobalAuthedUser,
    useGlobalLiveSeries,
    useGlobalStoredSeries,
    useJiraEnablement,
    useSeriesReality,
} from '../../hooks';
import { BaseButton, lightTheme } from '../../styles';
import { Bullet, BulletMark, Column, Row } from '../common';
import { Notification } from '../stand-up';
import { BaseInput } from './BaseInput';
import { JiraInput } from './jira';

const TextContainer = styled.div`
    width: 100%;
`;

const HoveredRow = styled(Row)<{ isHovered?: boolean; isSubItem?: boolean }>`
    position: relative;
    background-color: ${(props) => (props.isHovered ? props.theme.neutrals.grayLight : 'none')};

    &:hover {
        background-color: ${(props) => (props.isSubItem ? props.theme.neutrals.grayDark : 'inherit')};
        cursor: default;
    }
`;

const BulletMarkNoMargin = styled(BulletMark)`
    margin-left: 4px;
`;

const BulletMarkNoPadding = styled(BulletMarkNoMargin)`
    padding-right: unset;
`;

const Stationary = styled.div`
    position: absolute;
    right: 0px;
    width: 100px;
    height: 20px;
    padding: 2px 4px;
    border-radius: 3px;
    border: solid;
    border-width: thin;
    border-color: ${(props) => props.theme.neutrals.midnight};
    background-color: ${(props) => props.theme.neutrals.white};
    box-shadow: 0px 2px 8px rgba(0, 69, 62, 0.32);
    z-index: 1500;
`;

const YTBCopyButton = styled(BaseButton)<{ width: number }>`
    border-radius: 3px;
    margin: 1px 4px 1px 4px;
    text-align: center;
    display: flex;
    align-content: center;
    min-width: 2px;
    width: ${(props) => props.width}%;
    background-color: ${(props) => props.theme.secondary.orangeDark};
    color: white;

    &:hover {
        box-shadow: ${(props) => (props.disabled ? 'none' : '0px 2px 8px rgba(0, 69, 62, 0.32)')};
        background-color: ${(props) => props.theme.tertiary.orangeDark};
    }
`;

type PreviousUpdateSectionInputCommonProps = {
    typedUpdate: TypedUpdate;
    onConfirmation?: (typedUpdate: TypedUpdate) => void;
    meetingId?: string;
};

type PreviousUpdateSectionInputProps = PreviousUpdateSectionInputCommonProps & {
    onTooltipClick?: (typedUpdate: TypedUpdate) => void;
    createUpdateEmitter: (typedUpdate: TypedUpdate) => (text: string) => void;
    bullet?: JSX.Element;
    jiraInteractive?: boolean;
};

export type PreviousUpdateSectionTooltipProps = PreviousUpdateSectionInputCommonProps & {
    onClick: (typedUpdate: TypedUpdate) => void;
    enableKeyDown?: boolean;
};

export const PreviousUpdateSectionTooltip = ({
    onClick,
    typedUpdate,
    onConfirmation,
    meetingId,
    enableKeyDown = true,
}: PreviousUpdateSectionTooltipProps): JSX.Element => {
    const [liveSeries] = useGlobalLiveSeries();
    const [user] = useGlobalAuthedUser();
    const isJiraEnabled = useJiraEnablement();

    const enabledReservedLists = liveSeries.enabledReservedRoundtableLists;

    const onKeyDown = (e: KeyboardEvent) => {
        if (liveSeries.isDemo) {
            return;
        }
        if (e.code === 'Digit1' || e.code === 'KeyY') {
            onButtonClick(typedUpdate, SpinachUpdateType.Yesterday);
        }
        if (e.code === 'Digit2' || e.code === 'KeyT') {
            onButtonClick(typedUpdate, SpinachUpdateType.Today);
        }
        if (e.code === 'Digit3' || e.code === 'KeyB') {
            onButtonClick(typedUpdate, SpinachUpdateType.Challenge);
        }
    };

    const onButtonClick = async (
        typedUpdate: TypedUpdate,
        updateType: SpinachUpdateType,
        keyboardEvent: boolean = false
    ) => {
        if (!liveSeries.isComponentEnabled(updateType)) {
            return;
        }

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { subItems, resolvers, ...typedUpdateWithoutSubItem } = typedUpdate;

        const { jiraData, ticketData } = typedUpdateWithoutSubItem;
        const issueData = jiraData || ticketData?.ticket;
        if (onConfirmation) {
            /** @TODO Should asanaData be here? */
            if (issueData && isJiraEnabled) {
                onConfirmation({
                    ...typedUpdateWithoutSubItem,
                    ticketData: ticketData ? { ...ticketData, sentiment: undefined } : undefined,
                    isRolledOver: true,
                });
            } else {
                onConfirmation({
                    ...typedUpdateWithoutSubItem,
                    ticketData: ticketData ? { ...ticketData, sentiment: undefined } : undefined,
                    isRolledOver: true,
                });
            }
        }
        // I wonder if we should have a `correlationId` or `referenceId` to tie it to the
        // typedUpdate this came from so we can correlate them together
        onClick({
            ...typedUpdateWithoutSubItem,
            ticketData: ticketData ? { ...ticketData, sentiment: undefined } : undefined,
            creatorId: user.spinachUserId,
            id: v4(),
            updateType,
            isRolledOver: false,
        });
        const payload: ContextItemClickPayload = {
            ...user.toUserIdentityPayload(),
            IsResolverItem: Boolean(typedUpdate.creatorId && typedUpdate.creatorId !== user.spinachUserId),
            TicketSource: getTicketSource(typedUpdate),
            NumberOfSubItems: typedUpdate.subItems?.length ?? 0,
            MeetingID: meetingId,
            YTBUpdateType: typedUpdate.updateType,
            YTBItemAddedTo: updateType,
            KeyboardEvent: keyboardEvent,
            UpdateId: typedUpdate.id,
        };
        await postExperienceEvent({ eventType: ClientEventType.ContextItemClick, payload });
    };

    useEffect(() => {
        if (enableKeyDown) {
            document.addEventListener('keydown', onKeyDown);
            return () => {
                document.removeEventListener('keydown', onKeyDown);
            };
        }
    }, [enableKeyDown]);

    const buttonWidthPercentage = 100 / enabledReservedLists.length;

    return (
        <Stationary>
            <Row>
                {liveSeries.isComponentEnabled(SpinachUpdateType.Yesterday) ? (
                    <Tooltip title={Titles.CopyToYesterday} placement={'top'} arrow>
                        <YTBCopyButton
                            width={buttonWidthPercentage}
                            onClick={() => onButtonClick(typedUpdate, SpinachUpdateType.Yesterday)}
                        >
                            <TextContainer>Y</TextContainer>
                        </YTBCopyButton>
                    </Tooltip>
                ) : (
                    <></>
                )}

                {liveSeries.isComponentEnabled(SpinachUpdateType.Today) ? (
                    <Tooltip title={Titles.CopyToToday} placement={'top'} arrow>
                        <YTBCopyButton
                            width={buttonWidthPercentage}
                            onClick={() => onButtonClick(typedUpdate, SpinachUpdateType.Today)}
                        >
                            <TextContainer>T</TextContainer>
                        </YTBCopyButton>
                    </Tooltip>
                ) : (
                    <></>
                )}

                {liveSeries.isComponentEnabled(SpinachUpdateType.Challenge) ? (
                    <Tooltip title={Titles.CopyToBlockers} placement={'top'} arrow>
                        <YTBCopyButton
                            width={buttonWidthPercentage}
                            onClick={() => onButtonClick(typedUpdate, SpinachUpdateType.Challenge)}
                        >
                            <TextContainer>B</TextContainer>
                        </YTBCopyButton>
                    </Tooltip>
                ) : (
                    <></>
                )}
            </Row>
        </Stationary>
    );
};

function TransitionLeft(props: TransitionProps) {
    return <Slide {...props} direction="left" />;
}

export function PreviousUpdateSectionInput({
    createUpdateEmitter,
    meetingId,
    onConfirmation,
    onTooltipClick,
    bullet,
    typedUpdate,
}: PreviousUpdateSectionInputProps): JSX.Element {
    const [user] = useGlobalAuthedUser();
    const track = useExperienceTracking();
    const [series] = useGlobalStoredSeries();
    // eslint-disable-next-line prefer-const
    let [isHovered, setIsHovered] = useState(false);
    const [previousUpdateNotificationMessage, setPreviousUpdateNotificationMessage] = useState<null | string>(null);
    const [isRolledOver, setIsRolledOver] = useState(typedUpdate.isRolledOver);
    const isJiraEnabled = useJiraEnablement();
    const { isDemoSeries } = useSeriesReality();

    isHovered = isHovered || isDemoSeries;

    /** @TODO Should asanaData be here? */
    const issueData = typedUpdate.jiraData || typedUpdate.ticketData?.ticket;

    const onClick = (typedUpdate: TypedUpdate) => {
        onTooltipClick?.(typedUpdate);
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { subItems, ...typedUpdateWithoutSubItems } = typedUpdate;
        const { jiraData, ticketData } = typedUpdateWithoutSubItems;
        const typedUpdateIssueData = jiraData || ticketData?.ticket;

        setPreviousUpdateNotificationMessage(
            `Added to ${typedUpdate.updateType === SpinachUpdateType.Challenge ? 'Blocker' : typedUpdate.updateType}`
        );

        const emitTypedUpdateSave = createUpdateEmitter({
            ...typedUpdateWithoutSubItems,
            creatorId: user.spinachUserId,
            ticketData: ticketData
                ? { ...ticketData, sentiment: isDemoSeries ? Sentiment.Good : undefined }
                : undefined,
        });
        emitTypedUpdateSave(typedUpdate.text);
        setIsRolledOver(true);
        if (typedUpdateIssueData) {
            const payload = {
                SeriesId: series.slug,
                FeatureToggles: user.featureToggles,
                SelectionType: JiraIssueSelectionType.RolledOver,
            };
            track(ClientEventType.JiraIssueSelected, payload);
        }
    };

    return (
        <Row onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
            <HoveredRow isHovered={isHovered}>
                {bullet ? (
                    bullet
                ) : /** @NOTE Do not show Jira logo if JMAR is enabled */
                issueData && isJiraEnabled ? (
                    <JiraLogo style={{ alignSelf: 'center', width: '16px', height: '16px' }} />
                ) : typedUpdate.isRolledOver || isRolledOver ? (
                    <BulletMarkNoPadding>
                        <CheckIcon stroke={'black'} />
                    </BulletMarkNoPadding>
                ) : (
                    <BulletMarkNoMargin>
                        <Bullet />
                    </BulletMarkNoMargin>
                )}
                <Notification
                    isOpen={Boolean(previousUpdateNotificationMessage)}
                    onClose={() => setPreviousUpdateNotificationMessage(null)}
                    message={previousUpdateNotificationMessage}
                    duration={1200}
                    icon={
                        <CheckCircleIcon
                            style={{ color: lightTheme.neutrals.white, height: '20px' }}
                            htmlColor={lightTheme.neutrals.white}
                        />
                    }
                    containerStyle={{
                        position: 'absolute',
                        top: 'unset',
                        bottom: 'unset',
                        width: 'calc(100% - 100px)',
                        left: 'unset',
                        right: 'unset',
                        transform: 'unset',
                    }}
                    contentStyle={{
                        width: '100%',
                        height: '23px',
                        borderRadius: '3px',
                        border: 'solid',
                        borderWidth: 'thin',
                    }}
                    messageStyle={{
                        padding: 'unset',
                    }}
                    transitionComponent={TransitionLeft}
                />
                <Column>
                    {issueData && isJiraEnabled ? (
                        <JiraInput issueData={issueData} interactive={false} />
                    ) : (
                        <BaseInput typedUpdate={typedUpdate} disabled={true} />
                    )}
                </Column>
                {isHovered ? (
                    <PreviousUpdateSectionTooltip
                        onClick={onClick}
                        onConfirmation={onConfirmation}
                        meetingId={meetingId}
                        typedUpdate={typedUpdate}
                    />
                ) : (
                    <></>
                )}
            </HoveredRow>
        </Row>
    );
}
