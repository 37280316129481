import { ClientEventType } from '@spinach-shared/types';

import { useGlobalAuthedUser, useLandingAnalytic, useProAiPlanFeatureList } from '../../../hooks';
import { BodyBigOnboard, BodySmallOnboard, HeaderThreeOnboard } from '../../../styles';
import { Column, Row, SlidingColumn, Spacing } from '../../common';
import { ScrollView } from '../../series';
import { PrimaryButton } from '../../stand-up';
import { Stationary, StepProps } from './common';

export function WelcomeToReverseTrial({ direction, onSubmit, loadingMessage }: StepProps): JSX.Element {
    const [user] = useGlobalAuthedUser();
    const proFeatures = useProAiPlanFeatureList();
    useLandingAnalytic(ClientEventType.UserViewedWelcomeToReverseTrialStep);
    const header = user.isOnManuallyManagedTrial
        ? 'Your team is trialing Spinach Pro'
        : `Your team has ${user.reverseTrialDaysLeft} days of Spinach Pro`;
    return (
        <Stationary>
            <HeaderThreeOnboard>Nice to meet you {user.firstName || ''} 👋</HeaderThreeOnboard>
            <ScrollView sidePadding={0} stretch>
                <SlidingColumn centered direction={direction}>
                    <Spacing />
                    <BodyBigOnboard style={{ fontWeight: 600, width: '100%', textAlign: 'center' }}>
                        {header}
                    </BodyBigOnboard>
                    <Spacing factor={1 / 2} />
                    <Column style={{ width: '80%' }}>
                        <ul>
                            {proFeatures.map((feature, i) => (
                                <li key={i}>
                                    <Row vCenter>
                                        <BodyBigOnboard>{feature}</BodyBigOnboard>
                                    </Row>
                                    <Spacing factor={1 / 2} />
                                </li>
                            ))}
                        </ul>
                    </Column>

                    <Spacing factor={2} />
                    <Row centered>
                        <PrimaryButton
                            title={'Got it!'}
                            onClick={onSubmit}
                            isLoading={!!loadingMessage}
                            loadingText={loadingMessage}
                        />
                    </Row>
                    <Spacing factor={1 / 2} />
                    <Row vCenter centered>
                        <BodySmallOnboard>
                            <b>What happens when our Pro trial ends?</b>
                        </BodySmallOnboard>
                    </Row>
                    <Row vCenter centered>
                        <BodySmallOnboard>
                            Don&apos;t worry. You can still use Spinach free with limited features.
                        </BodySmallOnboard>
                    </Row>
                </SlidingColumn>
            </ScrollView>
        </Stationary>
    );
}
