import { Box } from '@material-ui/core';

export const AboutToMatchIndicator = ({ aboutToMatch, unknown }: { aboutToMatch: boolean; unknown: boolean }) => (
    <>
        {aboutToMatch ? (
            <Box
                style={{
                    marginTop: '0px',
                    textAlign: 'center',
                    fontSize: '72px',
                    fontWeight: 600,
                    color: 'white',
                    position: 'fixed',
                    zIndex: 150,
                }}
            >
                ...
            </Box>
        ) : undefined}
        {unknown ? (
            <Box
                style={{
                    marginTop: '38px',
                    textAlign: 'center',
                    fontSize: '40px',
                    fontWeight: 600,
                    color: 'white',
                    position: 'fixed',
                    zIndex: 150,
                }}
            >
                ???
            </Box>
        ) : undefined}
    </>
);
