import { useCallback } from 'react';

import { getCommandContents } from '@spinach-shared/models';
import { ClientEventType, PostVideoAgentChatMessageRequest } from '@spinach-shared/types';

import { AGENT_SAYINGS, patchVideoAgentSession, postVideoAgentChatMessage } from '../../../..';
import { AgentCommandType, SayFunction } from '../../../types/agent';
import { useActivityTracking } from '../../useExperienceTracking';
import { useGlobalVideoAgent } from '../useGlobalVideoAgent';

export function isStartAgendaCommand(command: string): boolean {
    const startAgendaCommands = [
        'startagenda',
        'start',
        'lets go',
        'start agenda',
        '/agenda start',
        '/spinach start',
        'spinach start',
    ];
    const { commandMatched: isStartAgendaTrigger } = getCommandContents(command, startAgendaCommands);

    return !!isStartAgendaTrigger;
}

export function useStartAgenda(say: SayFunction) {
    const {
        state: { session },
        setSession,
    } = useGlobalVideoAgent();
    const trackActivity = useActivityTracking();

    // TODO pass in voice or chat trigger source
    return useCallback(
        async (triggerType = AgentCommandType.Voice) => {
            if (!session) {
                return;
            }

            const updatedSession = session.withStartedAgenda();

            setSession(updatedSession);

            trackActivity(ClientEventType.VideoAgentActivity, 'Start Agenda', {
                TriggeredBy: triggerType, // TODO update voice or chat trigger source
                When: session.currentTopic?.id ? 'After Agenda Started' : 'Before Agenda Started',
                ...session?.analyticsPayload,
            });

            if (triggerType === AgentCommandType.Voice || session.isChatCommandAudioOutputEnabled) {
                if (session?.currentTopic) {
                    say(AGENT_SAYINGS.AgendaAlreadyStarted, false, { cacheRemotely: true });
                } else {
                    say(AGENT_SAYINGS.StartAgenda, false, { cacheRemotely: true });
                }
            }

            let chatPayload: PostVideoAgentChatMessageRequest | null = null;
            if (updatedSession.justStartedAgendaForFirstTime) {
                const agendaOverview = `Here is the current agenda:
${updatedSession.agenda.topics.map((topic, i) => `${i + 1}. ${topic.title}`).join(`\n`)}`;
                chatPayload = {
                    message: agendaOverview,
                    botId: updatedSession.botId,
                    options: {
                        oncePerMeetingProtectionKey: 'video-agent-start-agenda',
                    },
                };
            }

            await Promise.all([
                patchVideoAgentSession(updatedSession.toJSON()),
                chatPayload ? postVideoAgentChatMessage(chatPayload) : undefined,
            ]);
        },
        [session, setSession, trackActivity, say]
    );
}
