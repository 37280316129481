import { CircularProgress, Modal, TextField } from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { useEffect, useMemo, useState } from 'react';

import { AiHistoryUserAccessKind, ClientEventType } from '@spinach-shared/types';
import { ViralityLandingPageV1Props, getSummaryDeepLink, isEmailValid } from '@spinach-shared/utils';

import {
    BodyRegularOnboard,
    BodySubtitle,
    Column,
    ErrorBodySubtitle,
    GlobalModal,
    GlobalModalMetadataType,
    ListItemValue,
    Notification,
    PrimaryButton,
    ResponsiveModalTitle,
    Row,
    ScrollArea,
    Spacing,
    SpinachModalContent,
    copyTextToClipboard,
    lightTheme,
    useExperienceTracking,
    useGlobalAiDashboard,
    useGlobalAuthedUser,
    useGlobalModal,
    useGlobalModalState,
    useSpinachInputStyles,
    withAccessibleSubmitProps,
    withContentMasking,
} from '../../../..';
import { patchGiveMeetingNotesAccess } from '../../../apis/postGiveMeetingNotesAccess';
import { ItemList } from '../ItemList';

export function ShareAiHistoryModal(): JSX.Element {
    const globalModalState = useGlobalModalState();
    const [user] = useGlobalAuthedUser();
    const [, setGlobalModal] = useGlobalModal();
    const { setToastText } = useGlobalAiDashboard();
    const [isLoading, setIsLoading] = useState(false);
    const [isLinkCopied, setIsLinkCopied] = useState(false);
    const [indexBeingRemoved, setIndexBeingRemoved] = useState<number | null>(null);

    const track = useExperienceTracking();

    let usersWithHistoryAccess: { email: string; kind: AiHistoryUserAccessKind }[] = useMemo(() => [], []);
    let botId: string;
    let seriesId: string;
    let meetingTitle: string | undefined;
    if (globalModalState.metadata?.metadataType === GlobalModalMetadataType.ShareAiHistory) {
        usersWithHistoryAccess = globalModalState.metadata.usersWithHistoryAccess;
        botId = globalModalState.metadata.botId;
        seriesId = globalModalState.metadata.seriesId;
        meetingTitle = globalModalState.metadata.meetingTitle;
    }

    const [userMetadataList, setUserMetadatList] = useState(usersWithHistoryAccess);
    const [numberOfUsersAdded, setNumberOfUsersAdded] = useState(0);

    const [newEmailInvite, setNewEmailInvite] = useState('');

    const classes = useSpinachInputStyles({ value: newEmailInvite });

    useEffect(() => {
        setUserMetadatList(usersWithHistoryAccess);
    }, [usersWithHistoryAccess]);

    if (globalModalState.metadata?.metadataType !== GlobalModalMetadataType.ShareAiHistory) {
        return <></>;
    }

    const newEmailInvites = newEmailInvite
        .split(',')
        .map((email) => email.trim())
        .filter((email) => !!email);

    const historyHasUserWithSameEmail = !!userMetadataList.find((s) => newEmailInvites.includes(s.email));

    const lengthError =
        newEmailInvites.length + userMetadataList.length > 50 ? 'You can only add 50 users to a series' : '';

    const error = historyHasUserWithSameEmail
        ? 'User is already added'
        : newEmailInvites.some((newEmail) => !isEmailValid(newEmail)) && !!newEmailInvite
        ? 'Invalid email'
        : '';

    const closeModal = () => {
        track(ClientEventType.AIDashboardClick, {
            ClickedOn: 'Close AiHistory Invite Modal',
            BotId: botId,
            NumberOfUsersAdded: numberOfUsersAdded,
        });
        setGlobalModal(null, {});
    };

    const onSubmitInvite = async () => {
        try {
            track(ClientEventType.AIDashboardClick, {
                ClickedOn: 'Submit AiHistory Email Invite',
                BotId: botId,
                NewEmailInvites: newEmailInvites.join(', '),
                UsersWithExistingHistoryAccess: usersWithHistoryAccess.map((u) => u.email).join(', '),
            });
            setIsLoading(true);
            const updatedAiHistory = await patchGiveMeetingNotesAccess(botId, [
                ...userMetadataList,
                ...newEmailInvites.map((e) => ({ email: e, kind: AiHistoryUserAccessKind.Shared })),
            ]);
            setNumberOfUsersAdded(numberOfUsersAdded + newEmailInvites.length);

            if (updatedAiHistory) {
                setUserMetadatList(updatedAiHistory.usersWithHistoryAccess);
            }

            setNewEmailInvite('');
        } catch (e) {
            setToastText('Only people on the original meeting invite can share.');
        } finally {
            setIsLoading(false);
        }
    };

    const onRemoveUserClick = async (email: string) => {
        try {
            track(ClientEventType.AIDashboardClick, {
                ClickedOn: 'Remove User From AIHistory Invite',
                BotId: botId,
                EmailToRemove: email,
                UsersWithExistingHistoryAccess: usersWithHistoryAccess.map((u) => u.email).join(', '),
                NumberOfExistingUsersAttendedMeeting: usersWithHistoryAccess.reduce(
                    (acc, curr) => (curr.kind === AiHistoryUserAccessKind.Attended ? (acc += 1) : acc),
                    0
                ),
                NumberOfExistingUsersInvitedToHistory: usersWithHistoryAccess.reduce(
                    (acc, curr) => (curr.kind === AiHistoryUserAccessKind.Shared ? (acc += 1) : acc),
                    0
                ),
            });
            const updatedAiHistory = await patchGiveMeetingNotesAccess(
                botId,
                userMetadataList.filter((userMeta) => userMeta.email !== email)
            );

            if (updatedAiHistory) {
                setUserMetadatList(updatedAiHistory.usersWithHistoryAccess);
            }
        } catch (e) {
            setToastText('Failed to remove user');
        } finally {
            setTimeout(() => {
                setIndexBeingRemoved(null);
            }, 200);
        }
    };

    const listItemValues: ListItemValue<string, JSX.Element>[] = userMetadataList.map((userMeta, index) => {
        const isThisUser = user.email === userMeta.email;
        return {
            code: userMeta.email,
            label: (
                <Row style={{ width: 'fit-content' }} vCenter>
                    <BodyRegularOnboard>
                        {userMeta.email} {isThisUser ? '(You)' : ''}
                    </BodyRegularOnboard>
                    <Spacing horizontal factor={1 / 3} />
                    {isThisUser ? null : userMeta.kind === AiHistoryUserAccessKind.Attended ? (
                        <BodySubtitle style={{ color: lightTheme.primary.greenLight }}>attended</BodySubtitle>
                    ) : (
                        <BodySubtitle style={{ color: lightTheme.primary.greenLight }}>invited</BodySubtitle>
                    )}
                </Row>
            ),
            postContent:
                !isThisUser && userMeta.kind !== AiHistoryUserAccessKind.Attended ? (
                    indexBeingRemoved === index ? (
                        <CircularProgress size={'30px'} style={{ color: lightTheme.primary.orangeDark }} />
                    ) : (
                        <span
                            key={userMeta.email}
                            style={{
                                cursor: 'pointer',
                                position: 'relative',
                                display: 'flex',
                                alignItems: 'center',
                            }}
                            {...withAccessibleSubmitProps(() => {
                                setIndexBeingRemoved(index);
                                onRemoveUserClick(userMeta.email);
                            })}
                        >
                            <HighlightOffIcon htmlColor="gray" />
                        </span>
                    )
                ) : null,
        };
    });

    if (globalModalState.modal !== GlobalModal.ShareAiHistory) {
        return <></>;
    }
    return (
        <Modal open={globalModalState.modal === GlobalModal.ShareAiHistory} onClose={closeModal}>
            <SpinachModalContent onClose={closeModal} style={{ overflow: 'hidden', minHeight: '300px' }}>
                <Column centered>
                    <Row centered>
                        <ResponsiveModalTitle>Share</ResponsiveModalTitle>
                    </Row>
                    <Spacing factor={1 / 3} />
                    <Row centered>
                        <BodyRegularOnboard style={{ fontSize: '16px', textAlign: 'center', fontWeight: 500 }}>
                            Add users below to enable them to access the meeting summary, transcript and video.
                        </BodyRegularOnboard>
                    </Row>

                    <Spacing />

                    <Row style={{ flex: 'unset' }}>
                        <TextField
                            InputProps={{ classes: { root: classes.base } }}
                            {...withContentMasking(classes.root)}
                            fullWidth
                            placeholder="Add by email"
                            value={newEmailInvite}
                            onKeyDown={async (e) => {
                                if (e.key === 'Enter') {
                                    await onSubmitInvite();
                                }
                            }}
                            onChange={(e) => {
                                setNewEmailInvite(e.target.value.toLowerCase());
                            }}
                        />

                        <PrimaryButton
                            title="Add"
                            isLoading={isLoading}
                            loadingText={''}
                            onClick={onSubmitInvite}
                            disabled={!!error || !!lengthError || !newEmailInvite.trim()}
                        />
                    </Row>
                    <ErrorBodySubtitle>{lengthError || error}</ErrorBodySubtitle>

                    <Spacing factor={1 / 3} />
                    <Row>
                        <PrimaryButton
                            title="Copy Link"
                            onClick={() => {
                                let vProps: ViralityLandingPageV1Props | undefined;
                                if (meetingTitle) {
                                    vProps = { hasLoggedIn: false, meetingTitle };
                                }
                                copyTextToClipboard(getSummaryDeepLink({ botId, seriesId, vProps }), undefined, true);
                                track(ClientEventType.AIDashboardClick, {
                                    ClickedOn: 'Copy Share Link',
                                    BotId: botId,
                                    SeriesId: seriesId,
                                });
                                setIsLinkCopied(true);
                            }}
                        />
                        <Spacing horizontal />
                        <BodySubtitle style={{ color: '#707070', alignItems: 'center', display: 'flex' }}>
                            Note: Only people in the list can access the summary.
                        </BodySubtitle>
                    </Row>

                    <Spacing />

                    <Row centered>
                        <BodyRegularOnboard style={{ fontSize: '16px', textAlign: 'center', fontWeight: 500 }}>
                            People with access to this summary
                        </BodyRegularOnboard>
                    </Row>
                    <ScrollArea style={{ height: '30vh', maxHeight: '250px' }} sidePadding={0}>
                        <ItemList style={{ borderBottom: '1px solid lightgray' }} values={listItemValues} />
                    </ScrollArea>
                    <Spacing />

                    <Notification
                        containerStyle={{ bottom: '-10px', position: 'relative' }}
                        isOpen={isLinkCopied}
                        message="Link Copied!"
                        onClose={() => setIsLinkCopied(false)}
                    />
                </Column>
            </SpinachModalContent>
        </Modal>
    );
}
