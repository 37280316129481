import axios from 'axios';

import { ClientVideoAgentSessionJSON, PatchVideoAgentSessionRequest } from '@spinach-shared/types';
import { getDefaultRequestHeaders, isLocalStage } from '@spinach-shared/utils';

import { getAgentSearchParams } from '../../..';
import { mockBotId } from '../../constants';
import { getHeaderOpts } from '../../utils/api';

export async function patchVideoAgentSession(
    updatedSession: ClientVideoAgentSessionJSON
): Promise<ClientVideoAgentSessionJSON | undefined> {
    const { scribeServer, token } = getAgentSearchParams();

    const payload: PatchVideoAgentSessionRequest = {
        updatedSession,
    };

    if (updatedSession.botId === mockBotId && isLocalStage()) {
        return;
    }

    const response = await axios.patch(`${scribeServer}/video-agent/session`, payload, {
        headers: {
            ...getDefaultRequestHeaders(getHeaderOpts()),
            'Content-Type': 'application/json',
            'Cache-Control': 'no-store',
            authorization: token,
        },
    });

    return response.data;
}
