import styled from 'styled-components';

import { ClientUser } from '@spinach-shared/models';
import { ClientEventType, SpinachAPIPath, WebUrlQuery } from '@spinach-shared/types';

import {
    useExperienceTracking,
    useGlobalAuthedUser,
    useIntegrationDetection,
    useLandingAnalytic,
    useWindowSize,
} from '../../../../..';
import SpinachProofSrc from '../../../../assets/calendar-v2-proof.png';
import SpinachReviewSrc from '../../../../assets/calendar-v2-review.png';
import { ReactComponent as GreenCheckIcon } from '../../../../assets/green-circle-check.svg';
import { ReactComponent as SpinachLogo } from '../../../../assets/logo-spinachio.svg';
import SecurityBadges from '../../../../assets/security-badges.png';
import { BodyRegularOnboard, ButtonSize, HeaderThreeOnboard } from '../../../../styles';
import { URLUtil } from '../../../../utils';
import { Column, Row, Spacing } from '../../../common';
import { GoogleLoginComponent } from '../../../common/GoogleLoginComponent';
import { ScrollView } from '../../../series';
import SecondaryButton from '../../../stand-up/SecondaryButton';
import { StepProps } from '../common';

export type CalendarPermissionV2StepProps = StepProps & {
    onBack: () => void;
    onOptForManual: () => void;
    onProgressToAddEventToMeeting: (user: ClientUser) => void;
};

const HeaderThree = styled(HeaderThreeOnboard)`
    position: relative;
    .celebrate-finish-animation {
        position: absolute;
        height: 400px !important;
        top: -200%;
        left: 0;
        right: 0;
        margin: auto;
        pointer-events: none;
    }
`;

export function CalendarPermissionV2Step({
    direction,
    onOptForManual,
    onProgressToAddEventToMeeting,
}: CalendarPermissionV2StepProps): JSX.Element {
    useLandingAnalytic(ClientEventType.UserViewedCalendarPermissionsStep);

    const track = useExperienceTracking();
    const { width, isDesktopView } = useWindowSize();
    const [user] = useGlobalAuthedUser();
    const startDetection = useIntegrationDetection(onProgressToAddEventToMeeting);

    const benefits = [
        'Join meetings in one click',
        'Receive your own summaries',
        'Concise action items and key decisions',
        'Video recordings and transcripts available',
    ];

    return (
        <Row style={{ height: '100%' }}>
            <Column vCentered={true} centered={true} style={{ flex: 1, margin: '20px', width: 'calc(100% - 40px)' }}>
                <Row>
                    <SpinachLogo
                        style={{
                            height: isDesktopView ? '55px' : '25px',
                        }}
                    />
                </Row>
                <Column style={{ maxWidth: '600px', height: 'calc(100% - 55px)' }}>
                    <ScrollView sidePadding={0}>
                        <HeaderThree id="finish-onb-header">
                            Welcome! Let's connect your calendar to get started.
                        </HeaderThree>

                        <Spacing factor={1 / 2} />
                        <BodyRegularOnboard>Spinach will always ask before taking any action.</BodyRegularOnboard>

                        <Spacing factor={1 / 2} />

                        <Column style={{ marginTop: '15px', maxWidth: '400px', marginLeft: '20px' }}>
                            {benefits.map((benefit) => (
                                <Row key={benefit} style={{ marginBottom: '15px' }}>
                                    <GreenCheckIcon style={{ width: '18px', height: '18px', marginRight: '16px' }} />
                                    <BodyRegularOnboard style={{ fontSize: '18px', fontWeight: 600 }}>
                                        {benefit}
                                    </BodyRegularOnboard>
                                </Row>
                            ))}
                        </Column>

                        <Spacing />

                        <Row centered>
                            <GoogleLoginComponent
                                text="Connect Google Calendar"
                                onClick={() => {
                                    track(ClientEventType.UserClickedIntegrateWithGoogleCalendar);
                                    startDetection();
                                    URLUtil.openURL(
                                        `${process.env.REACT_APP_AUTH_URL}${SpinachAPIPath.GoogleCalendarAuth}/?${WebUrlQuery.Suid}=${user.spinachUserId}`
                                    );
                                }}
                            />
                        </Row>

                        <Spacing />

                        <Row centered={true}>
                            <SecondaryButton
                                title="No thanks, I'll do it manually"
                                size={ButtonSize.Small}
                                onClick={onOptForManual}
                                buttonStyles={{
                                    alignItems: 'center',
                                    display: 'flex',
                                    textDecoration: 'none',
                                }}
                            />
                        </Row>

                        <Spacing />
                        <Row centered>
                            <img src={SecurityBadges} style={{ width: '250px' }} />
                        </Row>
                    </ScrollView>
                </Column>
            </Column>
            {width > 900 ? (
                <Column style={{ backgroundColor: '#F4F6F6', flex: 1 }} centered={true} vCentered={true}>
                    <img src={SpinachReviewSrc} style={{ maxWidth: '70%', maxHeight: '60%' }} />
                    <Spacing factor={2} />
                    <img src={SpinachProofSrc} style={{ maxWidth: '70%', maxHeight: '25%' }} />
                </Column>
            ) : null}
        </Row>
    );
}
