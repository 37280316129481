import { Box } from '@material-ui/core';
import React, { useState } from 'react';

import { ClientEventType } from '@spinach-shared/types';

import {
    BodyRegularOnboard,
    ClientLogger,
    Spacing,
    getUser,
    patchUser,
    useExperienceTracking,
    useGlobalAiDashboard,
    useGlobalAuthedUser,
} from '../../../..';
import { SpinachSwitch } from '../SpinachSwitch';

export const DriveVideoUploadSettings = (): JSX.Element => {
    const [user, setUser] = useGlobalAuthedUser();
    const [isFreshUser, setIsFreshUser] = useState(false);
    React.useEffect(() => {
        (async () => {
            const newUser = await getUser();
            if (newUser.user) {
                setUser(newUser.user);
            }
            setIsFreshUser(true);
        })();
    }, [setUser]);

    const track = useExperienceTracking();

    const [isLoadingEdit, setIsLoadingEdit] = useState(false);
    const { setToastText } = useGlobalAiDashboard();

    return (
        <Box display="flex" flexDirection="column">
            <Box
                style={{
                    fontWeight: 500,
                    fontSize: '17px',
                    textTransform: 'uppercase',
                    letterSpacing: '1.5px',
                }}
            >{`Video Recordings`}</Box>
            <Box
                style={{ fontSize: '15px', marginTop: '5px', marginBottom: '10px' }}
            >{`Video recordings will automatically be saved to the same location as your meeting notes.`}</Box>
            <Box display="flex" alignSelf="flex-start">
                <SpinachSwitch
                    checked={user.metadata.uploadVideoToGoogleDrive}
                    disabled={isLoadingEdit || !isFreshUser}
                    onChange={async () => {
                        setIsLoadingEdit(true);
                        track(ClientEventType.AIDashboardClick, {
                            ClickedOn: 'Toggle Video Uploading To Google Drive (User Settings)',
                            ToggleTo: !!user.metadata.uploadVideoToGoogleDrive ? 'Off' : 'On',
                        });

                        const newValue = !user.metadata.uploadVideoToGoogleDrive;

                        try {
                            await patchUser({
                                metadata: {
                                    uploadVideoToGoogleDrive: newValue,
                                },
                            });

                            const updatedUser = await getUser();

                            if (updatedUser.user) {
                                setUser(updatedUser.user);
                            }
                        } catch (error: any) {
                            ClientLogger.error('Failed to change Google Drive Backup Video settings', {
                                spinachUserId: user.spinachUserId,
                                uploadVideoToGoogleDrive: newValue,
                                errorMessage: error.message,
                            });

                            setToastText('Something went wrong when updating your settings.');
                        }
                        setIsLoadingEdit(false);
                    }}
                />
                <BodyRegularOnboard style={{ marginLeft: '15px', marginTop: '2px', flex: 1 }}>
                    Upload video recordings to Drive
                </BodyRegularOnboard>
            </Box>

            <Box
                style={{
                    marginTop: '15px',
                    marginBottom: '20px',
                    background: 'black',
                    height: '1px',
                    opacity: 0.1,
                    alignSelf: 'stretch',
                }}
            />
        </Box>
    );
};
