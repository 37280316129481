import { Box, CircularProgress } from '@material-ui/core';
import { Tooltip } from '@material-ui/core';
import { Close, CloudDownload, Folder, InfoOutlined } from '@material-ui/icons';
import React, { Ref } from 'react';
import { useEffect, useMemo, useState } from 'react';

import { DashboardSection } from '@spinach-shared/constants';
import { ClientEventType, FeatureToggle, ViewableTranscriptLineData } from '@spinach-shared/types';

import {
    useExperienceTracking,
    useGlobalAiDashboard,
    useGlobalAuthedUser,
    useIsMeetingVideoDownload,
    useIsMeetingVideoPlayer,
    useProFeatures,
} from '../../../../hooks';
import { LocalStorageKeys, useLocalStorage } from '../../../../hooks/useLocalStorage';
import { lightTheme } from '../../../../styles';
import { Anchor, Spacing } from '../../../common';
import { ScrollArea } from '../../../stand-up';
import { useNavigateToUpgrade } from '../../hooks/useNavigateToUpgrade';
import { MeetingSelection } from '../types';
import { AskSpinach, AskSpinachFrom } from './AskSpinach';
import { getBlurbId } from './TranscriptsSection';

const nonBreakableSpace = '\u00A0';

export type Media =
    | { googleDriveUrl: string | null; participantsNames: string[] }
    | { recordingUrl: string | null; participantsNames: string[] }
    | { error: true }
    | { loading: true }
    | { isVideoRecordingDisabled: true; recordingUrl: null };

export type TranscriptData =
    | { isTranscriptDisabled: false; transcriptLineData: ViewableTranscriptLineData[] }
    | { isTranscriptDisabled: true };

export type MeetingMediaProps = {
    onDownloadVideo: () => void;
    meetingSelection: MeetingSelection;
    stackVideoLayout: boolean;
    freeOptionsCodes: string[];
    videoRef: React.RefObject<HTMLVideoElement>;
    dashboardSection: DashboardSection;
    onTimeUpdate: (time: number) => void;
    media?: Media;
    transcript?: TranscriptData;
    minimalTranscriptResponse: string[] | null;
    botId: string;
    seriesId?: string;
};

export function MeetingMedia({
    media,
    onDownloadVideo,
    stackVideoLayout,
    meetingSelection,
    freeOptionsCodes,
    videoRef,
    transcript,
    dashboardSection,
    minimalTranscriptResponse,
    onTimeUpdate,
    botId,
    seriesId,
}: MeetingMediaProps) {
    const hasProFeatures = useProFeatures();
    const showUpgradeToPro = !hasProFeatures && !freeOptionsCodes.includes(meetingSelection.code);
    const canShowMedia = media && transcript;
    const isHidingVideoForCappedSummary = !hasProFeatures && meetingSelection.isPartialCappedSummary;
    const [user] = useGlobalAuthedUser();

    return (
        <Box
            flex={stackVideoLayout ? 1 : 4}
            mr={stackVideoLayout ? undefined : '25px'}
            display="flex"
            flexDirection="column"
        >
            {isHidingVideoForCappedSummary && !user.featureToggles[FeatureToggle.UploadVideoToGoogleDrive] ? (
                <MediaDisabledSection stackVideoLayout={stackVideoLayout} media={media} />
            ) : showUpgradeToPro && !user.featureToggles[FeatureToggle.UploadVideoToGoogleDrive] ? (
                <UpgradeToProMediaSection />
            ) : canShowMedia ? (
                <MeetingMediaInner
                    media={media}
                    onDownloadVideo={onDownloadVideo}
                    stackVideoLayout={stackVideoLayout}
                    meetingSelection={meetingSelection}
                    videoRef={videoRef}
                    minimalTranscriptResponse={minimalTranscriptResponse}
                    transcript={transcript}
                    dashboardSection={dashboardSection}
                    onTimeUpdate={onTimeUpdate}
                    botId={botId}
                    seriesId={seriesId}
                />
            ) : (
                <></>
            )}
        </Box>
    );
}

export type MeetingMediaInnerProps = {
    media: Media;
    onDownloadVideo: () => void;
    stackVideoLayout: boolean;
    meetingSelection: MeetingSelection;
    videoRef: React.RefObject<HTMLVideoElement>;
    transcript: TranscriptData;
    dashboardSection: DashboardSection;
    onTimeUpdate: (time: number) => void;
    minimalTranscriptResponse: string[] | null;
    botId: string;
    seriesId?: string;
};

export const MeetingMediaInner = ({
    media,
    onDownloadVideo,
    stackVideoLayout,
    meetingSelection: selection,
    videoRef,
    transcript,
    dashboardSection,
    onTimeUpdate,
    botId,
    seriesId,
    minimalTranscriptResponse,
}: MeetingMediaInnerProps) => {
    const hasProFeatures = useProFeatures();
    const [currentSecond, setSecond] = useState(0);
    const track = useExperienceTracking();

    useEffect(() => {
        onTimeUpdate(currentSecond);
    }, [currentSecond, onTimeUpdate]);

    const autoScrollTranscript = true;

    const activeBlurbIndex = useMemo(() => {
        if (!transcript || !Array.isArray(transcript)) {
            return undefined;
        }
        const activeBlurb = transcript.find(
            (blurb, i) =>
                currentSecond >= blurb.timestamp &&
                (transcript[i + 1] == undefined || currentSecond <= transcript[i + 1].timestamp)
        );
        return activeBlurb ? transcript?.indexOf(activeBlurb) : undefined ?? undefined;
    }, [currentSecond, transcript]);

    useEffect(() => {
        if (autoScrollTranscript && activeBlurbIndex) {
            const element = document.getElementById(getBlurbId(activeBlurbIndex));
            if (element) {
                element.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        }
    }, [autoScrollTranscript, activeBlurbIndex, /* effect dep */ dashboardSection]);

    const handleTimeUpdate = () => {
        if (videoRef.current) {
            setSecond(Math.floor(videoRef.current.currentTime));
        }
    };

    const onPlay: React.ReactEventHandler<HTMLVideoElement> = (player) => {
        if (!player.target) {
            return;
        }

        track(ClientEventType.AIDashboardClick, {
            ClickedOn: 'Play Video',
        });

        const video = player.target as HTMLVideoElement;
        // check if current second is 0
        if (transcript && Array.isArray(transcript) && transcript.length) {
            // skip silence
            const start = Math.ceil(transcript[0].timestamp);
            if (video.currentTime < start) {
                video.currentTime = start;
            }
        }
    };

    const isVideoDisabled = media && 'isVideoRecordingDisabled' in media;
    const noUrl = media && 'recordingUrl' in media ? !media.recordingUrl : true;
    const noDriveUrl = media && 'googleDriveUrl' in media ? !media.googleDriveUrl : true;
    const isError = !!(media && 'error' in media) || (!isVideoDisabled && noUrl && noDriveUrl);

    const videoPlayerEnabled = useIsMeetingVideoPlayer();
    const videoDownloadEnabled = useIsMeetingVideoDownload();

    return (
        <>
            <Box fontWeight={700} fontSize="27px">
                {selection?.title}
            </Box>
            <Box fontWeight={700} fontSize="16px" mb={stackVideoLayout ? '5px' : '25px'}>
                {selection?.verbalDate}
            </Box>
            <ScrollArea sidePadding={0}>
                {videoPlayerEnabled && (
                    <MediaComponent
                        meetingCreatedAt={selection.createdAt}
                        hasProFeatures={hasProFeatures}
                        loading={!!(media && 'loading' in media)}
                        error={isError}
                        recordingUrl={media && 'recordingUrl' in media ? media.recordingUrl ?? undefined : undefined}
                        driveUrl={media && 'googleDriveUrl' in media ? media.googleDriveUrl ?? undefined : undefined}
                        videoRef={videoRef}
                        isVideoDisabled={isVideoDisabled}
                        onTimeUpdate={handleTimeUpdate}
                        onPlay={onPlay}
                    />
                )}
                {media && 'participantsNames' in media && (
                    <Box fontSize="18px" mt={stackVideoLayout ? '5px' : '25px'}>
                        <Box display={stackVideoLayout ? 'contents' : undefined} fontWeight={700}>
                            {'Meeting Attendees: '}
                        </Box>
                        <Box display={stackVideoLayout ? 'contents' : undefined} fontSize="16px">
                            {media.participantsNames
                                .map((name) => name.replaceAll(/\s/g, nonBreakableSpace))
                                .join(', ')}
                        </Box>
                    </Box>
                )}
                {videoDownloadEnabled && media && 'recordingUrl' in media && media.recordingUrl && (
                    <Tooltip title={hasProFeatures ? '' : 'Pro feature'} placement="right-start">
                        <Box
                            color={lightTheme.primary.greenLight}
                            display="flex"
                            style={{
                                textDecoration: 'underline',
                                cursor: 'pointer',
                                alignSelf: 'flex-start',
                                opacity: hasProFeatures ? 1 : 0.4,
                            }}
                            marginTop="10px"
                            onClick={onDownloadVideo}
                        >
                            <CloudDownload style={{ marginRight: '10px' }} />
                            {'Download Video'}
                        </Box>
                    </Tooltip>
                )}
                <Spacing />
                {!stackVideoLayout ? (
                    <AskSpinach
                        from={AskSpinachFrom.MediaComponent}
                        minimalTranscriptResponse={minimalTranscriptResponse}
                        botId={botId}
                        seriesId={seriesId}
                    />
                ) : (
                    <></>
                )}
            </ScrollArea>
        </>
    );
};

export function MediaComponent({
    meetingCreatedAt,
    hasProFeatures,
    loading,
    error,
    isVideoDisabled,
    recordingUrl,
    driveUrl,
    videoRef,
    onTimeUpdate,
    onPlay,
}: {
    meetingCreatedAt: Date;
    hasProFeatures: boolean;
    loading: boolean;
    error: boolean;
    isVideoDisabled: boolean;
    recordingUrl?: string;
    driveUrl?: string;
    videoRef: Ref<HTMLVideoElement>;
    onTimeUpdate: React.ReactEventHandler<HTMLVideoElement>;
    onPlay: React.ReactEventHandler<HTMLVideoElement>;
}) {
    return (
        <Box marginRight="5px" marginTop="5px">
            {loading && (
                <CircularProgress
                    size={'30px'}
                    style={{ marginLeft: '10px', marginBottom: '15px', color: lightTheme.primary.orangeDark }}
                />
            )}
            {error && (
                <Box style={{ background: '#F0F0F0', borderRadius: '4px' }} display={'flex'} padding="20px">
                    <Folder style={{ width: '40px', height: '40px' }} htmlColor={'#CCC'} />
                    <Box color="black" marginLeft="15px" marginY="auto">
                        <ErrorComponent meetingCreatedAt={meetingCreatedAt} hasProFeatures={hasProFeatures} />
                    </Box>
                </Box>
            )}
            {driveUrl ? (
                <Box style={{ background: '#F0F0F0', borderRadius: '4px' }} display={'flex'} padding="20px">
                    <Folder style={{ width: '40px', height: '40px' }} htmlColor={'#CCC'} />
                    <Box color="black" marginLeft="15px" marginY="auto">
                        Video recording available on <a href={driveUrl}>Google Drive</a>
                    </Box>
                </Box>
            ) : undefined}
            {recordingUrl ? (
                <video
                    onPlay={onPlay}
                    ref={videoRef}
                    onTimeUpdate={onTimeUpdate}
                    controlsList="nodownload"
                    style={{ width: '100%', height: 'auto' }}
                    controls
                    src={recordingUrl}
                    onContextMenu={(e) => e.preventDefault()}
                />
            ) : (
                <></>
            )}
            <LackOfGoogleDriveIntegrationWarning />
        </Box>
    );
}

const LackOfGoogleDriveIntegrationWarning = () => {
    const [user] = useGlobalAuthedUser();
    const navigateToUpgrade = useNavigateToUpgrade();
    const track = useExperienceTracking();
    const { setActiveSection } = useGlobalAiDashboard();
    const [hasDismissedGoogleDriveVideoBackupWarning, setHasUserSeenProvisioningInstructions] = useLocalStorage(
        LocalStorageKeys.HasDismissedGoogleDriveVideoBackupWarning,
        false
    );
    const isFreeUserWhoIsEnabledForGoogleDriveVideoUpload =
        !user.hasProFeatures && user.featureToggles[FeatureToggle.UploadVideoToGoogleDrive];
    const isProCustomerWhoIsEnabledForGoogleDriveVideoUploadButHasNotEnabledItOrYetToDismissTheWarning =
        (!user.isAuthedForGoogleDrive || (user.isAuthedForGoogleDrive && !user.metadata.uploadVideoToGoogleDrive)) &&
        user.hasProFeatures &&
        !hasDismissedGoogleDriveVideoBackupWarning &&
        user.featureToggles[FeatureToggle.UploadVideoToGoogleDrive];
    return (
        <>
            {isFreeUserWhoIsEnabledForGoogleDriveVideoUpload ? (
                <Box style={{ background: '#FEF2DE', borderRadius: '4px' }} display={'flex'} padding="20px" mt="10px">
                    <InfoOutlined style={{ width: '40px', height: '40px' }} htmlColor={'#FDD291'} />
                    <Box color="black" marginLeft="15px" marginY="auto" display="flex" flexDirection="column">
                        <Box>
                            Upgrade to Pro to view 100 past meetings, download video, and automatically save to Google
                            Drive.
                        </Box>
                        <Box
                            style={{
                                background: lightTheme.primary.greenDark,
                                color: 'white',
                                cursor: 'pointer',
                                borderRadius: '4px',
                                marginTop: '10px',
                                padding: '5px 10px',
                                alignSelf: 'flex-end',
                                fontWeight: 'bold',
                            }}
                            onClick={() => {
                                navigateToUpgrade('Click Upgrade to Pro (Google Drive recording backup)');
                            }}
                        >
                            Upgrade to Pro
                        </Box>
                    </Box>
                </Box>
            ) : undefined}
            {isProCustomerWhoIsEnabledForGoogleDriveVideoUploadButHasNotEnabledItOrYetToDismissTheWarning ? (
                <Box
                    style={{ background: '#FEF2DE', borderRadius: '4px' }}
                    display={'flex'}
                    paddingBottom="20px"
                    mt="10px"
                    paddingLeft="20px"
                >
                    <Box display={'flex'} mt="20px" flex="1">
                        <InfoOutlined style={{ width: '40px', height: '40px' }} htmlColor={'#FDD291'} />
                        <Box color="black" marginLeft="15px" marginY="auto">
                            Recordings are saved for 7 days.{' '}
                            <span
                                style={{ color: lightTheme.primary.greenDark, cursor: 'pointer' }}
                                onClick={() => {
                                    track(ClientEventType.AIDashboardClick, {
                                        ClickedOn: 'Enable Google Drive Under Video',
                                    });
                                    setActiveSection(DashboardSection.Integrations);
                                }}
                            >
                                Enable Google Drive
                            </span>{' '}
                            to store your recordings longer.
                        </Box>
                    </Box>
                    <Box
                        m="5px"
                        alignSelf="top"
                        style={{ cursor: 'pointer', alignSelf: 'flex-start' }}
                        onClick={() => {
                            track(ClientEventType.AIDashboardClick, {
                                ClickedOn: 'Close Google Drive Backup Warning',
                            });
                            setHasUserSeenProvisioningInstructions(true);
                        }}
                    >
                        <Close style={{ width: '18px', height: '18px' }} htmlColor={lightTheme.primary.greenDark} />
                    </Box>
                </Box>
            ) : undefined}
        </>
    );
};

const weekInMs = 1000 * 60 * 60 * 24 * 7;

const ErrorComponent = ({ meetingCreatedAt, hasProFeatures }: { meetingCreatedAt: Date; hasProFeatures: boolean }) => {
    const recallStillStoreThisVideo = Date.now() < meetingCreatedAt.getTime() + weekInMs;
    if (recallStillStoreThisVideo) {
        return <>{'Video is not available due to technical issues. Our team is working to resolve this.'}</>;
    } else {
        if (hasProFeatures) {
            return <>{'Video recordings are securely stored for only 7 days'}</>;
        } else {
            return (
                <>
                    {
                        'Enjoy access to recordings for up to 3 meetings on the free plan. Extend access to 7 days by upgrading to Pro'
                    }
                </>
            );
        }
    }
};

export const UpgradeToProMediaSection = () => {
    const navigateToUpgrade = useNavigateToUpgrade();

    return (
        <Box style={{ background: '#F0F0F0', borderRadius: '4px' }} display={'flex'} padding="20px">
            <Folder style={{ width: '40px', height: '40px' }} htmlColor={'#CCC'} />
            <Box color="black" marginLeft="15px" marginY="auto">
                <span>
                    {'Enjoy access to recordings for up to '}
                    <b>{`3${nonBreakableSpace}meetings`}</b>
                    {` on the free plan. Extend access to 7${nonBreakableSpace}days by `}
                    <Anchor
                        onClick={() => {
                            navigateToUpgrade('Click Upgrade to Pro (Link where video should be)');
                        }}
                    >
                        {'Upgrading to Pro'}
                    </Anchor>
                </span>
            </Box>
        </Box>
    );
};

export const MediaDisabledSection = ({
    media,
    stackVideoLayout,
}: {
    media: Media | undefined;
    stackVideoLayout: boolean;
}) => {
    const navigateToUpgrade = useNavigateToUpgrade();

    return (
        <>
            <Box style={{ background: '#F0F0F0', borderRadius: '4px' }} display={'flex'} padding="20px">
                <Folder style={{ width: '40px', height: '40px' }} htmlColor={'#CCC'} />
                <Box color="black" marginLeft="15px" marginY="auto">
                    <span>
                        <Anchor
                            onClick={() => {
                                navigateToUpgrade('Click Upgrade to Pro (partial summary disabled video)');
                            }}
                        >
                            {'Upgrade'}
                        </Anchor>{' '}
                        to access and download video (available for 7 days from recording date)
                    </span>
                </Box>
            </Box>
            {media && 'participantsNames' in media && (
                <Box fontSize="18px" mt={stackVideoLayout ? '5px' : '25px'}>
                    <Box display={stackVideoLayout ? 'contents' : undefined} fontWeight={700}>
                        {'Meeting Attendees: '}
                    </Box>
                    <Box display={stackVideoLayout ? 'contents' : undefined} fontSize="16px">
                        {media.participantsNames.map((name) => name.replaceAll(/\s/g, nonBreakableSpace)).join(', ')}
                    </Box>
                </Box>
            )}
        </>
    );
};
