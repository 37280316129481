import { Box, TextField } from '@material-ui/core';
import { useState } from 'react';

import { StoredSpinachSeries } from '@spinach-shared/utils';

import { patchSeries } from '../../../../apis/patchSeries';
import { useGlobalAiDashboard } from '../../../../hooks/useGlobalAiDashboard';
import { useGlobalStoredSeriesList } from '../../../../hooks/useGlobalStoredSeriesList';
import { useGlobalAuthedUser } from '../../../../hooks/useGlobalUser';
import { ButtonSize } from '../../../../styles';
import { useSpinachInputStyles } from '../../../input';
import { OutlinedButton } from '../../../stand-up/OutlinedButton';

export const CustomFeatureSettings = ({ storedSeries }: { storedSeries: StoredSpinachSeries }) => {
    const [user] = useGlobalAuthedUser();
    const { setToastText } = useGlobalAiDashboard();
    const { storedSeriesListState, setStoredSeriesList } = useGlobalStoredSeriesList();
    const [videoAgentTopicString, setVideoAgentTopicString] = useState(
        storedSeries.metadata.forcedVideoAgentTopics?.join(',') || ''
    );
    const [isLoading, setIsLoading] = useState(false);
    const classes = useSpinachInputStyles({ value: '' });

    const updateSeries = async () => {
        try {
            setIsLoading(true);
            const topics = videoAgentTopicString
                .split(',')
                .map((topic) => topic.trim())
                .filter((topic) => topic.length > 0);
            const updatedSeries = await patchSeries(storedSeries.id, {
                metadata: {
                    forcedVideoAgentTopics: topics,
                },
            });
            if (updatedSeries) {
                const instance = new StoredSpinachSeries(updatedSeries);
                const newList = storedSeriesListState.storedSeriesList.map((series) => {
                    if (series.id === instance.id) {
                        return instance;
                    } else {
                        return series;
                    }
                });
                setStoredSeriesList(newList);
                setToastText(`Series was updated with ${topics.join(',')}`);
            } else {
                setToastText('Error updating video agent topics');
            }
        } catch (error) {
            setToastText('Error updating video agent topics');
        } finally {
            setIsLoading(false);
        }
    };
    if (user.isEnabledForPreparedAgentTopics) {
        return (
            <Box display="flex" flexDirection="row" alignItems={'center'} p="30px">
                <TextField
                    InputProps={{ classes: { root: classes.base } }}
                    label="Add agenda topics (comma separated)"
                    // variant="outlined"
                    style={{
                        maxWidth: '700px',
                        width: '100%',
                        minWidth: '300px',
                    }}
                    // margin="normal"
                    value={videoAgentTopicString}
                    onChange={(e) => {
                        setVideoAgentTopicString(e.target.value);
                    }}
                />
                <OutlinedButton
                    style={{ marginLeft: '10px', height: '30px' }}
                    size={ButtonSize.Mini}
                    title="Save Topics"
                    onClick={() => updateSeries()}
                    isLoading={isLoading}
                />
            </Box>
        );
    } else {
        return <></>;
    }
};
